import { Component, OnInit } from '@angular/core';
import { IBrandListResult, IBrandsListResponse } from '@app/core/interface/brands.interface';
import { NotificationType, REDIRECT, Storage, USER } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { LoginFacade } from '@app/core/facade/login.facade';
import { RegisterFacade } from '@facade/register.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UrlStorageService } from '@app/core/service/url-storage.service';

@Component({
  selector: 'app-switch-brand',
  templateUrl: './switch-brand.component.html',
  styleUrls: ['./switch-brand.component.scss'],
  providers: [RegisterFacade, LoginFacade],
})
export class SwitchBrandComponent implements OnInit {
  supplierAccount = false;
  brands: IBrandListResult[] = [];
  previousRoute = '';
  isLogin = false;
  constructor(
    private _facade: BrandsFacade,
    private _registerFacade: RegisterFacade,
    private router: Router,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService,
    private _urlStorageService: UrlStorageService,
    private _loginFacade: LoginFacade
  ) {}

  ngOnInit(): void {
    this.supplierAccount = this.router.url === '/select-brand';
    this.getBrands();
    this.getPreviousRoute();
  }

  getBrands(): void {
    this._facade.getBrands$().subscribe({
      next: this._onGetBrands.bind(this),
      error: this._error.bind(this),
    });
  }

  switchBrand(brand: IBrandListResult): void {
    const storedUser = this._dataStorageService.get(USER, Storage.local)
      ? this._dataStorageService.get(USER, Storage.local)
      : this._dataStorageService.get(USER, Storage.session);
    const url = this._dataStorageService.get(REDIRECT, Storage.local);
    const changedUser = JSON.parse(storedUser);
    changedUser.brand = brand.uuid;
    this._facade.switchBrand$({ brand: brand.uuid }).subscribe({
      next: () => {
        this._registerFacade.getAccountDetails$().subscribe(result => {
          result.is_supplier
            ? this.router.navigate([url && url !== '/landing' && url !== '/' ? url : '/overview']).then(() => {
                location.reload(); // TODO why reload ??
              })
            : this.router.navigate([url && url !== '/landing' && url !== '/' ? url : '/dashboard']).then(() => {
                location.reload();
              });
          this._dataStorageService.set(USER, JSON.stringify(result), Storage.local);
          this._dataStorageService.removeKey(REDIRECT, Storage.local);
          this._snackbarService.openTypeSnackbar(`You have successfully switched Brand`, NotificationType.success);
        });
      },
      error: this._error.bind(this),
    });
  }
  private _onGetBrands(response: IBrandsListResponse) {
    if (this.supplierAccount && response.results.length === 1) {
      this.router.navigate(['/overview']);
    } else {
      this.brands = response.results;
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  getPreviousRoute(): void {
    this.previousRoute = this._urlStorageService.getPreviousUrl();
    this.isLogin = this.previousRoute.includes('/login');
  }

  //
  goBack(): void {
    if (this.isLogin) {
      this._loginFacade.logout$().subscribe({
        next: () => this.router.navigate(['/login']),
        error: this._error.bind(this),
      });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
