import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IProductChainStep, SupplierStatus } from '@app/core/interface/steps.interface';

import { TooltipPosition } from '@shared/components/tooltip/tooltip.constants';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @ViewChild('barSection') barSection!: ElementRef;
  @Input() progressValue!: number;
  @Input() title!: string;
  @Input() steps?: IProductChainStep[];
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  stepCounts!: { toUpdate: number; updated: number; notApplicable: number; totalInvited: number };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['steps'] && this.steps) {
      this.stepCounts = this.steps.reduce(
        (acc, step) => {
          if (step.supplier_status === SupplierStatus.to_update) {
            acc.toUpdate++;
            acc.totalInvited++;
          } else if (step.supplier_status === SupplierStatus.updated) {
            acc.updated++;
            acc.totalInvited++;
          } else if (step.supplier_status === SupplierStatus.not_applicable) {
            acc.notApplicable++;
          }
          return acc;
        },
        { toUpdate: 0, updated: 0, notApplicable: 0, totalInvited: 0 }
      );

      this.progressValue = (this.stepCounts.updated / (this.stepCounts.updated + this.stepCounts.toUpdate)) * 100;
    }
  }

  getProgressPosition(): number {
    const barWidth = this.barSection?.nativeElement.offsetWidth;
    const total = (barWidth * this.progressValue) / 125;
    return total / 2;
  }
}
