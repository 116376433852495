import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class EmailGroup {
  emailForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.emailForm = this._fb.group({
      // name: [null, [Validators.required]],
      name: [null],
      content: ['', [Validators.required]],
    });
  }
}
