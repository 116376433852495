import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class AddContactGroup {
  addContact: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.addContact = this._fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      language_prefered: [''],
      phone: [''],
      position: [''],
      main_contact: [true],
      is_active: [true],
    });
  }
}
