import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import { IInviteSupplierStep } from '../interface/steps.interface';
import {
  IEmailTemplateResponse,
  IDeleteEmailTemplateResponse,
  IEmailTemplatesResponse,
  IInviteTemplateResponse,
} from '../interface/email.interface';
import { IEmailSendRequestResponse, ISupportResponse } from '../interface/supp.interface';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getEmailTemplates$(): Observable<IEmailTemplatesResponse> {
    return this._http.get<IEmailTemplatesResponse>(`${this.apiUrl}/email/templates/`);
  }

  createEmailTemplate$(data: FormData): Observable<IEmailTemplateResponse> {
    return this._http.post<IEmailTemplateResponse>(`${this.apiUrl}/email/templates/`, data);
  }

  getEmailTemplate$(uuid: string): Observable<IEmailTemplateResponse> {
    return this._http.get<IEmailTemplateResponse>(`${this.apiUrl}/email/templates/${uuid}/`);
  }

  updateEmailTemplate$(data: FormData, uuid: string): Observable<IEmailTemplateResponse> {
    return this._http.patch<IEmailTemplateResponse>(`${this.apiUrl}/email/templates/${uuid}/`, data);
  }

  deleteEmailTemplate$(uuid: string): Observable<IDeleteEmailTemplateResponse> {
    return this._http.delete<IDeleteEmailTemplateResponse>(`${this.apiUrl}/email/templates/${uuid}/`);
  }
  sendInvitations$(data: FormData): Observable<IInviteTemplateResponse> {
    return this._http.post<IInviteTemplateResponse>(`${this.apiUrl}/email/templates/invite/`, data);
  }

  sendEmailToSupplier$(uuid: string): Observable<IInviteSupplierStep> {
    return this._http.post<IInviteSupplierStep>(`${this.apiUrl}/steps/${uuid}/invite`, {});
  }

  sendEmail$(data: FormData): Observable<ISupportResponse> {
    return this._http.post<ISupportResponse>(`${this.apiUrl}/email/support/`, data);
  }
  sendEmailRequestStepEdit$(stepUuid: string, data: FormData): Observable<IEmailSendRequestResponse> {
    return this._http.put<IEmailSendRequestResponse>(`${this.apiUrl}/supp/steps/${stepUuid}/requeststepedit/`, data);
  }
}
