import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OFFICE_FILENAME_EXTENSIONS } from '@app/core/constants';
import { IViewFile } from '@app/core/interface/suppliers.interface';
import { EventEmitter } from '@angular/core';
import { IMedia } from '@app/core/interface/medias.interface';
import { viewerType } from 'ngx-doc-viewer';
import { getViewerType } from '@app/core/utils/ngx-doc-type';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss'],
})
export class FileViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listData!: { list: IMedia[]; file: IMedia };
  @Input() hide_header = false;
  @Input() selectedFile!: IMedia;
  @Input() is_supplier!: boolean;
  @ViewChild('videoPlayer', { static: false }) videoPlayerRef?: ElementRef;
  @Output() selectedFileName = new EventEmitter<string>();
  docURL = '';
  name = '';
  fileName = '';
  created_at? = '';
  mimetype = '';
  puseMode = true;
  viewer: 'url' | 'office' = 'url';
  fileList: IMedia[] = [];
  timeoutId?: NodeJS.Timeout;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IViewFile,
    private _cdRef: ChangeDetectorRef
  ) {
    if (!dialogData.list && !(dialogData instanceof Array)) {
      this.docURL = dialogData.url;
      this.name = this.docURL;
      this.selectedFile = {
        file: this.docURL,
        name: this.dialogData.name,
        uuid: '',
        mimetype: this.dialogData.mimetype,
        created_at: this.dialogData.created_at,
        url: this.dialogData?.url,
      };
      this.setViewer(this.docURL);
    }
  }
  ngOnDestroy(): void {
    this.timeoutId && clearTimeout(this.timeoutId);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listData']) {
      this.fileList = changes['listData'].currentValue.list;
      this.setViewer(this.docURL);
    }
    if (changes['selectedFile']) {
      this.docURL = changes['selectedFile'].currentValue.file;
      this.setViewer(this.docURL);
    }
  }
  ngOnInit(): void {
    if (this.listData) {
      this.docURL = this.listData.file.file;
      this.fileList = this.listData.list;
      this.fileName = this.selectedFile.name;
      this.selectedFileName.emit(this.selectedFile.name);
      this.created_at = this.selectedFile.created_at;
      this.mimetype = this.selectedFile.mimetype;
      this.setViewer(this.docURL);
    }
  }
  /**
   *
   * @param filename depending on filename extendion we are setting the doc viewer
   */
  setViewer(filename: string): void {
    if (!filename) return;
    const extIndex = filename.split('.').length - 1;
    const ext = filename.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      this.viewer = 'office';
    } else {
      this.viewer = 'url';
    }
  }
  onFileSelect(file: IMedia): void {
    this.selectedFile = file;
    this.docURL = file.file;
    this.fileName = file.name;
    this.created_at = file.created_at;
    this.selectedFileName.emit(this.selectedFile.name);
    this.setViewer(this.docURL);
    this._cdRef.detectChanges();
  }

  getViewerType(mimetype: string): viewerType {
    return getViewerType(mimetype);
  }

  toggle(drawer: MatDrawer): void {
    this.timeoutId && clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      drawer.toggle();
    }, 0);
  }
}
