<div class="dialog-wrapper">
  <div class="d-flex justify-content-between header" *ngIf="!hide_header">
    <div [ngSwitch]="dialogData.screen">
      <div *ngSwitchCase="'photos'">{{ 'Photos Gallery' | translate }}</div>
      <div *ngSwitchCase="'videos'">{{ 'View video' | translate }}</div>
      <div *ngSwitchCase="'documents'">{{ 'Document details' | translate }}</div>
      <div *ngSwitchDefault>{{ 'Document details' | translate }}</div>
    </div>
    <div class="close-btn">
      <app-custom-icon
        mat-dialog-close
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        class="cursor-pointer"
      ></app-custom-icon>
    </div>
  </div>
  <div class="horizontal-line mb-4" *ngIf="dialogData.isBatch"></div>
  <div class="content-holder">
    <div class="header-holder" [ngClass]="{ 'is-supplier': is_supplier }">
      <h5 *ngIf="is_supplier">{{ 'supply-chain-step.All pages' | translate }}</h5>
      <div class="title-holder">
        <h2 class="subtitle">
          {{
            dialogData.name && !is_supplier
              ? dialogData.name
              : is_supplier
                ? selectedFile.name
                : ('View File' | translate)
          }}
        </h2>
        <div class="uploaded-holder">
          <div class="upload-icon">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-white'"> </app-custom-icon>
          </div>
          <p>
            {{ 'supply-chain-step.Uploaded' | translate }}
            {{ (created_at ? created_at : dialogData.created_at) | date: 'dd.MM.yyyy' }}
          </p>
        </div>
      </div>
      <div>
        <!-- <div class="row" *ngIf="listData && listData.list.length > 1">
          
          <div class="other-title offset-3">
            <p style="width: 40%; float: right; margin-top: 10px" class="p1 -strong">
              {{ drawer.opened ? ('Hide list' | translate) : ('See full list' | translate) }}
            </p>
            <mat-slide-toggle
              [checked]="drawer.opened"
              class="custom-slide-toggle col-2"
              style="width: 10%; float: right"
              [matTooltip]="
                drawer.opened ? ('Toggle to hide the list' | translate) : ('Toggle to see the full list' | translate)
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-end"
              matTooltipVisibility="visible"
              [color]="'primary'"
              (click)="toggle(drawer)"
            >
            </mat-slide-toggle>
          </div>
        </div> -->
      </div>
    </div>
    <mat-dialog-content class="mat-typography">
      <mat-drawer-container class="file-view-container" autosize>
        <div class="row preview-wraper">
          <div
            [ngClass]="{
              'img-view-holder':
                selectedFile.mimetype.indexOf('image') > -1 || selectedFile.mimetype.indexOf('video') > -1,
              'video-holder': selectedFile.mimetype.indexOf('video') > -1,
            }"
          >
            <!--Doc -->
            <!-- <ngx-doc-viewer
              *ngIf="!(selectedFile.mimetype.indexOf('image') > -1 || selectedFile.mimetype.indexOf('video') > -1)"
              [url]="docURL"
              [viewer]="getViewerType(selectedFile.mimetype)"
              [ngClass]="{ 'col-8': drawer.opened, 'col-12': !drawer.opened, 'full-doc': true }"
            >
            </ngx-doc-viewer> -->
            <ngx-doc-viewer
              *ngIf="!(selectedFile.mimetype.indexOf('image') > -1 || selectedFile.mimetype.indexOf('video') > -1)"
              [url]="docURL"
              [viewer]="getViewerType(selectedFile.mimetype)"
              class="col-12 full-doc"
            >
            </ngx-doc-viewer>
            <!--Img -->
            <img src="{{ docURL }}" class="img-view" *ngIf="selectedFile.mimetype.indexOf('image') > -1" />
            <!--Video -->
            <div class="video-controlerr" *ngIf="selectedFile.mimetype.indexOf('video') > -1">
              <app-video-player
                (onFileSelect)="onFileSelect($event)"
                [selectedFile]="selectedFile"
                [docURL]="docURL"
                [fileList]="fileList"
                [drawerOpened]="false"
              ></app-video-player>
            </div>
          </div>
        </div>
        <!-- <mat-drawer #drawer class="example-sidenav" mode="side" class="file-list-view col-3" position="end">  
          /// drawer is commented suince we are not using see all documentsin this screen

          <div class="file-list-wrapper">
            <div class="row">
              <div
                [ngClass]="file.mimetype === 'application/pdf' ? 'file-doc-pdf' : ''"
                class="file-card col-12"
                *ngFor="let file of fileList"
                (click)="onFileSelect(file)"
              >
                <div class="preview-container">
                  !--Doc --
                  <ngx-doc-viewer
                    [url]="file.file"
                    [viewer]="getViewerType(file.mimetype)"
                    *ngIf="!(file.mimetype.indexOf('image') > -1 || file.mimetype.indexOf('video') > -1)"
                    class="preview card-cover doc-view"
                    disableContent="all"
                  >
                  </ngx-doc-viewer>
                  !--Img --

                  <div
                    class="img-scrollbar"
                    style="background-image: url('{{ file.file }}');"
                    *ngIf="file.mimetype.indexOf('image') > -1"
                  ></div>
                  !--Video--
                  <div [ngClass]="file.mimetype.indexOf('video') > -1 && docURL === file.file ? 'overlay' : ''"></div>
                  <div
                    [ngClass]="file.mimetype.indexOf('video') === -1 && docURL === file.file ? 'overlay-img' : ''"
                  ></div>
                  <video
                    [src]="file.file"
                    *ngIf="file.mimetype.indexOf('video') > -1"
                    class="video-scrollbar preview card-cover"
                    [controls]="false"
                  ></video>
                  <div class="play-btn-srcollbar" *ngIf="file.mimetype.indexOf('video') > -1 && docURL !== file.file">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'video-white'"> </app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-drawer> -->
      </mat-drawer-container>
    </mat-dialog-content>
  </div>
  <!-- <mat-dialog-actions align="end">
    <button mat-button color="primary" *ngIf="!fileList" mat-raised-button mat-dialog-close>
      {{ 'Close' | translate }}
    </button>
  </mat-dialog-actions> -->
</div>
