import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function uniqueNameValidator(
  existingNames: string[],
  actionMode: string,
  initialName: string | undefined
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (actionMode === 'Edit' && initialName) {
      existingNames.forEach((value, index) => {
        if (value == initialName) existingNames.splice(index, 1);
      });
    }
    const name = control.value;
    const isUnique = !existingNames.includes(name);
    return isUnique ? null : { nonUniqueName: true };
  };
}
