<div class="header d-flex justify-content-between align-items-center">
  <h3>{{ 'Manage consent preferences' | translate }}</h3>
  <app-custom-icon
    mat-dialog-close
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
    class="cursor-pointer"
  ></app-custom-icon>
</div>

<mat-accordion>
  <!-- Necessary cookies (always on) -->
  <mat-expansion-panel hideToggle #necessaryCookies>
    <mat-expansion-panel-header>
      <mat-panel-title
        ><span class="indicator" [ngClass]="{ expanded: performancePanel.expanded }">
          {{ necessaryCookies.expanded ? '-' : '+' }}
        </span>
        Necessary cookies
      </mat-panel-title>
      <span class="always-on">Always on</span>
    </mat-expansion-panel-header>
    <p>
      These Cookies are essential to provide You with services available through the Website and to enable You to use
      some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these
      Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You
      with those services.
    </p>
  </mat-expansion-panel>

  <!-- Performance cookies with a toggle -->
  <mat-expansion-panel hideToggle #performancePanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="indicator"> {{ performancePanel.expanded ? '-' : '+' }} </span>Performance cookies
      </mat-panel-title>
      <mat-slide-toggle [(ngModel)]="performanceCookies"> </mat-slide-toggle>
    </mat-expansion-panel-header>
    <p>
      These cookies collect anonymous data about how visitors use our website (e.g., page views and click-through rates)
      to improve performance.
    </p>
  </mat-expansion-panel>

  <!-- Functional cookies with a toggle -->
  <mat-expansion-panel hideToggle #functionalCookiesPanel>
    <mat-expansion-panel-header>
      <mat-panel-title
        ><span class="indicator">
          {{ functionalCookiesPanel.expanded ? '-' : '+' }}
        </span>
        Functional cookies
      </mat-panel-title>
      <mat-slide-toggle [(ngModel)]="functionalCookies"> </mat-slide-toggle>
    </mat-expansion-panel-header>
    <p>
      These Cookies allow us to remember choices You make when You use the Website, such as remembering your login
      details or language preference. The purpose of these Cookies is to provide You with a more personal experience and
      to avoid You having to re-enter your preferences every time You use the Website.
    </p>
  </mat-expansion-panel>
</mat-accordion>

<div class="footer">
  <p>
    {{ 'Find out more in our' | translate }}
    <span [routerLink]="'/terms-and-conditions/Cookies policy'" mat-dialog-close>{{ 'Cookies policy' }}</span>
  </p>
  <app-rounded-button (click)="confirmChoices()" color="primary" [button_text]="'Confirm choices'" [size]="'sm'">
  </app-rounded-button>
</div>
