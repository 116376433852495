import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IUser } from '@app/core/interface/login.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataAuthService {
  private readonly _user: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  readonly user$ = this._user.asObservable();
  public updateUserAvatar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateUserAvatar$ = this.updateUserAvatar.asObservable();
  set user(data: IUser | null) {
    this._user.next(data);
  }

  get user(): IUser | null {
    return this._user.getValue();
  }
}
