import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMedia } from '@app/core/interface/medias.interface';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  @Input() selectedFile!: IMedia;
  @Input() docURL!: string;
  @Input() fileList: IMedia[] = [];
  @Input() drawerOpened = true;
  @Output() onFileSelect = new EventEmitter<IMedia>();
  @ViewChild('videoPlayer', { static: false }) videoPlayerRef?: ElementRef;
  currentTimeInterval!: NodeJS.Timeout;
  show_play_icon = true;
  percent = 0;
  isOverlayVisible = true;

  constructor(private elementRef: ElementRef) {}

  togglePlay(): void {
    if (!this.videoPlayerRef) return;
    const video: HTMLVideoElement = this.videoPlayerRef.nativeElement;
    if (video.paused) {
      video.play();
      this.onVideoPlay();
    } else {
      video.pause();
      this.onVideoPause();
    }
  }
  playPause(): void {
    this.show_play_icon = false;
    const myVideo: HTMLVideoElement = document.getElementById('my_video') as HTMLVideoElement;
    if (myVideo.paused) {
      myVideo.play();
      this.onVideoPlay();
    } else if (myVideo.ended) {
      this.show_play_icon = true;
      this.onVideoPause();
    } else {
      myVideo.pause();
      this.onVideoPause();
    }
  }
  changeVolume(event: Event): void {
    if (this.videoPlayerRef) {
      const video: HTMLVideoElement = this.videoPlayerRef.nativeElement;
      const volume = (event.target as HTMLInputElement).valueAsNumber;
      video.volume = volume / 100;
    }
  }

  toggleFullScreen() {
    if (!this.videoPlayerRef) return;
    const video = this.videoPlayerRef.nativeElement;
    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  }

  seekToTime(event: Event) {
    if (!this.videoPlayerRef) return;
    const video = this.videoPlayerRef.nativeElement;
    if (video) {
      const duration = video.duration;
      const seekPercentage = (event.target as HTMLInputElement).valueAsNumber;
      const seekTime = (+seekPercentage / 100) * +duration;
      video.currentTime = seekTime;
    }
  }
  calculateSeekPercentage() {
    if (this.videoPlayerRef) {
      const video: HTMLVideoElement = this.videoPlayerRef.nativeElement;
      const duration = video.duration;
      this.percent = (video.currentTime / duration) * 100;
      return;
    }
    this.percent = 0;
  }
  playVideo(): void {
    this.show_play_icon = false;
    this.show_play_icon = false;
  }

  pauseVideo(): void {
    this.show_play_icon = true;
  }
  calculateVolume() {
    return this.videoPlayerRef?.nativeElement.volume * 100;
  }
  ended(): void {
    this.show_play_icon = true;
    this.onVideoPause();
  }
  onFileSelected(file: IMedia) {
    this.onFileSelect.emit(file);
    this.show_play_icon = true;
    this.togglePlay();
  }

  onVideoPlay() {
    this.currentTimeInterval = setInterval(() => {
      this.calculateSeekPercentage();
    }, 500);
  }

  onVideoPause() {
    clearInterval(this.currentTimeInterval);
  }
}
