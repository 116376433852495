<div class="toggle-group" [ngClass]="{ hidden: selectedStep }">
  <mat-button-toggle-group #group="matButtonToggleGroup">
    <div class="step-level toggle">
      <mat-button-toggle value="stepLevel" aria-label="Step Level" [checked]="true">
        <span>{{ 'Step Level' | translate }}</span>
      </mat-button-toggle>
    </div>
  </mat-button-toggle-group>
</div>

<div class="product-level" *ngIf="group.value === 'productLevel'">
  <h6>{{ 'You are viewing all of the Certificates that are added to this Product' | translate }}</h6>

  <div class="row">
    <div class="col-3" *ngFor="let certificate of product?.certification_product">
      <app-certificate-card [certificate]="certificate" (click)="viewFile(certificate)"></app-certificate-card>
    </div>
  </div>
</div>

<div class="supplier-level" *ngIf="group.value === 'supplierLevel'">
  <h6>{{ 'You are viewing all of the Certificates of the Suppliers in this Batch' | translate }}</h6>

  <div class="row">
    <div class="col-3" *ngFor="let certificate of supplierCertificates">
      <app-certificate-card
        [certificate]="certificate"
        [supplier]="certificate.supplier"
        (click)="viewFile(certificate)"
      ></app-certificate-card>
    </div>
  </div>
</div>

<div class="step-level" *ngIf="group.value === 'stepLevel'">
  <h6>
    {{ 'You are viewing all of the Certificates of the Suppliers Sites, added in the Steps of this Batch' | translate }}
  </h6>

  <div class="row">
    <div class="col-3 step-certificate" *ngFor="let certificate of stepCertificates">
      <p>{{ 'Added in step: ' }}</p>
      <h6>{{ certificate.step }}</h6>
      <app-certificate-card
        [certificate]="certificate"
        [supplier]="certificate.supplier"
        [manufactory]="certificate.manufactory"
        (click)="viewFile(certificate)"
      ></app-certificate-card>
    </div>
  </div>
</div>
