import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  IActivateDeactivateCustomFieldResponse,
  ICustomField,
  ICustomFieldsListResponse,
  ICustomFieldsObjectType,
  IDeleteFieldOption,
  INameId,
  IUpdateProjectCustomFieldValues,
} from '../interface/add-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class AddFieldsService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCustomFields$(data: ICustomFieldsObjectType): Observable<ICustomFieldsListResponse> {
    return this._http.get<ICustomFieldsListResponse>(`${this.apiUrl}/${data}/customfieldname/`);
  }

  createCustomField$(data: ICustomFieldsObjectType, custom_field: FormData): Observable<INameId> {
    return this._http.post<INameId>(`${this.apiUrl}/${data}/customfieldname/`, custom_field);
  }

  editCustomField$(data: ICustomFieldsObjectType, custom_field: FormData, uuid: string): Observable<INameId> {
    return this._http.put<INameId>(`${this.apiUrl}/${data}/customfieldname/${uuid}/`, custom_field);
  }

  deleteCustomField$(data: ICustomFieldsObjectType, uuid: string): Observable<INameId> {
    return this._http.delete<INameId>(`${this.apiUrl}/${data}/customfieldname/${uuid}/`);
  }

  activateDeactivateCustomFields$(uuid: string, data: FormData): Observable<IActivateDeactivateCustomFieldResponse> {
    return this._http.patch<IActivateDeactivateCustomFieldResponse>(
      `${this.apiUrl}/customfieldname/${uuid}/active/`,
      data
    );
  }

  updateProjectCustomFieldValues$(
    data: IUpdateProjectCustomFieldValues[]
  ): Observable<IUpdateProjectCustomFieldValues[]> {
    return this._http.post<IUpdateProjectCustomFieldValues[]>(
      `${this.apiUrl}/project/customfieldname/userupdate/`,
      data
    );
  }

  ////////////////////////// Product/Project Custom Fields /////////////////////////////
  createCustomFieldWithType$(object_type: string, custom_field: Partial<ICustomField>): Observable<ICustomField> {
    return this._http.post<ICustomField>(`${this.apiUrl}/${object_type}/customfieldname/`, custom_field);
  }

  editCustomFieldWithType$(
    object_type: string,
    uuid: string,
    custom_field: Partial<ICustomField>
  ): Observable<ICustomField> {
    return this._http.patch<ICustomField>(`${this.apiUrl}/${object_type}/customfieldname/${uuid}/`, custom_field);
  }

  deleteCustomFieldOption$(object_type: string, uuid: string): Observable<IDeleteFieldOption> {
    return this._http.delete<ICustomField>(`${this.apiUrl}/${object_type}/customfieldoptions/${uuid}/`);
  }
}
