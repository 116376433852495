<div class="wraper">
  <div class="icon">
    <img src="../../../assets/images/cookie.png" />
  </div>
  <div class="text">
    <h5>{{ 'Please note that we are using cookies to improve your experience!' | translate }}</h5>
    <p>
      {{ 'You can find out which cookies we are using, by checking the' | translate }}
      <span mat-dialog-close (click)="openCookiesManagement()">{{ 'Cookies preferences' | translate }}</span>
    </p>
    <p>
      {{ 'Find out more in our' | translate }}
      <span [routerLink]="'/terms-and-conditions/Cookies policy'" mat-dialog-close>{{
        'Cookies policy' | translate
      }}</span>
    </p>
  </div>
  <div class="necessary-action">
    <app-rounded-button
      color="secondary"
      [button_text]="'Only necessary cookies'"
      [size]="'md'"
      (click)="setCookies(false)"
    >
    </app-rounded-button>
  </div>
  <div class="accept-close">
    <app-rounded-button color="primary" [button_text]="'Accept all and close'" [size]="'md'" (click)="setCookies(true)">
    </app-rounded-button>
  </div>
</div>
