import { UntypedFormGroup } from '@angular/forms';
import { CountryList } from './country-list';
const list = CountryList.isoCountries.map(contry => contry.name);
// custom validator to check if country exists
export const IsCountry = () => {
  return (formGroup: UntypedFormGroup) => {
    const country = formGroup.controls['country'];
    const name = typeof country.value === 'string' ? country.value : country.value?.name;
    if (name && list.indexOf(name) > -1) {
      country.setErrors(null);
    } else if (name && list.indexOf(name) === -1) {
      country.setErrors({ country: true });
    }
  };
};
