import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProductChainResult } from '@app/core/interface/productchain.interface';

@Component({
  selector: 'app-batch-status-update',
  templateUrl: './batch-status-update.component.html',
  styleUrls: ['./batch-status-update.component.scss'],
})
export class BatchStatusUpdateComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isDeactivate: boolean;
      isActivate?: boolean;
      product: IProductChainResult;
      batchName: string;
      isMarkStep: boolean;
    }
  ) {}
}
