import { IProductChainResult, IProductChainsResponse, IStepSupplierStatus } from '../interface/productchain.interface';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { ProductChainService } from '../service/productchain.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ProductChainFacade {
  constructor(private _productChainService: ProductChainService) {}

  getProductChains$(): Observable<IProductChainsResponse> {
    return this._productChainService.getProductChains$().pipe(
      take(1),
      catchError((err): ObservableInput<IProductChainsResponse> => throwError(() => err.error))
    );
  }

  createProductChain$(data: Partial<IProductChainResult>): Observable<IProductChainResult> {
    return this._productChainService.createProductChain$(data).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }

  duplicateProductChain$(uuid: string, data: Partial<IProductChainResult>): Observable<IProductChainResult> {
    return this._productChainService.duplicateProductChain$(uuid, data).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }

  updateProductChainActivation$(data: Partial<IProductChainResult>, uuid: string): Observable<IProductChainResult> {
    return this._productChainService.updateProductChainActivation$(data, uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }
  updateProductChain$(data: Partial<IProductChainResult>, uuid: string): Observable<IProductChainResult> {
    return this._productChainService.updateProductChain$(data, uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }

  getProductChain$(uuid: string): Observable<IProductChainResult> {
    return this._productChainService.getProductChain$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IProductChainResult> => throwError(() => err.error))
    );
  }

  getStepSupplierStatus$(uuid: string, type: string): Observable<IStepSupplierStatus> {
    return this._productChainService.getStepSupplierStatus$(uuid, type).pipe(
      take(1),
      catchError((err): ObservableInput<IStepSupplierStatus> => throwError(() => err.error))
    );
  }

  publishProductChain$(uuid: string): Observable<IProductChainResult> {
    return this._productChainService.publishProductChain$(uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }

  changeProductChainStatus$(uuid: string, status: string): Observable<IProductChainResult> {
    return this._productChainService.changeProductChainStatus$(uuid, status).pipe(
      take(1),
      catchError(({ error }: IProductChainResult): ObservableInput<IProductChainResult> => throwError(() => error))
    );
  }
}
