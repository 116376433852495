<!-- use primary, secondary or tetriary-link like input color for diferent type of button -->
<button mat-raised-button ngClass="{{ color }} {{ size }}" [disabled]="disabled">
  <div class="text-holder">
    <app-custom-icon
      *ngIf="icon_left"
      class="left"
      [icon_category]="icon_category"
      [icon_name]="icon_name"
    ></app-custom-icon>
    <p class="left" [ngClass]="!icon_right && !icon_left ? 'border-bottom' : ''">{{ button_text | translate }}</p>
    <app-custom-icon
      *ngIf="icon_right"
      class="right"
      [icon_category]="icon_category"
      [icon_name]="icon_name"
    ></app-custom-icon>
  </div>
</button>
