<div class="header">
  <div class="breadcrumb d-flex align-items-center">
    <span class="d-flex">
      <app-custom-icon
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-right'"
        class="back-arrow"
      ></app-custom-icon>
    </span>
    <p class="invite-users">
      {{ 'Invite Users' | titlecase }}
    </p>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'" class="right-arr"></app-custom-icon>
    <h5 class="edit-txt">
      {{ 'Edit Email Content' | titlecase }}
    </h5>
  </div>
</div>
<hr />
<div class="cont-wrap">
  <app-create-email-content
    #content
    id="content"
    class="content invitation-text"
    [lang]="data.template.email_template_type.language"
    (resetForm)="setForm()"
    [isEditModal]="true"
  >
  </app-create-email-content>
</div>
<div class="btn-sec d-flex flex-wrap justify-content-end full-width">
  <app-rounded-button
    size="md"
    (click)="cancel()"
    color="tetriary-link"
    class="link"
    button_text="{{ 'Cancel' | translate }}"
  >
  </app-rounded-button>

  <app-rounded-button
    color="turquoise"
    mat-button
    mat-raised-button
    size="md"
    [button_text]="'Update content' | translate"
    [disabled]="disabled"
    (click)="!disabled && updateContent()"
    [icon_category]="'files'"
    [icon_name]="'sheet'"
    [icon_right]="true"
  >
  </app-rounded-button>
</div>
