import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class ProjectsGroup {
  projectForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.projectForm = this._fb.group(
      {
        name: [null, [Validators.required]],
        estimated_start_date: [null],
        estimated_end_date: [null],
        status: [null, [Validators.required]],
        address: [null],
        city: [null],
        country: [null],
        logo: [null],
        description: [null],
        statezipcode: [null],
        project: [null],
        is_location_specific: [false],
        custom_fields: this._fb.array([]),
      },
      { validator: IsCountry() }
    );
  }

  createFieldForm(): UntypedFormGroup {
    return this._fb.group({
      field_uuid: [null],
      field_value: [null],
      field_options: [[]],
    });
  }
}
