import {
  IDeleteSupplierSite,
  IManufactory,
  IManufactoryCategoriesResponse,
} from '../interface/manufactories.interface';

import { Injectable } from '@angular/core';
import { ManufactoriesService } from '../service/manufactories.service';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ManufactoriesFacade {
  constructor(private _manufactoriesService: ManufactoriesService) {}

  getSupplierSiteDetails$(uuid: string): Observable<IManufactory> {
    return this._manufactoriesService.getSupplierSiteDetails$(uuid).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }

  createSupplierSite$(data: Partial<IManufactory>): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._manufactoriesService.createSupplierSite$(formData).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }

  updateSupplierSite$(uuid: string, data: Partial<IManufactory>): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._manufactoriesService.updateSupplierSite$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }

  deleteSupplierSite$(uuid: string): Observable<IDeleteSupplierSite> {
    return this._manufactoriesService.deleteSupplierSite$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteSupplierSite): ObservableInput<IDeleteSupplierSite> => throwError(() => error))
    );
  }

  getManufactoryCategories$(): Observable<IManufactoryCategoriesResponse> {
    return this._manufactoriesService.getManufactoryCategories$().pipe(
      take(1),
      catchError((err): ObservableInput<IManufactoryCategoriesResponse> => throwError(() => err.error))
    );
  }

  getSupplierSites$(uuid: string): Observable<IManufactory[]> {
    return this._manufactoriesService.getSupplierSites$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IManufactory[]> => throwError(() => err.error))
    );
  }
}
