import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ImageEditPreviewComponent } from './../image-edit-preview/image-edit-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() placeholder = 'Select Images';
  @Input() iconType = 'attach_file';
  @Output() fileData = new EventEmitter<File>();
  @Input() required = false;
  @Input() disabled = false;
  @Input() uploadFormControl = new UntypedFormControl(null, [Validators.required]);
  @Input() imageUrl?: string | null = '';
  @Input() status!: string;
  @Input() uploadImageText = 'Upload image';
  @Input() uploadImageSubtext = 'png.jpg.svg / maximum 5MB';
  @Input() readonly = false;
  @Input() uploadBtnText = '';
  @Input() title = '';
  @Input() fileDataInput?: File;
  @Input() isCancelable = false;
  styleFormControl = new UntypedFormControl();
  selectedFiles?: FileList | null;
  selectedFileName: string | undefined = '';
  imageChangedEvent!: Event;
  previews: string[] = [];
  docURL = '';

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public translate: TranslateService
  ) {}

  /**
   * On Oninit we have the needed data and we are setting validation to
   * the field that we are using for display validation clasess
   */
  ngOnInit(): void {
    this.setValidation();
    this.setTranslationData();
  }

  setTranslationData(): void {
    this.uploadImageText = this.translate.instant(`company-information.${this.uploadImageText}`);
  }
  /**
   * This lifecycle hook allows us to control disabled property on file upload, from parrent component
   * Also sets image url and name if imageURL is changed and if it is string (the string is URL of the picture that needsd to ne edit ,
   *  if the user uploads a picture we handle that on upload  picture method)
   * @param changes Parent Changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) changes['disabled'].currentValue ? this.disableControls() : this.enableControls();
    if (changes['imageUrl'] && typeof changes['imageUrl'].currentValue === 'string') {
      if (changes['imageUrl'].currentValue !== '') {
        this.docURL = changes['imageUrl'].currentValue;
        const sepatateUrl = changes['imageUrl'].currentValue.split('/');
        this.selectedFileName = sepatateUrl[sepatateUrl.length - 1];
        if (this.isCancelable) this.previews = [this.docURL]; // Update previews with the new URLx
      } else {
        this.docURL = '';
        this.previews = [];
      }
    }
    if (changes['fileDataInput']) {
      const file = changes['fileDataInput'].currentValue;
      this.imageChangedEvent = file;
    }
  }
  /**
   * On file select we are oppening edit image popup
   * @param event selected file event
   */
  selectFiles(event: Event): void {
    this.selectedFileName = '';
    const eventTarget = event.target as HTMLInputElement;
    this.selectedFiles = eventTarget.files;
    this.imageChangedEvent = event;
    this.previews = [];

    if (this.selectedFiles && this.selectedFiles[0]) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          this.previews.push(e.target.result as string);
        }
      };
      reader.readAsDataURL(this.selectedFiles[0]);
      //this.fileData.emit(this.selectedFiles[0]);
      this.selectedFileName = this.selectedFiles[0].name;
      this.setUploadLabel();
      const dialogRef = this.dialog.open(ImageEditPreviewComponent, {
        width: '1160px',
        height: '786px',
        panelClass: 'position-relative',
        data: {
          event,
          name: this.selectedFileName,
          docUrl: this.docURL,
          alredyUpladed: false,
        },
      });

      dialogRef.afterClosed().subscribe((result: { file: File; event: Event }) => {
        if (result) {
          this.previews = [];
          this.fileData.emit(result.file);
          // Update the imageChangedEvent with the new event data
          this.imageChangedEvent = result.event;
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target && e.target.result) {
              this.docURL = e.target.result as string;
              this.previews.push(this.docURL);
            }
          };
          if (result && result.file) {
            reader.readAsDataURL(result.file);
          }
        }
      });
    } else {
      this.fileData.emit(undefined);
    }
    this.setValidation();
  }
  /**
   * Sets value to style field
   */
  setUploadLabel(): void {
    const placeholderName = this.selectedFileName ? this.selectedFileName : this.placeholder;
    this.styleFormControl.setValue(placeholderName);
  }

  /**
   * We are stylyng input upload field with another since we have to hide original
   * In this method we are cleareing validators if original field is valid and we put maxLenght 0 in order to show
   * invalid style if original field is invalid,
   * @returns validation of the field
   */
  setValidation(): void {
    this.status === 'INVALID'
      ? this.styleFormControl.addValidators([Validators.maxLength(0)])
      : this.styleFormControl.clearValidators();
    this.setUploadLabel();
  }

  /**
   * Opens edit Image dialog, after succesful edit we are trigering fileData event to save the image and
   * set the result in perviews
   */

  openImageEditDialog(): void {
    const event = this.imageChangedEvent;
    const dialogRef = this.dialog.open(ImageEditPreviewComponent, {
      data: {
        event,
        name: this.selectedFileName,
        title: this.title,
        docUrl: this.docURL,
        alredyUpladed: true,
      },
      width: '1160px',
      height: '786px',
      panelClass: 'position-relative',
    });

    dialogRef.afterClosed().subscribe((result: { file: File; event: Event }) => {
      if (result) {
        this.previews = [];
        this.fileData.emit(result.file);
        // Update the imageChangedEvent with the new event data
        this.imageChangedEvent = result.event;

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target && e.target.result) {
            this.docURL = e.target.result as string;
            this.previews.push(this.docURL);
          }
        };
        if (result && result.file) {
          this.selectedFileName = result.file.name;
          reader.readAsDataURL(result.file);
        }
      }
    });
  }

  /**
   * Disables controls
   */
  disableControls() {
    this.uploadFormControl.disable();
    this.styleFormControl.disable();
  }

  /**
   * Enables controls
   */
  enableControls() {
    this.styleFormControl.enable();
    this.uploadFormControl.enable();
  }
}
