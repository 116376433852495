import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
})
export class ImageCardComponent {
  @Input() image?: string | null;
  @Input() title!: string;
  @Input() innerHTMLDescription!: string;
  @Input() selected!: boolean;
}
