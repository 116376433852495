<div class="dialog-wrapper">
  <div class="breadcrump">
    <app-custom-icon
      id="arrow-left"
      mat-dialog-close
      [icon_category]="'arrows'"
      [icon_name]="'arrow-circle-left-short'"
    ></app-custom-icon>
    <h4 mat-dialog-close>{{ 'All Certificates' | translate }}</h4>
    <app-custom-icon id="arrow-right" [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
    <h3>{{ 'Certificate Details' | translate }}</h3>
    <div class="close">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
    </div>
  </div>

  <div class="h-line">
    <div class="horizontal-line mb-2"></div>
  </div>

  <div class="header-holder">
    <div class="title-holder">
      <h2>
        {{ certificate.certificate?.name ? certificate.certificate.name : ('View File' | translate) }}
      </h2>
      <div class="icon-holder">
        <div class="calendar">
          <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar'"> </app-custom-icon>
        </div>
        <p class="calendar-text">
          {{ 'Starts:' | translate }}
          {{ (certificate.validity_start ? certificate.validity_start : '') | date: 'dd.MM.YY' }}
        </p>
        <div class="danger-red">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'"> </app-custom-icon>
        </div>
        <p class="danger-text">
          {{ 'Expires:' | translate }}
          {{ (certificate.validity_end ? certificate.validity_end : '') | date: 'dd.MM.YY' }}
        </p>
      </div>
    </div>
  </div>

  <div class="data-wrapper">
    <div class="data-holder">
      <div class="data">
        <p>{{ 'certificated subject' | translate }}</p>
        <div class="product-data">
          <div class="product-img">
            <img class="img" src="{{ productLogo }}" />
          </div>
          <div class="product-name">
            <p>{{ productName }}</p>
          </div>
        </div>
      </div>
      <div class="data">
        <p>{{ 'certificator' | translate }}</p>
        <h6>
          {{
            certificate.certificator !== null && certificate.certificator !== 'null' && certificate.certificator !== ''
              ? certificate.certificator
              : 'Not set'
          }}
        </h6>
      </div>
      <div class="data">
        <p>{{ 'area' | translate }}</p>
        <h6>{{ certificate.certificate?.domain?.name }}</h6>
      </div>
      <div class="data">
        <p>{{ 'certificate category' | translate }}</p>
        <h6>{{ certificate.certificate?.category?.name }}</h6>
      </div>
    </div>
  </div>

  <mat-dialog-content class="mat-typography ngx-doc-holder">
    <div class="row">
      <div class="img-view-holder" [ngClass]="{ 'col-9': fileList.length, 'col-12': !fileList.length }">
        <ngx-doc-viewer
          *ngIf="selectedFile.mimetype && !(selectedFile.mimetype.indexOf('image') > -1)"
          [url]="docURL + '?embedded=true'"
          [viewer]="viewer"
          [ngClass]="{ 'col-8': fileList.length, 'col-12': !fileList.length }"
        >
        </ngx-doc-viewer>
      </div>
    </div>
  </mat-dialog-content>
</div>
