import { IFilesMeadiasLinks, IMediaContentType, IMediaResponse, IMediaScreens } from '../interface/medias.interface';
import { Observable, ObservableInput, catchError, take, throwError } from 'rxjs';

import { FilesMediasLinksService } from '../service/files-medias-links.service';
import { Injectable } from '@angular/core';
import { generateFormData } from '../utils/generate-formData';

@Injectable()
export class FilesMeadiasLinksFacade {
  constructor(private _addFieldsService: FilesMediasLinksService) {}

  createMedia$(
    data: Partial<IFilesMeadiasLinks>,
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens
  ): Observable<IMediaResponse> {
    const formData = generateFormData(data);

    return this._addFieldsService.createMedia$(formData, type, typeId, screen).pipe(
      take(1),
      catchError(({ error }: IMediaResponse): ObservableInput<IMediaResponse> => throwError(() => error))
    );
  }

  deleteMedia$(
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens,
    screenUuid: string
  ): Observable<IMediaResponse> {
    return this._addFieldsService.deleteMedia$(type, typeId, screen, screenUuid).pipe(
      take(1),
      catchError(({ error }: IMediaResponse): ObservableInput<IMediaResponse> => throwError(() => error))
    );
  }
  updateMedia$(
    data: Partial<IFilesMeadiasLinks>,
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens,
    screenUuid: string
  ): Observable<IMediaResponse> {
    const formData = generateFormData(data);

    return this._addFieldsService.updateMedia$(formData, type, typeId, screen, screenUuid).pipe(
      take(1),
      catchError(({ error }: IMediaResponse): ObservableInput<IMediaResponse> => throwError(() => error))
    );
  }

  updatePublicationStepMedia$(
    data: Partial<IFilesMeadiasLinks>,
    stepUuid: string,
    uuid: string
  ): Observable<IMediaResponse> {
    const formData = generateFormData(data);

    return this._addFieldsService.updatePublicationStepMedia$(formData, stepUuid, uuid).pipe(
      take(1),
      catchError(({ error }: IMediaResponse): ObservableInput<IMediaResponse> => throwError(() => error))
    );
  }
  /**
   *
   * @param type by getting the type we are checking in alowed
   * @returns boolean if type is valid
   */
  isValidUploadType(type: string, allowedTypes: Array<string>): boolean {
    const splitType = type.split('/');
    const ext = splitType[splitType.length - 1];
    return allowedTypes.includes(ext);
  }
}
