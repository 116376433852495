<div class="custom-card" [ngClass]="{ selected: selected }">
  <!-- <app-custom-icon *ngIf="selected" [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon> -->
  <div class="icon-div">
    <app-custom-icon *ngIf="selected" [icon_category]="'essential'" [icon_name]="'tick-blank'"></app-custom-icon>
  </div>

  <img src="{{ image ? image : '../../../../assets/images/image-placeholder.jpg' }}" />
  <h5>{{ title }}</h5>
  <p>
    <app-view-rich-text [content]="innerHTMLDescription"></app-view-rich-text>
  </p>
</div>
