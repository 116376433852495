import { ICountry } from '../interface/suppliers.interface';

/**
 * Filters countries on user typing or selecting dropdown
 * @param value value entered by user
 * @returns filtered list of countries
 */
export const SetCountry = (countryList: ICountry[], value?: string | ICountry): ICountry | undefined => {
  if (value) {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.code.toLowerCase();
    const selectedCountry = countryList.find((country: ICountry) => {
      if (country.code.toLowerCase() === filterValue || country.name.toLowerCase() === filterValue) {
        return country;
      } else {
        return;
      }
    });
    return selectedCountry;
  } else {
    return undefined;
  }
};
/**
 * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
 * @param subject ICountry from country dropdown
 * @returns country name for display
 */
export const DisplayCountryName = (subject: ICountry | null): string => {
  return subject?.name ?? '';
};
