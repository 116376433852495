import {
  IBatch,
  IBatchQuanityUnit,
  IBatchQuanityUnitResponse,
  IBatchResponse,
  IBatchResult,
} from './../interface/batch.interface';

import { HttpClient } from '@angular/common/http';
import { IProductChainStepResponse } from '../interface/steps.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  createBatch$(data: FormData): Observable<IBatch> {
    return this._http.post<IBatch>(`${this.apiUrl}/batches/`, data);
  }

  updateBatch$(uuid: string, data: FormData): Observable<IBatch> {
    return this._http.patch<IBatch>(`${this.apiUrl}/batches/${uuid}/`, data);
  }

  getAllQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._http.get<IBatchQuanityUnitResponse>(`${this.apiUrl}/brands/quantityunit/`);
  }

  getQuantityUnit$(uuid: string): Observable<IBatchQuanityUnitResponse> {
    return this._http.get<IBatchQuanityUnitResponse>(`${this.apiUrl}/brands/quantityunit/${uuid}/`);
  }

  getBatchQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._http.get<IBatchQuanityUnitResponse>(
      `${this.apiUrl}/brands/quantityunit/?is_assign_to_batch=True&is_active=True`
    );
  }

  getActiveQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._http.get<IBatchQuanityUnitResponse>(`${this.apiUrl}/brands/quantityunit/?is_active=True`);
  }

  createQuantityUnit$(data: FormData): Observable<IBatchQuanityUnit> {
    return this._http.post<IBatchQuanityUnit>(`${this.apiUrl}/brands/quantityunit/`, data);
  }

  updateQuantityUnit$(uuid: string, data: FormData): Observable<IBatchQuanityUnit> {
    return this._http.patch<IBatchQuanityUnit>(`${this.apiUrl}/brands/quantityunit/${uuid}/`, data);
  }

  getBatch$(uuid: string): Observable<IBatchResult> {
    return this._http.get<IBatchResult>(`${this.apiUrl}/batchchain/${uuid}/`);
  }

  updateBatchChainActivation$(uuid: string, data: Partial<IBatch>): Observable<IBatch> {
    return this._http.patch<IBatch>(`${this.apiUrl}/batchchain/${uuid}/activate/`, data);
  }

  updateBatchChain$(uuid: string, data: Partial<IBatch>): Observable<IBatch> {
    return this._http.patch<IBatch>(`${this.apiUrl}/batchchain/${uuid}/`, data);
  }

  getBatchSteps$(): Observable<IProductChainStepResponse> {
    return this._http.get<IProductChainStepResponse>(`${this.apiUrl}/batchsteps/`);
  }

  getBatchList$(): Observable<IBatchResponse> {
    return this._http.get<IBatchResponse>(`${this.apiUrl}/batchchain/`);
  }
}
