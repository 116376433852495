import { inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  previousUrl = '';
  currentUrl = '';
  isTermsAndConditions = signal(false);

  router = inject(Router);

  constructor() {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  openTermAndCondition(url: string): void {
    this.isTermsAndConditions.set(true);
    this.router.navigate([url]);
  }
}
