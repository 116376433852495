<ng-container *ngFor="let action of actionLists">
  <div
    class="menu-option align-items-center"
    [ngClass]="{
      hidden:
        (action.operation === ActionListOperations.EditProductChain && this.element.status !== 'COMPLETE') ||
        (action.operation === ActionListOperations.CreateBatch && this.element.status !== 'COMPLETE'),
    }"
    (click)="click(action.operation)"
    *ngIf="action.operation === ActionListOperations.OpenChainList ? element.steps.length : true"
  >
    <app-custom-icon
      [icon_category]="action.icon_category"
      [icon_name]="action.icon_name"
      [id]="action.id"
    ></app-custom-icon>
    <p
      [ngStyle]="{
        color:
          action.id === 'deactivate' || action.id === 'delete' || action.id === 'deactivate-red'
            ? '#e20613'
            : action.id === 'reactive'
              ? '#0db496'
              : 'inherit',
      }"
    >
      {{ action.keyword | translate }}
    </p>
  </div>
</ng-container>
