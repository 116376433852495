<div class="d-flex flex-column wrapper">
  <p class="label">{{ label }}</p>
  <div class="input-box">
    <p *ngIf="value" class="value">{{ value }}</p>
    <p *ngIf="number" class="value">
      <span class="label">{{ 'Number' | translate }}: </span>{{ number }}
    </p>
    <p *ngIf="type" class="value">
      <span class="label">{{ 'Type' | translate }}:</span> {{ type }}
    </p>
  </div>
</div>
