import { Pipe, PipeTransform } from '@angular/core';

import { languageMap } from '@app/shared/constants/language-codes.constant';

@Pipe({
  name: 'shortToFullLanguage',
})
export class ShortToFullLanguagePipe implements PipeTransform {
  transform(value: string): string {
    return languageMap[value] || value;
  }
}
