import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMedia, IMediaContentType, IMediaScreens } from '@app/core/interface/medias.interface';

import { AddDocumentsAndMediasComponent } from '../add-documents-and-medias/add-documents-and-medias.component';
import { FileViewComponent } from '../file-view/file-view.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { ICertificationSupplier } from '@app/core/interface/suppliers.interface';
import { ImageEditPreviewComponent } from '../image-edit-preview/image-edit-preview.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType, Storage, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { IStepCertification } from '@app/core/interface/steps.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { DataStorageService } from '@service/data-localstorage.service';

@Component({
  selector: 'app-step-media-accordion',
  templateUrl: './step-media-accordion.component.html',
  styleUrls: ['./step-media-accordion.component.scss'],
})
export class StepMediaAccordionComponent {
  @Input() photos?: IMedia[];
  @Input() videos?: IMedia[];
  @Input() documents?: IMedia[];
  @Input() certificates?: ICertificationSupplier[];
  @Input() stepCertificates?: IStepCertification[];
  @Input() canUpload? = false;
  @Input() typeUuid?: string;
  @Input() type: IMediaContentType = 'publicationsteps';
  @Input() canDelete? = false;
  @Input() certificatesTooltip? = '';
  @Input() photosTooltip? = '';
  @Output() refreshStep = new EventEmitter<boolean>();
  photosOpen = true;
  videosOpen = false;
  documentOpen = false;
  certificatesOpen = false;
  @Input() hideUploadCertificates = true;
  isAmway = true;
  constructor(
    private _dialog: MatDialog,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService
  ) {}

  ngOnInit() {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    this.isAmway = brandName?.toLowerCase() === 'amway';
  }
  addFile(screen: IMediaScreens) {
    const dialog = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      data: {
        screen: screen,
        type: this.type,
        typeUuid: this.typeUuid,
      },
      panelClass: 'padding-0',
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.refreshStep.emit(true);
      }
    });
  }

  viewFile(file: IMedia, screen: IMediaScreens) {
    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'custom-modalbox',
      data: {
        url: file.file,
        name: file.name,
        mimetype: file.mimetype,
        created_at: file.created_at,
        isBatch: true,
        backTitle: 'Step Details',
        screen: screen,
      },
    });
  }

  deleteItem(id: string, mediasType: IMediaScreens = 'medias'): void {
    if (this.canDelete && this.typeUuid) {
      this._facade.deleteMedia$(this.type, this.typeUuid, mediasType, id).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  removeItem(img: IMedia, mediasType: IMediaScreens) {
    // Make the type in singular. Ex. photos -> photo.
    const type = mediasType.slice(0, -1);

    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to delete this ${type}. Please note that if you delete the selected ${type}, the changes cannot be undone.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && img.uuid !== null)
        this.deleteItem(img.uuid, mediasType === 'photos' || mediasType === 'videos' ? 'medias' : mediasType);
    });
  }

  onSelectFile(eventSelectFile: Event): void {
    const selectedFile = eventSelectFile.target as HTMLInputElement;

    const file = selectedFile.files?.[0];
    if (selectedFile.files && selectedFile.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile.files[0]);
      reader.onload = event => {
        if (event.target) {
          const img: IMedia = {
            file: file?.name ?? '',
            name: (eventSelectFile.target as HTMLInputElement).files?.[0].name ?? '',
            url: event.target.result as string,
            mimetype: '',
            uuid: '',
          };
          this.openImageEditDialg(eventSelectFile, img, true);
        }
      };
    }
  }

  editDocument(doc: IMedia, typeScreen: string) {
    const dialogRef = this._dialog.open(AddDocumentsAndMediasComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        screen: typeScreen,
        type: this.type,
        typeUuid: this.typeUuid,
        doc: doc,
        edit: true,
      },
    });

    dialogRef.afterClosed().subscribe((doc: IMedia) => {
      if (doc) this._success();
    });
  }

  openImageEditDialg(event: Event, img: IMedia, isNewUpload?: boolean): void {
    const dialogRef = this._dialog.open(ImageEditPreviewComponent, {
      data: {
        newUpload: isNewUpload,
        event,
        name: img.name,
        title: 'Step Update',
        docUrl: img.file,
        doc: img,
        type: this.type,
        typeUuid: this.typeUuid,
      },
      width: '1160px',
      height: '768px',
      panelClass: 'position-relative',
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.file == null) {
        this.deleteItem(img.uuid);
      } else if (res) this._success();
    });
  }

  hideMedia(media: IMedia, is_entity_private: boolean): void {
    if (this.typeUuid) {
      this._facade.updateMedia$({ is_entity_private }, this.type, this.typeUuid, 'medias', media.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success() {
    this.refreshStep.emit(true);
    this._snackbarService.openTypeSnackbar(`Successfully updated`, NotificationType.success);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  openCertificate(certificate: ICertificationSupplier) {
    this._dialog.open(FileViewComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'custom-modalbox',
      data: {
        url: certificate.document,
        name: certificate.certificate.name,
        mimetype: 'application/pdf',
        isBatch: true,
        backTitle: 'Supplier Details',
        screen: screen,
      },
    });
  }

  getCertificateType(document: string) {
    const documentSplitted = document.split('.');
    return documentSplitted[documentSplitted.length - 1] === 'pdf';
  }

  getCertificateUrl(document: string) {
    if (this.getCertificateType(document)) {
      return document;
    } else {
      return '';
    }
  }
}
