<div class="video-controler" (mouseenter)="isOverlayVisible = true" (mouseleave)="isOverlayVisible = false">
  <div [ngClass]="{ overlay: true, 'hidden-overlay': !isOverlayVisible && !videoPlayer.ended }"></div>
  <video
    (play)="playVideo()"
    (pause)="pauseVideo()"
    id="my_video"
    [src]="docURL"
    class="preview card-cover"
    [controls]="false"
    (ended)="ended()"
    [ngClass]="{ 'multiple-videos-v': drawerOpened }"
    #videoPlayer
  ></video>

  <div
    class="play-btn"
    (click)="togglePlay()"
    matTooltip="{{ show_play_icon ? 'Play' : ('Pause' | translate) }}"
    matTooltipClass="custom-tooltip above"
    matTooltipPosition="above"
    matTooltipShowDelay="3000"
    matTooltipHideDelay="500"
  >
    <app-custom-icon
      [ngClass]="show_play_icon ? 'visible' : 'invisible'"
      [icon_category]="'essential'"
      [icon_name]="'video-white'"
    >
    </app-custom-icon>
    <app-custom-icon
      id="pause-video-icon"
      [ngClass]="show_play_icon ? 'invisible' : 'visible'"
      [icon_category]="'essential'"
      [icon_name]="'pause-white'"
    >
    </app-custom-icon>
  </div>
  <div [ngClass]="{ 'multiple-videos': drawerOpened, 'video-controls': true }" *ngIf="videoPlayer">
    <div class="row">
      <div class="col-3">
        <app-custom-icon
          [ngClass]="{ play: show_play_icon, pause: !show_play_icon, 'ctl-icon': true }"
          [icon_category]="'essential'"
          [icon_name]="show_play_icon ? 'video' : 'pause-white'"
          (click)="togglePlay()"
          matTooltip="{{ show_play_icon ? 'Play' : ('Pause' | translate) }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          matTooltipShowDelay="1000"
          matTooltipHideDelay="500"
        >
        </app-custom-icon>

        <app-custom-icon
          (click)="fileList.indexOf(selectedFile) > 0 && onFileSelected(fileList[fileList.indexOf(selectedFile) - 1])"
          [ngClass]="{ 'disabled-icon': fileList.indexOf(selectedFile) === 0 }"
          [icon_category]="'essential'"
          [icon_name]="'next-white'"
          class="ctl-icon prev"
          matTooltip="{{ 'Previous' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          matTooltipHideDelay="500"
          matTooltipShowDelay="1000"
        ></app-custom-icon>

        <app-custom-icon
          (click)="
            fileList.indexOf(selectedFile) < fileList.length - 1 &&
              onFileSelected(fileList[fileList.indexOf(selectedFile) + 1])
          "
          [ngClass]="{ 'disabled-icon': fileList.indexOf(selectedFile) === fileList.length - 1 }"
          [icon_category]="'essential'"
          [icon_name]="'next-white'"
          class="ctl-icon"
          matTooltip="{{ 'Next' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          matTooltipShowDelay="1000"
          matTooltipHideDelay="500"
        ></app-custom-icon>
      </div>
      <div class="col-3">
        <app-custom-icon
          [icon_category]="'essential'"
          [icon_name]="'volume-white'"
          class="ctl-icon"
          matTooltip="{{ 'Volume' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          matTooltipShowDelay="1000"
          matTooltipHideDelay="500"
        ></app-custom-icon>
        <input
          type="range"
          [value]="videoPlayerRef?.nativeElement.volume ? +videoPlayerRef?.nativeElement.volume * 100 : 100"
          (input)="changeVolume($event)"
          min="0"
          max="100"
          step="1"
          class="volume"
        />
      </div>
      <div class="full-btn-wrap offset-5 col-1">
        <app-custom-icon
          id="full screen"
          [icon_category]="'grid'"
          [icon_name]="'maximize-2'"
          matTooltip="{{ 'Full screen' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          matTooltipShowDelay="1000"
          matTooltipHideDelay="500"
          (click)="toggleFullScreen()"
          class="full-btn cursor-pointer"
        >
        </app-custom-icon>
      </div>
    </div>
    <input
      class="timeline cursor-pointer"
      type="range"
      [(ngModel)]="percent"
      (input)="seekToTime($event)"
      min="0"
      max="100"
      step="1"
    />
  </div>
</div>
