<div class="email-d-h">
  <div class="row">
    <div class="col-4 cancel-btn">
      <app-rounded-button
        *ngIf="!data.template"
        color="primary"
        [button_text]="'Cancel'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-left'"
        [size]="'md'"
        [icon_left]="true"
        mat-dialog-close
        class="cancel-btn"
      >
      </app-rounded-button>
    </div>
    <div class="col-4 saas-l">
      <img
        src="https://res.cloudinary.com/doprzrpqj/image/upload/v1682594095/logo_sblus6.png"
        alt="Header Img"
        class="header-img"
      />
    </div>
    <div class="col-4" *ngIf="!data.template">
      <app-rounded-button
        color="primary"
        [button_text]="'Send Invitation'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right'"
        [size]="'md'"
        [icon_right]="true"
        (click)="send()"
      >
      </app-rounded-button>
    </div>
    <div class="col-1 offset-3 close-wrapper" *ngIf="data.template">
      <app-custom-icon
        class="close-icon cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        mat-dialog-close
      ></app-custom-icon>
    </div>
  </div>
</div>
<mat-dialog-content class="tmp-wrap">
  <app-invite-supplier-tmp [supplier]="data.supplier" [template]="data.template"></app-invite-supplier-tmp>
</mat-dialog-content>
