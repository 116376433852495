import { Injectable } from '@angular/core';
import { catchError, Observable, ObservableInput, take, throwError } from 'rxjs';
import {
  IActivateDeactivateCustomFieldResponse,
  ICustomField,
  ICustomFieldsListResponse,
  ICustomFieldsObjectType,
  IDeleteFieldOption,
  INameId,
  IUpdateProjectCustomFieldValues,
} from '../interface/add-fields.interface';
import { AddFieldsService } from '../service/add-fields.service';
import { generateFormData } from '../utils/generate-formData';

@Injectable()
export class AddFieldsFacade {
  constructor(private _addFieldsService: AddFieldsService) {}

  getCustomFields$(object: ICustomFieldsObjectType): Observable<ICustomFieldsListResponse> {
    return this._addFieldsService.getCustomFields$(object).pipe(
      take(1),
      catchError(
        ({ error }: ICustomFieldsListResponse): ObservableInput<ICustomFieldsListResponse> => throwError(() => error)
      )
    );
  }

  createCustomField$(object: ICustomFieldsObjectType, data: Partial<INameId>): Observable<INameId> {
    const formData = generateFormData(data);

    return this._addFieldsService.createCustomField$(object, formData).pipe(
      take(1),
      catchError(({ error }: INameId): ObservableInput<INameId> => throwError(() => error))
    );
  }

  editCustomField$(object: ICustomFieldsObjectType, data: Partial<INameId>, uuid: string): Observable<INameId> {
    const formData = generateFormData(data);

    return this._addFieldsService.editCustomField$(object, formData, uuid).pipe(
      take(1),
      catchError(({ error }: INameId): ObservableInput<INameId> => throwError(() => error))
    );
  }

  deleteCustomField$(object: ICustomFieldsObjectType, uuid: string): Observable<INameId> {
    return this._addFieldsService.deleteCustomField$(object, uuid).pipe(
      take(1),
      catchError(({ error }: INameId): ObservableInput<INameId> => throwError(() => error))
    );
  }

  activateDeactivateCustomFields$(
    uuid: string,
    data: Partial<IActivateDeactivateCustomFieldResponse>
  ): Observable<IActivateDeactivateCustomFieldResponse> {
    const formData = generateFormData(data);
    return this._addFieldsService.activateDeactivateCustomFields$(uuid, formData).pipe(
      take(1),
      catchError(
        ({ error }: IActivateDeactivateCustomFieldResponse): ObservableInput<IActivateDeactivateCustomFieldResponse> =>
          throwError(() => error)
      )
    );
  }

  ////////////////////////// Project and Product Custom Fields /////////////////////////////
  createCustomFieldWithType$(object_type: string, field: Partial<ICustomField>): Observable<ICustomField> {
    return this._addFieldsService.createCustomFieldWithType$(object_type, field).pipe(
      take(1),
      catchError(({ error }: ICustomField): ObservableInput<ICustomField> => throwError(() => error))
    );
  }

  editCustomFieldWithType$(object_type: string, uuid: string, field: Partial<ICustomField>): Observable<ICustomField> {
    return this._addFieldsService.editCustomFieldWithType$(object_type, uuid, field).pipe(
      take(1),
      catchError(({ error }: ICustomField): ObservableInput<ICustomField> => throwError(() => error))
    );
  }

  deleteCustomFieldOption$(object_type: string, uuid: string): Observable<IDeleteFieldOption> {
    return this._addFieldsService.deleteCustomFieldOption$(object_type, uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteFieldOption): ObservableInput<IDeleteFieldOption> => throwError(() => error))
    );
  }

  updateProjectCustomFieldValues$(
    object: IUpdateProjectCustomFieldValues[]
  ): Observable<IUpdateProjectCustomFieldValues[]> {
    return this._addFieldsService.updateProjectCustomFieldValues$(object).pipe(
      take(1),
      catchError(
        ({ error }: IUpdateProjectCustomFieldValues): ObservableInput<IUpdateProjectCustomFieldValues[]> =>
          throwError(() => error)
      )
    );
  }
}
