import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class AddFactoryGroup {
  addFactory: UntypedFormGroup;
  addFactorySupplier: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.addFactory = this._fb.group(
      {
        country: ['', Validators.required],
        name: ['', Validators.required],
        supplier: ['', Validators.required],
        description: [''],
        address: [''],
        city: [''],
        logo: [''],
        category: [''],
        h2o_consommation: [''],
        co2_production: [''],
        is_main_location: [null],
        region: [''],
      },
      { validator: IsCountry() }
    );
    this.addFactorySupplier = this._fb.group(
      {
        country: ['', Validators.required],
        name: ['', Validators.required],
        description: [''],
        address: [''],
        city: [''],
        logo: [''],
        // category: [''],
        // h2o_consommation: [''],
        // co2_production: [''],
        is_main_location: [false],
        region: [''],
      },
      { validator: IsCountry() }
    );
  }
}
