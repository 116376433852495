import { viewerType } from 'ngx-doc-viewer/lib/document-viewer.component';

export const getViewerType = (mimetype: string): viewerType => {
  if (mimetype.indexOf('application/vnd.openxmlformats-officedocument') > -1) {
    // Microsoft Office documents (docx, xlsx, pptx)
    return 'office';
  } else if (mimetype.indexOf('application/msword') > -1) {
    // Microsoft Word documents (doc)
    return 'office';
  }
  //  pdf is using url for now because the div arouund can be cleackable and user can switch
  //  files in file view other types must be correct in order not to download them but onlhy display them
  //  else if (mimetype.indexOf('pdf') > -1) {
  //   // PDF documents
  //   return 'pdf';
  // }
  else if (mimetype.indexOf('application/vnd.google-apps.document') > -1) {
    // Google Docs
    return 'google';
  } else {
    // Fallback to URL viewer for other document types
    return 'url';
  }
};
