import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ILanguagesResponse } from '../interface/languages.interface';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private apiUrl = environment.api;
  constructor(private _http: HttpClient) {}

  getLanguages$(): Observable<ILanguagesResponse> {
    return this._http.get<ILanguagesResponse>(`${this.apiUrl}/languages/`);
  }
}
