import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BatchFacade } from '@app/core/facade/batch.facade';
import { BatchStatusUpdateComponent } from '@app/module/dashboard/product/batch-status-update/batch-status-update.component';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-batches-list',
  templateUrl: './batches-list.component.html',
  styleUrls: ['./batches-list.component.scss'],
  providers: [BatchFacade],
})
export class BatchesListComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() dataSource = new MatTableDataSource<IProductChainResult>();
  @Input() batchesPaginator!: number[];
  @Input() pageSize!: number;
  @Output() refetchBatches = new EventEmitter<boolean>();
  displayedColumns = ['name', 'reference', 'productName', 'tags', 'status', 'actions'];
  constructor(
    private dialog: MatDialog,
    private _batchFacade: BatchFacade,
    private _router: Router
  ) {}

  ngAfterViewInit() {
    this.paginator.pageSize = this.pageSize;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'productName':
          return item['product'].name;
        case 'name':
          return item['batch'][0].name.toUpperCase();
        case 'reference':
          return item['batch'][0].reference;
        default:
          return item[property as keyof IProductChainResult] as string;
      }
    };
  }

  updateBatchStatus(isDeactivate: boolean, batch: IProductChainResult): void {
    const dialogRef = this.dialog.open(BatchStatusUpdateComponent, {
      width: '580px',
      height: '460px',
      data: {
        isDeactivate,
        isActivate: !isDeactivate,
        batchName: batch.batch[0].name,
        product: batch.product,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._batchFacade
          .updateBatchChainActivation$(batch.batch[0].batch_chain.uuid, {
            is_active: !isDeactivate,
          })
          .subscribe(() => {
            this.refetchBatches.emit(true);
          });
      }
    });
  }

  viewProductBatch(uuid: string) {
    this._router
      .navigate([`/chain-setup/batch-management/${uuid}`, { currentUrl: this._router.url, tab: 'batches' }])
      .then(() => window.location.reload());
  }
}
