import { Component, TemplateRef } from '@angular/core';

import { TooltipPosition } from './tooltip.constants';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  tooltipContent: TemplateRef<unknown> | null = null;
  position: TooltipPosition = TooltipPosition.BELOW;
  left = 0;
  top = 0;
  visible = false;
}
