import { Component, Input, inject } from '@angular/core';

import { NavigationService } from '@app/core/service/navigation.service';

@Component({
  selector: 'app-data-secured-badge',
  templateUrl: './data-secured-badge.component.html',
  styleUrl: './data-secured-badge.component.scss',
})
export class DataSecuredBadgeComponent {
  @Input() isColumnDirection = false;

  navigationService = inject(NavigationService);
}
