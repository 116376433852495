<form [formGroup]="form" class="wrapper-container">
  <h2 mat-dialog-title *ngIf="!data.isBatch">
    {{ title.slice(0, -1) | translate }}
  </h2>

  <div class="toggle-group" [hidden]="!manufactories">
    <mat-button-toggle-group #group="matButtonToggleGroup">
      <div class="toggle">
        <mat-button-toggle value="supplier" aria-label="Supplier Contacts" [checked]="true">
          <span>{{ 'Supplier' | translate }}</span>
        </mat-button-toggle>
      </div>
      <div class="toggle">
        <mat-button-toggle value="supplier-site" aria-label="Invited Users">
          <span>{{ 'Supplier Site' | translate }}</span>
        </mat-button-toggle>
      </div>
    </mat-button-toggle-group>
  </div>
  <div class="breadcrump" *ngIf="data.isBatch">
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-circle-left-short'"></app-custom-icon>
    <h4 mat-dialog-close>{{ data.backTitle ? data.backTitle : 'Creating Batch' }}</h4>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
    <h3>{{ title.slice(0, -1) | translate }}</h3>
  </div>
  <div class="wrapper" *ngIf="group.value === 'supplier'">
    <mat-dialog-content class="mat-typography dialog-cont" [ngClass]="{ 'batch-content': data.isBatch }">
      <div [ngClass]="{ 'info-div': data.isBatch }" id="infDiv" #infoDiv>
        <div *ngIf="data.screen !== 'videos'" class="logo-text-holder">
          <div class="logo" *ngIf="data.isBatch"></div>
          <p class="infotext">
            {{ infoMsg | translate }}
          </p>
        </div>

        <app-custom-icon
          *ngIf="data.isBatch"
          [icon_category]="'essential'"
          [icon_name]="'close-circle'"
          class="close-btn"
          (click)="infoDiv.style.display = 'none'"
        ></app-custom-icon>
      </div>
      <div class="form-div">
        <div *ngIf="data?.screen === 'documents'">
          <div class="card-cont">
            <div class="left-box">
              <p class="infotext3">
                {{ 'Document Name' | translate }}
              </p>
              <mat-form-field appearance="outline" class="full-width name input-primary">
                <mat-label>{{ 'Enter Document Name' | translate }}</mat-label>
                <input
                  matInput
                  class="name"
                  placeholder="{{ 'Enter Document Name' | translate }}"
                  formControlName="name"
                />
              </mat-form-field>
            </div>
            <div class="right-box">
              <div
                class="card-content"
                [ngClass]="{
                  'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                  'warn-uploaded': warn || typeWarn,
                }"
              >
                <div
                  class="document-icon"
                  [ngClass]="{
                    'document-icon-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded-icon': warn || typeWarn,
                  }"
                >
                  <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"></app-custom-icon>
                </div>
                <p class="" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded">
                  {{ ' maximum 10MB' | translate }}
                </p>
                <div class="upload-btn" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn">
                  <input
                    type="file"
                    class="file-upload"
                    #fileUpload
                    (change)="onSelectFile($event); setFile($event, 'document')"
                    accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .docx, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
                  />
                  <app-rounded-button
                    color="turquoise"
                    [button_text]="'Choose Document' | translate"
                    [icon_category]="'essential'"
                    [icon_name]="'add'"
                    [size]="'md'"
                    [icon_right]="true"
                    (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                  >
                  </app-rounded-button>
                  <span class="choose">
                    {{ 'choose from PC or Mac' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="formats-allowed offset-6"
            *ngIf="!warn && !image_uploaded && !video_uploaded && !document_uploaded"
          >
            <div class="row">
              <div class="col-1">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              </div>
              <div class="col-11">
                {{ 'Please choose a document in some of the following formats: ' | translate }}
                <div class="formats">
                  pdf / docx / doc / xlsx / xls / pptx / ppt / jpeg / jpg / gif / tiff / tif / jfif / png / mp4 / webm
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'proofs'">
          <div class="card-cont">
            <div class="left-box">
              <p class="infotext3">
                {{ 'Document Name' | translate }}
              </p>
              <mat-form-field appearance="outline" class="full-width name input-primary">
                <mat-label>{{ 'Document Name' | translate }}</mat-label>
                <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
              </mat-form-field>
            </div>
            <div class="right-box">
              <div
                class="card-content"
                [ngClass]="{
                  'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                  'warn-uploaded': warn || typeWarn,
                }"
              >
                <div
                  class="document-icon"
                  [ngClass]="{
                    'document-icon-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded-icon': warn || typeWarn,
                  }"
                >
                  <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"></app-custom-icon>
                </div>
                <p class="" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded">
                  {{ ' maximum 10MB' | translate }}
                </p>
                <div class="upload-btn" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn">
                  <input
                    type="file"
                    class="file-upload"
                    #fileUpload
                    (change)="onSelectFile($event); setFile($event, 'document')"
                    accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .docx, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
                  />
                  <app-rounded-button
                    color="turquoise"
                    [button_text]="'Choose Document' | translate"
                    [icon_category]="'essential'"
                    [icon_name]="'add'"
                    [size]="'md'"
                    [icon_right]="true"
                    (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                  >
                  </app-rounded-button>
                  <span class="choose">
                    {{ 'choose from PC or Mac' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="formats-allowed offset-6" *ngIf="typeWarn">
            <div class="row">
              <div class="col-1">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              </div>
              <div class="col-11">
                {{ 'Please choose a media in some of the following formats:' | translate }}
                <div class="formats">
                  pdf / docx / doc / xlsx / xls / pptx / ppt / jpeg / jpg / gif / tiff / tif / jfif / png / mp4 / webm
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'medias'">
          <div class="">
            <div class="card-cont">
              <div class="left-box">
                <p class="infotext3">
                  {{ 'Media Name' | translate }}
                </p>
                <mat-form-field appearance="outline" class="full-width name input-primary">
                  <mat-label>{{ 'Media Name' | translate }}</mat-label>
                  <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
                </mat-form-field>
              </div>
              <div class="right-box">
                <div
                  class="card-content card-media-content"
                  [ngClass]="{
                    'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded': warn || typeWarn,
                  }"
                >
                  <div
                    class="document-icon"
                    [ngClass]="{
                      'image-uploaded': this.form.controls['file'].value && image_uploaded,
                      'video-uploaded': this.form.controls['file'].value && video_uploaded,
                      'warn-uploaded-icon': warn || typeWarn,
                    }"
                  >
                    <img *ngIf="image_uploaded && !warn && !typeWarn && is_image_uploaded" [src]="uploadedUrl" alt="" />
                    <video
                      *ngIf="video_uploaded && !warn && !typeWarn && !is_image_uploaded"
                      [src]="uploadedUrl"
                    ></video>
                    <app-custom-icon
                      *ngIf="!warn && !typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'upload-white'"
                    ></app-custom-icon>
                    <app-custom-icon
                      *ngIf="warn || typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'image-red'"
                    ></app-custom-icon>
                  </div>
                  <div
                    class="max-holder"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded"
                  >
                    <p class="">
                      {{ 'photos max 10MB' | translate }}
                    </p>
                    <p class="">
                      {{ 'videos max 25MB' | translate }}
                    </p>
                  </div>
                  <div
                    class="upload-btn"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn"
                  >
                    <input
                      type="file"
                      class="file-upload"
                      #fileUpload
                      (change)="onSelectFile($event); setFile($event, 'media')"
                      accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
                    />
                    <app-rounded-button
                      color="turquoise"
                      [button_text]="'Choose Media' | translate"
                      [icon_category]="'essential'"
                      [icon_name]="'add'"
                      [size]="'md'"
                      [icon_right]="true"
                      (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                    >
                    </app-rounded-button>
                    <span class="choose">
                      {{ 'choose from PC or Mac' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="formats-allowed offset-6" *ngIf="typeWarn">
            <div class="row">
              <div class="col-1">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              </div>
              <div class="col-11">
                {{ 'Please choose a media in some of the following formats:' | translate }}
                <div class="formats">
                  jpeg / jpg / gif / tiff / tif / jfif / png / mp4 / webm / pdf / docx / doc / xlsx / xls / pptx / ppt
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'links'">
          <div class="card-cont">
            <div class="left-box">
              <p class="infotext3">
                {{ 'Link Name' | translate }}
              </p>
              <mat-form-field appearance="outline" class="full-width name input-primary">
                <mat-label>{{ 'Link Name' | translate }}</mat-label>
                <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
              </mat-form-field>
            </div>
            <div class="right-box">
              <div
                class="card-content"
                [ngClass]="{
                  'file-uploaded': this.form.controls['url'].value && !urlWarn,
                  'warn-uploaded': this.form.controls['url'].value && urlWarn,
                }"
              >
                <div
                  class="document-icon"
                  [ngClass]="{
                    'document-icon-uploaded': this.form.controls['url'].value && !urlWarn,
                    'warn-uploaded-icon-url': this.form.controls['url'].value && urlWarn,
                  }"
                >
                  <app-custom-icon [icon_category]="'essential'" [icon_name]="'link-white'"></app-custom-icon>
                </div>
                <div class="input-link">
                  <input
                    class="url-input"
                    formControlName="url"
                    placeholder="{{ 'paste your link here' | translate }}"
                    (ngModelChange)="urlWarn = false"
                    [ngClass]="{
                      'success-input': this.form.controls['url'].value && !urlWarn,
                      'warn-input': this.form.controls['url'].value && urlWarn,
                    }"
                  />
                  <p class="col-12 link-text" *ngIf="!this.form.controls['url'].value; else linkUploaded">
                    {{ 'paste a link of a related website or ' + itemType | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="invalid-link offset-6" *ngIf="urlWarn">
            <div class="row">
              <div class="col-1">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              </div>
              <div class="col-11">
                {{ "This link isn't valid, please check it and try again" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'photos'">
          <div class="">
            <div class="card-cont">
              <div class="left-box">
                <p class="infotext3">
                  {{ 'Media Name' | translate }}
                </p>
                <mat-form-field appearance="outline" class="full-width name input-primary">
                  <mat-label>{{ 'Media Name' | translate }}</mat-label>
                  <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
                </mat-form-field>
              </div>
              <div class="right-box">
                <div
                  class="card-content card-media-content"
                  [ngClass]="{
                    'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded': warn || typeWarn,
                  }"
                >
                  <div
                    class="document-icon"
                    [ngClass]="{
                      'image-uploaded': this.form.controls['file'].value && image_uploaded,
                      'video-uploaded': this.form.controls['file'].value && video_uploaded,
                      'warn-uploaded-icon': warn || typeWarn,
                    }"
                  >
                    <img *ngIf="image_uploaded && !warn && !typeWarn && is_image_uploaded" [src]="uploadedUrl" alt="" />
                    <video
                      *ngIf="video_uploaded && !warn && !typeWarn && !is_image_uploaded"
                      [src]="uploadedUrl"
                    ></video>
                    <app-custom-icon
                      *ngIf="!warn && !typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'upload-white'"
                    ></app-custom-icon>
                    <app-custom-icon
                      *ngIf="warn || typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'image-red'"
                    ></app-custom-icon>
                  </div>
                  <div
                    class="max-holder"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded"
                  >
                    <p class="">
                      {{ 'photos max 10MB' | translate }}
                    </p>
                  </div>
                  <div
                    class="upload-btn"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn"
                  >
                    <input
                      type="file"
                      class="file-upload"
                      #fileUpload
                      (change)="onSelectFile($event); setFile($event, 'photo')"
                      accept="image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png,"
                    />
                    <app-rounded-button
                      color="turquoise"
                      [button_text]="'Choose Photo' | translate"
                      [icon_category]="'essential'"
                      [icon_name]="'add'"
                      [size]="'md'"
                      [icon_right]="true"
                      (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                    >
                    </app-rounded-button>
                    <span class="choose">
                      {{ 'choose from PC or Mac' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'videos'">
          <div class="">
            <div class="card-cont">
              <div class="left-box">
                <p class="infotext3">
                  {{ 'Video Name' | translate }}
                </p>
                <mat-form-field appearance="outline" class="full-width name input-primary">
                  <mat-label>{{ 'Video Name' | translate }}</mat-label>
                  <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
                </mat-form-field>
              </div>
              <div class="right-box">
                <div
                  class="card-content card-media-content"
                  [ngClass]="{
                    'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded': warn || typeWarn,
                  }"
                >
                  <div
                    class="document-icon"
                    [ngClass]="{
                      'image-uploaded': this.form.controls['file'].value && image_uploaded,
                      'video-uploaded': this.form.controls['file'].value && video_uploaded,
                      'warn-uploaded-icon': warn || typeWarn,
                    }"
                  >
                    <img *ngIf="image_uploaded && !warn && !typeWarn && is_image_uploaded" [src]="uploadedUrl" alt="" />
                    <video
                      *ngIf="video_uploaded && !warn && !typeWarn && !is_image_uploaded"
                      [src]="uploadedUrl"
                    ></video>
                    <app-custom-icon
                      *ngIf="!warn && !typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'upload-white'"
                    ></app-custom-icon>
                    <app-custom-icon
                      *ngIf="warn || typeWarn"
                      [icon_category]="'essential'"
                      [icon_name]="'image-red'"
                    ></app-custom-icon>
                  </div>
                  <div
                    class="max-holder"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded"
                  >
                    <p class="">
                      {{ 'videos max 25MB' | translate }}
                    </p>
                  </div>
                  <div
                    class="upload-btn"
                    *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn"
                  >
                    <input
                      type="file"
                      class="file-upload"
                      #fileUpload
                      (change)="onSelectFile($event); setFile($event, 'video')"
                      accept="video/mp4, video/webm"
                    />
                    <app-rounded-button
                      color="turquoise"
                      [button_text]="'Choose Video' | translate"
                      [icon_category]="'essential'"
                      [icon_name]="'add'"
                      [size]="'md'"
                      [icon_right]="true"
                      (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                    >
                    </app-rounded-button>
                    <span class="choose">
                      {{ 'choose from PC or Mac' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="data?.screen === 'certificates'">
          <div class="card-cont">
            <div class="left-box">
              <p class="infotext3">
                {{ 'Certificate Name' | translate }}
              </p>
              <mat-form-field appearance="outline" class="full-width name input-primary">
                <mat-label>{{ 'Certificate Name' | translate }}</mat-label>
                <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
              </mat-form-field>
            </div>
            <div class="right-box">
              <div
                class="card-content"
                [ngClass]="{
                  'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                  'warn-uploaded': warn || typeWarn,
                }"
              >
                <div
                  class="document-icon"
                  [ngClass]="{
                    'document-icon-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                    'warn-uploaded-icon': warn || typeWarn,
                  }"
                >
                  <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"></app-custom-icon>
                </div>
                <p class="" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded">
                  {{ ' maximum 10MB' | translate }}
                </p>
                <div class="upload-btn" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn">
                  <input
                    type="file"
                    class="file-upload"
                    #fileUpload
                    (change)="onSelectFile($event); setFile($event, 'certificate')"
                    accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .docx, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
                  />
                  <app-rounded-button
                    color="turquoise"
                    [button_text]="'Choose Certificate' | translate"
                    [icon_category]="'essential'"
                    [icon_name]="'add'"
                    [size]="'md'"
                    [icon_right]="true"
                    (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                  >
                  </app-rounded-button>
                  <span class="choose">
                    {{ 'choose from PC or Mac' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <app-rounded-button mat-dialog-close color="tetriary-link" [button_text]="'Cancel' | translate" [size]="'md'">
      </app-rounded-button>

      <app-rounded-button
        class="upload"
        color="primary"
        [button_text]="(data.screen === 'links' ? 'Add link' : data.edit ? 'Update' : 'Upload') | translate"
        [icon_category]="'essential'"
        [icon_name]="'upload'"
        [size]="'md'"
        [disabled]="form.invalid || form.pristine || warn"
        [icon_right]="true"
        (click)="form.valid && form.dirty && !warn && !typeWarn && save()"
      >
      </app-rounded-button>
    </mat-dialog-actions>

    <ng-template #uploaded>
      <div class="success-upload" *ngIf="!warn && !typeWarn">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick'" *ngIf="!data.edit"></app-custom-icon>

        <p class="uploaded-txt" *ngIf="!data.edit">
          {{
            image_uploaded
              ? 'Your photo is ready to be'
              : video_uploaded
                ? 'Your video is ready to be'
                : ('Your document is ready to be' | translate)
          }}
          {{ 'uploaded!' | translate }}
        </p>

        <p class="uploaded-txt" *ngIf="data.edit">
          {{ 'Your uploaded ' + data.screen | translate }}
        </p>
      </div>

      <div *ngIf="warn || typeWarn" class="warn-upload-holder">
        <div class="danger-icon">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
          <!-- <p *ngIf="is_image && warn" class="uploaded-txt">
          {{ 'Your document is larger than 10mb!' | translate }}
        </p>
        <p *ngIf="is_video && warn" class="uploaded-txt">
          {{ 'Your document is larger than 25mb!' | translate }}
        </p> -->
          <!--chack with the new design if videos should be 25mb above example or 10mb bellow example-->
          <p *ngIf="warn && (type === 'certificate' || type === 'document' || type === 'proofs')" class="uploaded-txt">
            {{ 'Your document is larger than 10mb!' | translate }}
          </p>
          <p *ngIf="warn && is_video && !is_image" class="uploaded-txt">
            {{ 'Your video is larger than 25mb!' | translate }}
          </p>
          <p *ngIf="warn && !is_video && is_image" class="uploaded-txt">
            {{ 'Your document is larger than 10mb!' | translate }}
          </p>
          <p *ngIf="typeWarn" class="uploaded-txt">
            {{ 'Your document format is not supported!' | translate }}
          </p>
        </div>
        <div class="try-again-holder">
          <p class="" *ngIf="warn">
            {{ 'Please upload the correct size' | translate }}
          </p>
          <p class="" *ngIf="typeWarn">
            {{ 'Please choose document with one of the formats bellow.' | translate }}
          </p>
          <app-rounded-button
            *ngIf="warn || typeWarn"
            color="turquoise"
            [button_text]="'Try again' | translate"
            [icon_category]="'arrows'"
            [icon_name]="'refresh-square'"
            [size]="'sm'"
            [icon_right]="true"
            (click)="tryAgain()"
          >
          </app-rounded-button>
        </div>
      </div>
    </ng-template>

    <ng-template #closeBtn>
      <app-custom-icon
        *ngIf="!warn && !typeWarn"
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        (click)="clearFileControl()"
        class="remove-file"
      ></app-custom-icon>
    </ng-template>

    <ng-template #linkUploaded>
      <p *ngIf="this.form.controls['url'].value && !urlWarn" class="link-text link-text-uploaded">
        {{ 'The link has been successfully added' | translate }}
      </p>
    </ng-template>
  </div>
  <div class="wrapper" *ngIf="group.value === 'supplier-site'">
    <app-add-medias-supplier-site [manufactories]="manufactories"></app-add-medias-supplier-site>
  </div>
</form>
