import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISupplierIngredient } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-create-component-dialog',
  templateUrl: './create-component-dialog.component.html',
  styleUrls: ['./create-component-dialog.component.scss'],
})
export class CreateComponentDialogComponent {
  @Output() toggleAdd = new EventEmitter<boolean>(false);
  selectedIngredient = {};
  sites = [{ name: 'test', uuid: 'test' }];
  selectedSupplier = {};
  suppliers = [{ name: 'test', uuid: 'test' }];
  supplierLogo: string | null | undefined;
  component_btn!: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mode: string;
      ingredient: ISupplierIngredient;
      supplierUUID: string;
      logo: string;
      component_btn: boolean;
    },
    public dialogRef: MatDialogRef<CreateComponentDialogComponent>
  ) {
    if (data) {
      this.supplierLogo = this.data.logo;
      this.component_btn = this.data.component_btn;
    }
  }

  cancel() {
    this.toggleAdd.emit(false);
  }

  close(event: boolean) {
    this.dialogRef.close(event);
  }
}
