import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActionListComponent } from './components/action-list/action-list.component';
import { AddDocumentsAndMediasComponent } from './components/add-documents-and-medias/add-documents-and-medias.component';
import { AddMediasSupplierSiteComponent } from './components/add-medias-supplier-site/add-medias-supplier-site.component';
import { AddSupplierComponent } from './components/chain-node/add-supplier/add-supplier.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { AngularMaterialModule } from '@app/material.module';
import { BatchesListComponent } from './components/chains-batches/batches-list/batches-list.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CertificateCardComponent } from './components/certificate-card/certificate-card.component';
import { CertificateViewComponent } from './components/certificate-view/certificate-view.component';
import { CertificateViewDetailsComponent } from './components/certificate-view-details/certificate-view-details.component';
import { CertificatesTabComponent } from './components/certificates-tab/certificates-tab.component';
import { ChainNodeBorderDirective } from './components/chain-node/chain-node.directive';
import { ChainNodeComponent } from './components/chain-node/chain-node.component';
import { ChainSetupHeaderComponent } from './components/chain-setup-header/chain-setup-header.component';
import { ChainsBatchesComponent } from './components/chains-batches/chains-batches.component';
import { ChainsListComponent } from './components/chains-batches/chains-list/chains-list.component';
import { CommonModule } from '@angular/common';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';
import { CookiesManagementComponent } from './components/cookies-management/cookies-management.component';
import { CreateComponentDialogComponent } from './components/create-component-dialog/create-component-dialog.component';
import { CreateProductDialogComponent } from './components/create-product-dialog/create-product-dialog.component';
import { CreateProductFormComponent } from './components/create-product-form/create-product-form.component';
import { CreateSiteFormComponent } from '@app/module/dashboard/product/product-ingredients/add-ingredient/create-supplier-and-site/create-site-form/create-site-form.component';
import { CustomFieldsFormComponent } from './components/custom-fields-form/custom-fields-form.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { DataSecuredBadgeComponent } from './components/data-secured-badge/data-secured-badge.component';
import { DeactivatedStepTooltipComponent } from './components/chain-node/deactivated-step-tooltip/deactivated-step-tooltip.component';
import { DocumentsTabComponent } from './components/documents-tab/documents-tab.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { EmailFacade } from '@app/core/facade/email.facade';
import { FileCardComponent } from './components/file-card/file-card.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { IllustrationDesignComponent } from './components/illustration-design/illustration-design.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditPreviewComponent } from './components/image-edit-preview/image-edit-preview.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { InfoInputDialogComponent } from './components/info-input-dialog/info-input-dialog.component';
import { InputInfoComponent } from './components/input-info/input-info.component';
import { InvitationsSendComponent } from './components/invite-users-dialog/invitations-send/invitations-send.component';
import { InviteSupplierTmpComponent } from './components/email-dialog/invite-supplier-tmp/invite-supplier-tmp.component';
import { InviteUsersDialogComponent } from './components/invite-users-dialog/invite-users-dialog.component';
import { InviteUsersListComponent } from './components/invite-users-dialog/invite-users-list/invite-users-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MiniCardComponent } from './components/mini-card/mini-card.component';
import { NgModule } from '@angular/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NodeService } from '@app/core/service/node.service';
import { PersonalDataTooltipComponent } from './components/personal-data-tooltip/personal-data-tooltip.component';
import { PhaseCardComponent } from './components/phase-card/phase-card.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { RoundedButtonComponent } from './components/rounded-button/rounded-button.component';
import { RouterModule } from '@angular/router';
import { RsRteComponent } from './components/rs-rte/rs-rte.component';
import { SelectEmailTemplateComponent } from './components/invite-users-dialog/select-email-template/select-email-template.component';
import { ShortToFullLanguagePipe } from '@app/core/pipes/short-to-full-language.pipe';
import { StepMediaAccordionComponent } from './components/step-media-accordion/step-media-accordion.component';
import { SupplierComponentsComponent } from '@app/module/dashboard/supplier/create-supplier/supplier-components/supplier-components.component';
import { SupplyChainViewListComponent } from './components/chains-batches/supply-chain-view-list/supply-chain-view-list.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { SwitchBrandComponent } from './components/switch-brand/switch-brand.component';
import { TextareaAutoResizeDirective } from './directives/textarea-auto-resize.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { UsefulDataComponent } from './components/useful-data/useful-data.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ViewCustomFieldsDetailsComponent } from './components/view-custom-fields-details/view-custom-fields-details.component';
import { ViewRichTextComponent } from './components/view-rich-text/view-rich-text.component';
import { ViewUsefullDataDialogComponent } from './components/useful-data/view-usefull-data-dialog/view-usefull-data-dialog.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    ImageEditPreviewComponent,
    FileViewComponent,
    CreateProductDialogComponent,
    CreateProductFormComponent,
    ChainNodeComponent,
    ChainNodeBorderDirective,
    IllustrationDesignComponent,
    RoundedButtonComponent,
    CustomIconComponent,
    ImageCardComponent,
    CreateComponentDialogComponent,
    ChainSetupHeaderComponent,
    SupplierComponentsComponent,
    AddSupplierComponent,
    QrCodeComponent,
    AddDocumentsAndMediasComponent,
    AddSupplierComponent,
    FilesListComponent,
    FileCardComponent,
    CertificateCardComponent,
    UsefulDataComponent,
    ViewUsefullDataDialogComponent,
    CertificatesTabComponent,
    DocumentsTabComponent,
    CertificateViewComponent,
    InputInfoComponent,
    InfoDialogComponent,
    ChainsBatchesComponent,
    ChainsListComponent,
    BatchesListComponent,
    CertificateViewDetailsComponent,
    PhaseCardComponent,
    StepMediaAccordionComponent,
    SupplyChainViewListComponent,
    EmailDialogComponent,
    AddMediasSupplierSiteComponent,
    InviteSupplierTmpComponent,
    InviteUsersDialogComponent,
    InviteUsersListComponent,
    SelectEmailTemplateComponent,
    InvitationsSendComponent,
    MiniCardComponent,
    TextareaAutoResizeDirective,
    SwitchBrandComponent,
    AlertBoxComponent,
    VideoPlayerComponent,
    CustomFieldsFormComponent,
    ViewCustomFieldsDetailsComponent,
    ActionListComponent,
    CreateSiteFormComponent,
    SupportDialogComponent,
    ShortToFullLanguagePipe,
    InfoInputDialogComponent,
    ProgressBarComponent,
    TooltipComponent,
    TooltipDirective,
    CustomSnackbarComponent,
    DeactivatedStepTooltipComponent,
    RsRteComponent,
    ViewRichTextComponent,
    DataSecuredBadgeComponent,
    PersonalDataTooltipComponent,
    CookiesBannerComponent,
    CookiesManagementComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    NgxDocViewerModule,
    FormsModule,
    RouterModule,
    DragDropModule,
    MatDialogModule,
    CKEditorModule,
  ],
  exports: [
    FileUploadComponent,
    TranslateModule,
    NgxDocViewerModule,
    FileViewComponent,
    CreateProductFormComponent,
    ChainNodeComponent,
    AngularMaterialModule,
    ChainNodeBorderDirective,
    IllustrationDesignComponent,
    RoundedButtonComponent,
    CustomIconComponent,
    ImageCardComponent,
    InputInfoComponent,
    CreateComponentDialogComponent,
    ChainSetupHeaderComponent,
    SupplierComponentsComponent,
    QrCodeComponent,
    FilesListComponent,
    FileCardComponent,
    CertificateCardComponent,
    UsefulDataComponent,
    CertificatesTabComponent,
    DocumentsTabComponent,
    CertificateViewComponent,
    InfoDialogComponent,
    ChainsBatchesComponent,
    ChainsListComponent,
    BatchesListComponent,
    CertificateViewDetailsComponent,
    PhaseCardComponent,
    StepMediaAccordionComponent,
    ReactiveFormsModule,
    AddMediasSupplierSiteComponent,
    MiniCardComponent,
    TextareaAutoResizeDirective,
    SwitchBrandComponent,
    CreateSiteFormComponent,
    CustomFieldsFormComponent,
    ViewCustomFieldsDetailsComponent,
    SupportDialogComponent,
    ShortToFullLanguagePipe,
    InfoInputDialogComponent,
    FormsModule,
    ProgressBarComponent,
    TooltipComponent,
    TooltipDirective,
    RsRteComponent,
    ViewRichTextComponent,
    DataSecuredBadgeComponent,
    PersonalDataTooltipComponent,
    CookiesBannerComponent,
    CookiesManagementComponent,
  ],
  providers: [NodeService, EmailFacade, MatSnackBar],
})
export class SharedModule {}
