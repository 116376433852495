<div class="holder">
  <div class="title-section">
    <p>{{ title }}</p>
    <app-custom-icon
      [icon_category]="'essential'"
      [icon_name]="'info-circle'"
      appTooltip
      [tooltipContent]="tooltipTemplate"
      [position]="TooltipPosition.BELOW"
    ></app-custom-icon>
  </div>
  <div class="bar-section progress-bar" #barSection>
    <mat-progress-bar mode="determinate" [value]="progressValue" class="bold-progress-bar"> </mat-progress-bar>
    <span class="progress-value" *ngIf="progressValue" [style.left.px]="getProgressPosition()"
      >{{ progressValue.toFixed() }}%</span
    >
  </div>
</div>

<ng-template #tooltipTemplate>
  <div class="steps-info" *ngIf="steps?.length; else noSteps">
    <p class="total-invited">
      <span>{{ stepCounts.totalInvited }}</span> Steps with invited suppliers
    </p>
    <p class="invited-info" *ngIf="stepCounts.totalInvited">
      <span>{{ stepCounts.updated }}</span> updated / <span>{{ stepCounts.toUpdate }}</span> to update
    </p>
    <div class="horizontal-line"></div>
    <p class="non-invited">
      <span>{{ stepCounts.notApplicable }}</span> Steps with non invited suppliers
    </p>
  </div>
  <ng-template #noSteps>
    <div class="no-steps">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
      <p>Steps progress data is not available as there are no steps added yet</p>
    </div>
  </ng-template>
</ng-template>
