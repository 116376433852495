import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Storage, USER } from '@app/core/constants';

import { Component } from '@angular/core';
import { CookiesManagementComponent } from '../cookies-management/cookies-management.component';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IUser } from '@app/core/interface/login.interface';
import { RegisterFacade } from '@app/core/facade/register.facade';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrl: './cookies-banner.component.scss',
  providers: [RegisterFacade],
})
export class CookiesBannerComponent {
  constructor(
    private _dialog: MatDialog,
    private _registerFacade: RegisterFacade,
    private _dataAuthService: DataAuthService,
    private _dataStorageService: DataStorageService,
    public dialogRef: MatDialogRef<CookiesBannerComponent>
  ) {}

  openCookiesManagement(): void {
    this._dialog.open(CookiesManagementComponent, {
      autoFocus: false,
      width: '600px',
    });
  }

  setCookies(acceptAll: boolean): void {
    this._registerFacade
      .manageProfile$({
        is_performance_cookies_approved: acceptAll,
        is_functional_cookies_approved: acceptAll,
      })
      .subscribe({
        next: this._success.bind(this),
      });
  }

  private _success(response: IUser) {
    this._dataStorageService.set(USER, JSON.stringify(response), Storage.local);
    this._dataAuthService.user = response;
    this.dialogRef.close();
  }
}
