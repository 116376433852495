import {
  IActivateDeactivateSupplierResponse,
  IDeleteSupplier,
  IDeleteSupplierContact,
  IFactory,
  IRecentSuppliersResponse,
  ISupplier,
  ISupplierContact,
  ISupplierContactsResponse,
  ISupplierEmailsResponse,
  ISupplierResponse,
  ISuppliersResponse,
} from './../interface/suppliers.interface';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { SuppliersService } from '../service/suppliers.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { IsDocMediaForPublicationResponse, IsLinkForPublicationResponse } from '../interface/products.interface';

@Injectable()
export class SuppliersFacade {
  constructor(private _suppliersService: SuppliersService) {}

  getSuppliers$(onlyActive?: boolean): Observable<ISuppliersResponse> {
    return this._suppliersService.getSuppliers$(onlyActive).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppliersResponse> => throwError(() => err.error))
    );
  }

  getRecentSuppliers$(): Observable<IRecentSuppliersResponse> {
    return this._suppliersService.getRecentSuppliers$().pipe(
      take(1),
      catchError((err): ObservableInput<IRecentSuppliersResponse> => throwError(() => err.error))
    );
  }

  createSupplier$(data: Partial<ISupplier>): Observable<ISupplier> {
    const formData = generateFormData(data);
    return this._suppliersService.createSupplier$(formData).pipe(
      take(1),
      catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
    );
  }

  getSupplierList$(ordering?: string, limit?: number): Observable<ISuppliersResponse> {
    return this._suppliersService.getSupplierList$(ordering, limit).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppliersResponse> => throwError(() => err.error))
    );
  }

  deleteSupplier$(uuid: string): Observable<IDeleteSupplier> {
    return this._suppliersService.deleteSupplier$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteSupplier): ObservableInput<IDeleteSupplier> => throwError(() => error))
    );
  }

  getSupplier$(uuid: string): Observable<ISupplierResponse> {
    return this._suppliersService.getSupplier$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISupplierResponse> => throwError(() => err.error))
    );
  }

  createSupplierContact$(uuid: string, data: Partial<ISupplierContact>): Observable<ISupplierContact> {
    const formData = generateFormData(data);
    return this._suppliersService.createSupplierContact$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: ISupplierContact): ObservableInput<ISupplierContact> => throwError(() => error))
    );
  }
  getSupplierContacts$(uuid: string): Observable<ISupplierContactsResponse> {
    return this._suppliersService.getSupplierContacts$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ISupplierContactsResponse): ObservableInput<ISupplierContactsResponse> => throwError(() => error)
      )
    );
  }

  updateSupplierContact$(
    supplier_uuid: string,
    uuid: string,
    data: Partial<ISupplierContact>
  ): Observable<ISupplierContact> {
    const formData = generateFormData(data);
    return this._suppliersService.updateSupplierContact$(supplier_uuid, uuid, formData).pipe(
      take(1),
      catchError(({ error }: ISupplierContact): ObservableInput<ISupplierContact> => throwError(() => error))
    );
  }

  deleteSupplierContact$(supplier_uuid: string, uuid: string): Observable<IDeleteSupplierContact> {
    return this._suppliersService.deleteSupplierContact$(supplier_uuid, uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeleteSupplierContact): ObservableInput<IDeleteSupplierContact> => throwError(() => error)
      )
    );
  }

  updateSupplier$(uuid: string, data: Partial<ISupplier>): Observable<ISupplier> {
    const formData = generateFormData(data);
    return this._suppliersService.updateSupplier$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<ISupplier> => throwError(() => err.error))
    );
  }

  activateDeactivateSupplier$(
    uuid: string,
    data: Partial<IActivateDeactivateSupplierResponse>
  ): Observable<IActivateDeactivateSupplierResponse> {
    const formData = generateFormData(data);
    return this._suppliersService.activateDeactivateSupplier$(uuid, formData).pipe(
      take(1),
      catchError(
        ({ error }: IActivateDeactivateSupplierResponse): ObservableInput<IActivateDeactivateSupplierResponse> =>
          throwError(() => error)
      )
    );
  }

  getSupplierManufactories$(uuid: string): Observable<IFactory[]> {
    return this._suppliersService.getSupplierManufactories$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IFactory[]> => throwError(() => err.error))
    );
  }

  updateSupplierDocuments$(
    productUuid: string,
    documentUuid: string,
    document: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._suppliersService.updateSupplierDocuments$(productUuid, documentUuid, document).pipe(
      take(1),
      catchError(
        ({ error }: IsDocMediaForPublicationResponse): ObservableInput<IsDocMediaForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  updateSupplierMedias$(
    productUuid: string,
    mediaUuid: string,
    media: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._suppliersService.updateSupplierMedias$(productUuid, mediaUuid, media).pipe(
      take(1),
      catchError(
        ({ error }: IsDocMediaForPublicationResponse): ObservableInput<IsDocMediaForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  updateSupplierLinks$(
    productUuid: string,
    linkUuid: string,
    link: FormData
  ): Observable<IsLinkForPublicationResponse> {
    return this._suppliersService.updateSupplierLinks$(productUuid, linkUuid, link).pipe(
      take(1),
      catchError(
        ({ error }: IsLinkForPublicationResponse): ObservableInput<IsLinkForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  getAllSupplierEmails$(): Observable<ISupplierEmailsResponse[]> {
    return this._suppliersService.getAllSupplierEmails$().pipe(
      take(1),
      catchError((err): ObservableInput<ISupplierEmailsResponse[]> => throwError(() => err.error))
    );
  }
}
