<mat-accordion>
  <mat-expansion-panel *ngIf="photos" expanded hideToggle (opened)="photosOpen = true" (closed)="photosOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p>{{ 'Photos Gallery' | translate }} ({{ photos?.length ?? 0 }})</p>
        <app-custom-icon
          *ngIf="!photosOpen"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-square-down'"
        ></app-custom-icon>
        <app-custom-icon
          *ngIf="photosTooltip"
          [icon_category]="'essential'"
          [icon_name]="'info-circle'"
          matTooltip="{{ photosTooltip }}"
          matTooltipClass="custom-tooltip-end"
          matTooltipPosition="above"
          class="info-icon"
        ></app-custom-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="img-wraper col-4" [ngClass]="{ 'hidden-photo': img['is_entity_private'] }" *ngFor="let img of photos">
        <div class="edit" *ngIf="!img['is_entity_private']">
          <p *ngIf="canDelete">{{ 'Actions' | translate }}</p>
          <p *ngIf="!canDelete">{{ 'Open photo' | translate }}</p>
          <app-custom-icon
            *ngIf="canDelete"
            [matMenuTriggerFor]="menu"
            [icon_category]="'settings'"
            [icon_name]="'more'"
          ></app-custom-icon>
          <app-custom-icon
            *ngIf="!canDelete"
            (click)="viewFile(img, 'photos')"
            [icon_category]="'security'"
            [icon_name]="'eye'"
          >
          </app-custom-icon>
          <mat-menu #menu="matMenu" class="action-menu mat-menu-primary">
            <button mat-menu-item (click)="viewFile(img, 'photos')">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
              <span>{{ 'Open photo' | translate }}</span>
            </button>
            <button mat-menu-item (click)="hideMedia(img, true)" *ngIf="type !== 'steps' && type !== 'batchsteps'">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'eye-slash'"></app-custom-icon>
              <span>{{ 'Hide photo' | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="openImageEditDialg($event, img)"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
              <span>{{ 'Edit photo' | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="removeItem(img, 'photos')"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'trash-red'"
                class="trash-red"
              ></app-custom-icon>
              <span class="color-red">{{ 'Remove Photo' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="hidden-info" *ngIf="img['is_entity_private']" (click)="hideMedia(img, false)">
          <p>Hidden</p>
          <app-custom-icon [icon_category]="'security'" [icon_name]="'hidden'"></app-custom-icon>
        </div>
        <img src="{{ img.file }}" />
      </div>
      <input class="d-none" #fileUpload type="file" (change)="onSelectFile($event)" />

      <div class="upload-box col-4" *ngIf="canUpload" (click)="isAmway ? addFile('photos') : fileUpload.click()">
        <span>{{ 'Upload photo' | translate }}</span>
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'upload'"></app-custom-icon>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="videos" hideToggle (opened)="videosOpen = true" (closed)="videosOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p>{{ 'All videos' | translate }} ({{ videos?.length ?? 0 }})</p>

        <app-custom-icon
          *ngIf="!videosOpen"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-square-down'"
        ></app-custom-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="img-wraper col-4" [ngClass]="{ 'delete-video': canDelete }" *ngFor="let video of videos">
        <div class="edit">
          <p *ngIf="canDelete">{{ 'Actions' | translate }}</p>
          <p *ngIf="!canDelete">{{ 'Open video' | translate }}</p>
          <app-custom-icon
            *ngIf="!canDelete"
            (click)="viewFile(video, 'videos')"
            [icon_category]="'security'"
            [icon_name]="'eye'"
          >
          </app-custom-icon>
          <app-custom-icon
            *ngIf="canDelete"
            [matMenuTriggerFor]="menu"
            [icon_category]="'settings'"
            [icon_name]="'more'"
          ></app-custom-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewFile(video, 'videos')">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
              <span>{{ 'Open Video' | translate }}</span>
            </button>

            <button
              mat-menu-item
              (click)="editDocument(video, 'videos')"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
              <span>{{ 'Edit Video' | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="removeItem(video, 'videos')"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'trash-red'"
                class="trash-red"
              ></app-custom-icon>
              <span class="color-red">{{ 'Remove Video' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div
          class="hidden-info"
          [ngClass]="{ hidden: video['is_entity_private'] }"
          *ngIf="type !== 'steps' && type !== 'batchsteps'"
          (click)="hideMedia(video, !video['is_entity_private'])"
        >
          <p>{{ video['is_entity_private'] ? 'Hidden' : 'Hide' }}</p>
          <app-custom-icon [icon_category]="'security'" [icon_name]="'hidden'"></app-custom-icon>
        </div>
        <video [src]="video.file" width="100" class="preview card-cover" [controls]="false" [autoplay]="false"></video>
      </div>
      <div class="upload-box col-4" *ngIf="canUpload" (click)="addFile('videos')">
        <span>{{ 'Upload video' | translate }}</span>
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'upload'"></app-custom-icon>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="documents" hideToggle (opened)="documentOpen = true" (closed)="documentOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p>{{ 'Documents' | translate }} ({{ documents?.length ?? 0 }})</p>
        <app-custom-icon
          *ngIf="!documentOpen"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-square-down'"
        ></app-custom-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="img-wraper document-height col-6 m-2" *ngFor="let document of documents">
        <div class="edit-document m-2">
          <p *ngIf="canDelete">{{ 'Actions' | translate }}</p>
          <p *ngIf="!canDelete">{{ 'Open document' | translate }}</p>
          <app-custom-icon
            *ngIf="!canDelete"
            (click)="viewFile(document, 'documents')"
            [icon_category]="'security'"
            [icon_name]="'eye'"
          >
          </app-custom-icon>
          <app-custom-icon
            *ngIf="canDelete"
            [matMenuTriggerFor]="menu"
            [icon_category]="'settings'"
            [icon_name]="'more'"
          ></app-custom-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewFile(document, 'documents')">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
              <span>{{ 'Open Document' | translate }}</span>
            </button>

            <button
              mat-menu-item
              (click)="editDocument(document, 'documents')"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
              <span>{{ 'Edit Document info' | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="removeItem(document, 'documents')"
              *ngIf="(type === 'steps' || type === 'batchsteps') && canDelete"
            >
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'trash-red'"
                class="trash-red"
              ></app-custom-icon>
              <span class="color-red">{{ 'Remove Document' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="custom-card">
          <div class="cursor-pointer app-file-card">
            <div class="product-details-card">
              <ngx-doc-viewer
                [url]="document.file"
                [disableContent]="'all'"
                [viewer]="document.mimetype.indexOf('pdf') > -1 ? 'url' : 'office'"
                *ngIf="document.mimetype.indexOf('application') > -1"
                class="preview card-cover p-0"
              >
              </ngx-doc-viewer>
              <div
                style="background-image: url('{{ document.file }}');"
                *ngIf="document.mimetype.indexOf('image') > -1"
                class="preview card-cover"
              ></div>

              <br />
            </div>
          </div>
        </div>
        <!-- TEMPORALLY HIDDEN BECAUSE OF THE DESIGN ISSUE. -->
        <!-- <app-file-card
          *ngIf="type !== 'publicationsteps' && type !== 'publication/suppliers'"
          [file]="document"
          (onFileSelected)="viewFile($event)"
          class="app-file-card"
        ></app-file-card> -->

        <div [ngClass]="{ 'delete-video': canDelete }">
          <div
            class="hidden-info"
            [ngClass]="{ hidden: document['is_entity_private'] }"
            *ngIf="type !== 'steps'"
            (click)="hideMedia(document, !document['is_entity_private'])"
          >
            <p>{{ document['is_entity_private'] ? 'Hidden' : 'Hide' }}</p>
            <app-custom-icon [icon_category]="'security'" [icon_name]="'hidden'"></app-custom-icon>
          </div>
          <ngx-doc-viewer
            *ngIf="type === 'publicationsteps' || type === 'publication/suppliers'"
            [url]="document.file"
            [disableContent]="'all'"
            [viewer]="'url'"
            class="step-document"
          >
          </ngx-doc-viewer>
        </div>
      </div>
      <div class="upload-box mt-2 ms-4 col-4 text-center" *ngIf="canUpload" (click)="addFile('documents')">
        <span>{{ 'Upload document' | translate }}</span>
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'upload'"></app-custom-icon>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    hideToggle
    (opened)="certificatesOpen = true"
    (closed)="certificatesOpen = false"
    *ngIf="certificates"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p>{{ 'Certificates' | translate }} ({{ certificates?.length ?? 0 }})</p>
        <app-custom-icon
          *ngIf="!certificatesOpen"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-square-down'"
        ></app-custom-icon>
        <app-custom-icon
          *ngIf="certificatesTooltip"
          [icon_category]="'essential'"
          [icon_name]="'info-circle'"
          matTooltip="{{ certificatesTooltip }}"
          matTooltipClass="custom-tooltip-end"
          matTooltipPosition="above"
          class="info-icon"
        ></app-custom-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="img-wraper col-4" *ngFor="let certificate of certificates">
        <ng-container *ngIf="certificate.document">
          <div class="edit-certificate m-2">
            <app-custom-icon
              (click)="openCertificate(certificate)"
              [icon_category]="'security'"
              [icon_name]="'eye'"
            ></app-custom-icon>
          </div>
          <div class="cursor-pointer app-file-card">
            <div class="product-details-card">
              <ngx-doc-viewer
                [url]="getCertificateUrl(certificate.document)"
                [disableContent]="'all'"
                [viewer]="getCertificateType(certificate.document) ? 'url' : 'office'"
                class="preview card-cover p-0"
              >
              </ngx-doc-viewer>
              <div style="background-image: url('{{ certificate.document }}')}" class="preview card-cover">
                {{ certificate.certificate.name }}
              </div>
              <br />
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="certificate.certificate.image">
          <img
            src="{{
              certificate.certificate.image ? certificate.certificate.image : '/src/assets/images/brand-avatar.png'
            }}"
          />
        </ng-container>
      </div>
      <div
        [ngClass]="hideUploadCertificates ? 'upload-box mt-2 ms-2 col-4 text-center' : 'd-none'"
        *ngIf="canUpload"
        (click)="addFile('certificates')"
      >
        <span>{{ 'Upload certificate' | translate }}</span>
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'upload'"></app-custom-icon>
      </div>
    </div>
  </mat-expansion-panel>
  <!-- <mat-expansion-panel
  hideToggle
  (opened)="certificatesOpen = true"
  (closed)="certificatesOpen = false"
  *ngIf="stepCertificates"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <p>{{ 'Certificates' | translate }} ({{ stepCertificates?.length ?? 0 }})</p>
      <app-custom-icon
        *ngIf="!certificatesOpen"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-square-down'"
      ></app-custom-icon>
      <app-custom-icon
        *ngIf="certificatesTooltip"
        [icon_category]="'essential'"
        [icon_name]="'info-circle'"
        matTooltip="{{ certificatesTooltip }}"
        matTooltipClass="custom-tooltip-end"
        matTooltipPosition="above"
        class="info-icon"
      ></app-custom-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="row">
    <div class="img-wraper col-4" *ngFor="let certificate of stepcertificates">
      <img
        src="{{
          certificate.certificate.image ? certificate.certificate.image : '/src/assets/images/brand-avatar.png'
        }}"
      />
    </div>
  </div>
</mat-expansion-panel> -->
</mat-accordion>
