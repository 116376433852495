<div (click)="$event.stopPropagation()">
  <div class="menu-header">
    <p>{{ 'Select supplier' | translate }}</p>
  </div>
  <div class="filter">
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label class="label-text">{{ 'Search for supplier' | translate }}</mat-label>

      <input (blur)="onBlur()" (input)="filterItem($event)" matInput placeholder="" #inputFilter />

      <app-custom-icon
        class="search-filter"
        [icon_category]="'search'"
        [icon_name]="'search-normal-2'"
      ></app-custom-icon>
    </mat-form-field>
  </div>
  <div class="supplier-list">
    <mat-accordion *ngFor="let supplier of filterAppled ? filteredSupplierList : supplierList; let i = index">
      <mat-expansion-panel hideToggle class="m-2" [expanded]="step === i">
        <mat-expansion-panel-header>
          <mat-checkbox
            [labelPosition]="'before'"
            class="checkbox-primary"
            checked="{{ step === i }}"
            (change)="setStep(i, supplier.uuid, $event.checked)"
          >
            <mat-panel-title>
              <p>{{ supplier.name }}</p>
            </mat-panel-title>
          </mat-checkbox>
        </mat-expansion-panel-header>
        <form [formGroup]="form">
          <mat-radio-group aria-label="Select an option" formControlName="manufactories">
            <mat-radio-button *ngFor="let factory of supplier.manufactories" value="{{ factory.uuid }}" color="primary">
              <p
                [ngClass]="{ 'main-location': factory.is_main_location }"
                matTooltip="{{ factory.is_main_location ? 'main site' : '' }}"
                matTooltipClass="custom-tooltip-center"
                [matTooltipPosition]="'above'"
              >
                <span>{{ factory.name }}</span> {{ factory.address ? factory.address + ',' : '' }}
                {{ factory.city ? factory.city + ',' : '' }} {{ factory.country }}
              </p>
            </mat-radio-button>
          </mat-radio-group>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="menu-buttons">
    <app-rounded-button
      color="primary"
      [button_text]="'Add Supplier'"
      [size]="'sm'"
      (click)="addSupplier()"
      [disabled]="form && !form.valid"
    >
    </app-rounded-button>
  </div>
</div>
