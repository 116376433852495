<div class="full-height d-flex flex-column">
  <h2 class="title">
    {{ (data.mode === 'create' ? 'Create Component' : 'Edit Component') | translate }}
  </h2>
  <mat-divider></mat-divider>
  <div class="holder">
    <app-supplier-components
      [supplierLogo]="supplierLogo"
      [supplierUuid]="data.supplierUUID"
      [supplierName]="'supplierName'"
      (close)="dialogRef.close($event)"
      [ingredient]="data.mode === 'create' ? 'add' : data.ingredient"
      [show_buttons]="true"
    >
    </app-supplier-components>
  </div>
</div>
