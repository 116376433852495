import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  IIngredient,
  IIngredientCategory,
  IIngredientCategoryResponse,
} from '@app/core/interface/ingredient.interface';
import { ISupplier, ISupplierIngredient } from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { Subject, takeUntil } from 'rxjs';

import { AddFactoryComponent } from '@app/module/supplier-management/add-factory/add-factory.component';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { IngredientService } from '@app/core/service/ingredient.service';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupplierComponentsGroup } from './supplier-components.group';
import { UntypedFormGroup } from '@angular/forms';

interface IFormatedIngredinet extends Omit<IIngredient, 'manufactory'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  manufactory?: any;
}

@Component({
  selector: 'app-supplier-components',
  templateUrl: './supplier-components.component.html',
  styleUrls: ['./supplier-components.component.scss'],
  providers: [SupplierComponentsGroup, ManufactoriesFacade, IngredientFacade],
})
export class SupplierComponentsComponent implements OnInit {
  @Input() supplierUuid!: string;
  @Input() supplierName!: string;
  @Input() supplierLogo!: string | null | undefined;
  @Input() supplierComponents: IFormatedIngredinet[] = [];
  @Input() ingredient: 'add' | IFormatedIngredinet | undefined;
  @Input() show_buttons!: boolean;
  @Input() addComponent!: boolean;
  @Input() site!: IManufactory;
  @Input() componentView!: boolean;
  @Input() ingredients!: ISupplierIngredient[];
  @Output() close = new EventEmitter<boolean>(false);
  @Output() isUpdate = new EventEmitter<boolean>(false);
  @Output() isFormValid = new EventEmitter<boolean>(false);
  @Output() componentingredient = new EventEmitter<IFormatedIngredinet | string | undefined>();
  @Output() componentingredients = new EventEmitter<ISupplierIngredient[]>();
  @Output() componentCreated = new EventEmitter<boolean>(false);
  @Output() componentDeleted = new EventEmitter<boolean>(false);
  categories!: IIngredientCategory[];
  form: UntypedFormGroup;
  manufactoryList: IManufactory[] = [];
  showAddComponentForm = false;
  isUpdateComponent = false;
  supplierComponentUuid? = '';
  uuid!: string;
  selectedSupplier?: ISupplier;
  showComponents = false;
  updateComponent = false;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _group: SupplierComponentsGroup,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _manufactoriesFacade: ManufactoriesFacade,
    private _facade: IngredientFacade,
    private ingredientService: IngredientService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this._group.addSupplierComponent;
    if (this.addComponent) {
      this.showAddComponentForm = true;
    }
    if (this.componentView) {
      this.showComponents = true;
    }
  }

  ngOnInit(): void {
    this._facade.getIngredientCategories$('True').subscribe({
      next: this._getCategoriesSuccess.bind(this),
      error: this._error.bind(this),
    });

    this.ingredientService.supplierComponentActions.pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
      switch (data) {
        case 'create': {
          this.createSupplierComponent();
          break;
        }
        case 'update': {
          this.updateSupplierComponent();
          break;
        }
        case '!showAddComponentForm': {
          this.showAddComponentForm = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
        case 'cancel': {
          this.close.emit(false);
          break;
        }
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.isFormValid.emit(this.form.valid);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['supplierUuid']) {
      this.form.get('supplier')?.setValue(this.supplierUuid);
      this.form.get('supplier')?.markAsDirty();
    }
    if (changes['componentView']) {
      this.updateComponent = true;
      this.setIngredients();
    }
    if (changes['addComponent'] && changes['addComponent'].currentValue) {
      this.showAddForm();
    }

    if (changes['ingredient']) {
      if (changes['ingredient'].currentValue === 'add') {
        this.showAddForm();
      }
      if (changes['ingredient'].currentValue && changes['ingredient'].currentValue !== 'add') {
        this.showUpdateForm(changes['ingredient'].currentValue);
      }

      this.componentingredient.emit(this.ingredient);
    }
  }

  setIngredients(): void {
    if (this.supplierComponents) {
      this.supplierComponents = this.supplierComponents.filter(ing => ing.manufactory?.uuid == this.site.uuid);
    }
  }

  getManufactories() {
    if (this.supplierUuid) {
      this._manufactoriesFacade.getSupplierSites$(this.supplierUuid).subscribe((data: IManufactory[]) => {
        this.manufactoryList = data;
      });
    }
  }

  createSupplierComponent(): void {
    const changedFromValues = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._facade.createIngredient$(changedFromValues).subscribe({
        next: this._createSupplierComponentSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  deleteSupplierComponent(component: ISupplierIngredient): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete Supplier component: ${component.name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && component.uuid) {
        this._facade.deleteIngredient$(component.uuid).subscribe(() => {
          this.supplierComponents = this.supplierComponents.filter(
            supplierComponent => supplierComponent.uuid !== component.uuid
          );
          this.componentDeleted.emit(true);
        });
      }
    });
  }

  updateSupplierComponent(): void {
    const changedFromValues = GetDirtyValues(this.form);

    if (this.form.valid && this.supplierComponentUuid) {
      this._facade.updateIngredient$(this.supplierComponentUuid, changedFromValues).subscribe({
        next: this._updateSupplierComponentSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  showAddForm(): void {
    this.getManufactories();
    this.form.reset();
    this.form.get('supplier')?.setValue(this.supplierUuid);
    this.form.get('supplier')?.markAsDirty();
    if (this.addComponent) {
      this.form.get('manufactory')?.setValue(this.site.uuid);
      this.form.get('manufactory')?.markAsDirty();
    }
    this.showAddComponentForm = true;
    this.isUpdateComponent = false;
    this.isUpdate.emit(false);
  }

  showUpdateForm(component: IFormatedIngredinet): void {
    this.updateComponent = true;
    this.componentView = false;
    if (!this.manufactoryList.length) {
      this.getManufactories();
    }
    this.showAddComponentForm = true;
    this.supplierComponentUuid = component.uuid;
    this.form.patchValue({
      name: component.name,
      reference: component.reference,
      supplier: this.supplierUuid,
      manufactory: component.manufactory?.uuid,
      description: component.description ?? '',
      category: component.category?.uuid,
      quantity: component.quantity,
      sourcing_location: component.sourcing_location,
    });
    this.form.markAsPristine();
    this.isUpdateComponent = true;
    this.isUpdate.emit(true);
  }

  discard() {
    if (this.ingredient) {
      this.close.emit(false);
    } else {
      this.showAddComponentForm = false;
    }
  }

  private _createSupplierComponentSuccess(data: IIngredient): void {
    this._snackbarService.openTypeSnackbar(`Component ${data.name} Added.`, NotificationType.success);

    this.supplierComponents.push(this._formatIngredientData(data));
    this.componentingredients.emit(this.supplierComponents);
    this.showAddComponentForm = false;
    this.componentCreated.emit(true);
    this.form.reset();
    this.form.get('supplier')?.setValue(this.supplierUuid);
    this.form.get('supplier')?.markAsDirty();
    if (this.ingredient) this.close.emit(true);
  }

  private _updateSupplierComponentSuccess(data: IIngredient): void {
    this._snackbarService.openTypeSnackbar(`Component ${data.name} Updated`, NotificationType.success);

    this.supplierComponents = this.supplierComponents.filter(supplierComponent => supplierComponent.uuid !== data.uuid);
    this.supplierComponents.push(this._formatIngredientData(data));
    this.showAddComponentForm = false;
    this.isUpdateComponent = false;
    this.updateComponent = true;
    this.componentView = true;
    this.isUpdate.emit(false);
    if (this.ingredient) this.close.emit(true);
  }

  private _formatIngredientData(data: IIngredient): IFormatedIngredinet {
    return {
      ...data,
      manufactory: this.manufactoryList.find(site => site.uuid === data.manufactory),
    };
  }

  private _getCategoriesSuccess(response: IIngredientCategoryResponse) {
    if (response && response.results.length) {
      this.categories = response.results;
    }
  }

  private _error(error: IIngredient): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  openCreateSupplierSite(): void {
    const dialogRef = this._dialog.open(AddFactoryComponent, {
      data: {
        supplier: this.supplierUuid,
        logo: this.supplierLogo,
      },
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getManufactories();
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  cancelComponentView() {
    if (this.addComponent) {
      this.close.emit(true);
    }
    this.showAddComponentForm = this.addComponent = this.componentView = this.showComponents = false;
    this.componentView = true;
    this.isUpdate.emit(false);
  }
}
