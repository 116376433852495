import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInfoDialogData } from '@app/core/interface/unit.interface';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  type!: string;
  text!: string;
  usedUnit!: boolean;
  product_chain_uuid!: string | null;
  reactivate!: boolean;
  is_batch!: boolean;
  disapproved!: boolean;
  title!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IInfoDialogData,
    public dialogRef: MatDialogRef<InfoDialogComponent>
  ) {}

  ngOnInit(): void {
    this.setData();
  }

  setData(): void {
    this.type = this.dialogData.type;
    this.text = this.dialogData.text;
    this.usedUnit = this.dialogData.usedUnit;
    this.reactivate = this.dialogData.reactivate;
    this.is_batch = this.dialogData.is_batch;
    this.title = this.dialogData.title;
    this.product_chain_uuid = this.dialogData.product_chain_uuid;
    this.disapproved = this.dialogData.disapproved;
  }

  deactivateUnit(): void {
    if (!this.usedUnit || this.is_batch) {
      this.dialogRef.close(true);
    }
  }

  reactivateUnit(): void {
    if (this.reactivate) {
      this.dialogRef.close(true);
    }
  }

  disapproveInfoBatch() {
    if (this.disapproved) {
      this.dialogRef.close(true);
    }
  }
}
