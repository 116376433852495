import {
  IPageTranslationResponse,
  IPageTranslationResultsResponse,
  ITranslationResponse,
} from '../interface/translation.interface';

import { DataAuthService } from '@app/core/service/data-auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { environment } from '@env/environment';
import { SELECTED_LANGUAGE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  apiUrl = environment.baseApiUrl;
  suffix = 'translation/brand';
  public companyInfotranslation: BehaviorSubject<IPageTranslationResultsResponse> =
    new BehaviorSubject<IPageTranslationResultsResponse>({ name: '', uuid: '', key: [] });
  public language: Subject<string> = new Subject();

  constructor(private _http: HttpClient, private _dataAuthService: DataAuthService) {
    let user = this._dataAuthService.user;
    user = typeof user === 'string' ? JSON.parse(user) : user;
    const isCsmUser = user?.user_permissions.includes('customer_success_manager') as boolean;
    this.suffix = isCsmUser ? 'csm/translation' : 'translation/brand';
  }

  getUkTranslation$(page: string): Observable<ITranslationResponse> {
    return this._http.get<ITranslationResponse>(`${this.apiUrl}/${this.suffix}/${page}/?lang=en-uk`);
  }

  getTranslation$(langugage: string, page: string): Observable<IPageTranslationResultsResponse> {
    return this._http.get<IPageTranslationResultsResponse>(`${this.apiUrl}${langugage}/api/v3/${this.suffix}/${page}`);
  }

  getDeTranslation$(page: string): Observable<ITranslationResponse> {
    return this._http.get<ITranslationResponse>(`${this.apiUrl}/${this.suffix}/${page}/?lang=de`);
  }

  getFrTranslation$(page: string): Observable<ITranslationResponse> {
    return this._http.get<ITranslationResponse>(`${this.apiUrl}/${this.suffix}/${page}/?lang=fr`);
  }

  putTranslation$(data: FormData, select_language: string): Observable<ITranslationResponse> {
    return this._http.put<ITranslationResponse>(
      `${this.apiUrl}${select_language}/api/v3/${this.suffix}/${data.get('uuid')}`,
      data
    );
  }

  getPageTranslation$(language?: string): Observable<IPageTranslationResponse> {
    return this._http.get<IPageTranslationResponse>(`${this.apiUrl}${language}/api/v3/${this.suffix}/`);
  }

  getTranslation(lang: string): Observable<Record<string, string>> {
    return this._http.get<Record<string, string>>(`/assets/i18n/${lang}.json`);
  }
  /**
   *
   * @param lang currentLang
   * @returns Observable from object of current translation in JSON file
   */

  getAllKeysAndValues(lang: string): Observable<Record<string, string>> {
    return this.getTranslation(lang).pipe(
      map((translations: Record<string, string>) => {
        const result: Record<string, string> = {};
        for (const key in translations) {
          if (Object.prototype.hasOwnProperty.call(translations, key)) {
            result[key] = translations[key];
          }
        }
        return result;
      })
    );
  }
  /**
   *
   * @param data response data for mapping
   * @returns mapped translation from response
   */
  extractTranslationFromResoponse(data: IPageTranslationResultsResponse[]): Record<string, string> {
    const JSONdata: Record<string, string> = {};
    data.forEach(row => {
      JSONdata[row.name] = row[row.name] as string;
    });
    return JSONdata;
  }
  /**
   *
   * @param target keys and values from JSON response
   * @param source keys and values from mapped response
   * @returns merged translation with the newe values from response if present in the response,
   *  if not the old from the JSON file are teken
   */

  getDeepMergeTranslation(
    target: Record<string, string | Record<string, string>>,
    source: Record<string, string | Record<string, string>>
  ): Record<string, string | Record<string, string>> {
    Object.keys(source).forEach(key => {
      const sourceValue = source[key];
      const targetValue = target[key];

      if (typeof sourceValue === 'object' && sourceValue !== null && !(sourceValue instanceof Array)) {
        // When both values are objects, merge them
        if (typeof targetValue === 'object' && targetValue !== null && !(targetValue instanceof Array)) {
          target[key] = this.getDeepMergeTranslation(
            targetValue as Record<string, string>,
            sourceValue as Record<string, string>
          ) as Record<string, string>;
        } else {
          // Replace the target value with the source value if target is not an object
          target[key] = { ...sourceValue };
        }
      } else {
        // Replace target value with source value if source is not an object
        target[key] = sourceValue;
      }
    });

    return target;
  }

  setSelectedLanguage(lang: string): void {
    localStorage.setItem(SELECTED_LANGUAGE, lang);
    this.language.next(lang);
  }
}
