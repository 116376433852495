import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionList, ActionListOperations } from '@interface/action-list.interface';
import { IProductChainResult } from '@interface/productchain.interface';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
})
export class ActionListComponent {
  @Input() actionLists!: ActionList[];
  @Input() element!: IProductChainResult;
  protected readonly ActionListOperations = ActionListOperations;
  @Output() operationClicked = new EventEmitter<ActionListOperations>();
  click(operation: ActionListOperations) {
    this.operationClicked.emit(operation);
  }
}
