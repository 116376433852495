import { BATCH_STEP_DISAPPROVAL, NotificationType } from '@app/core/constants';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IEmailTemp, IEmailTemplatesResponse, IEmailVarValues } from '@app/core/interface/email.interface';
import { ISupplierContact, ISupplierResponse } from '@app/core/interface/suppliers.interface';

import { EmailDialogComponent } from '../../email-dialog/email-dialog.component';
import { EmailFacade } from '@app/core/facade/email.facade';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ModifyTemplateDialogComponent } from '@app/module/settings/email-templates/modify-template-dialog/modify-template-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-select-email-template',
  templateUrl: './select-email-template.component.html',
  styleUrls: ['./select-email-template.component.scss'],
  providers: [EmailFacade],
})
export class SelectEmailTemplateComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['select', 'name', 'type', 'language', 'actions'];

  uuid = '';
  tableDataSource = new MatTableDataSource<IEmailTemp>();
  emailSelection = new SelectionModel<IEmailTemp>(false, []);
  allTemlates: Array<IEmailTemp> = [];
  emailTemlates: Array<IEmailTemp> = [];
  @Input() variables: IEmailVarValues = {
    'Brand name': null,
    'Brand contact name': null,
    'Supplier name': null,
    'Supplier contact name': null,
    'Product name': null,
    'Project name': null,
    'Current date': null,
  };
  @Input() supplier!: ISupplierResponse;
  @Input() selectedContact?: ISupplierContact;
  @Output() templateSelected = new EventEmitter<IEmailTemp | undefined>();
  @Output() removeContact = new EventEmitter<Partial<IProduct>>();
  @Output() goBack = new EventEmitter<boolean>();

  constructor(
    private _facade: EmailFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getEmailTemplates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedContact'] && changes['selectedContact'].currentValue) {
      const status = changes['selectedContact'].currentValue.is_account_set ? 'next inv' : 'first';
      this.emailTemlates = this.allTemlates.filter(
        temp =>
          temp.email_template_type?.type.includes(status) &&
          !temp.email_template_type?.type.includes(BATCH_STEP_DISAPPROVAL)
      );
      this.tableDataSource.data = this.emailTemlates.sort((templateA, templateB) => {
        const emailComparison = templateA.email_template_type.language.localeCompare(
          templateB.email_template_type.language
        );
        if (emailComparison !== 0) {
          return emailComparison;
        }
        return templateA.name.localeCompare(templateB.name);
      });
    }
  }

  selectTemplate(row: IEmailTemp): void {
    this.emailSelection.clear();
    this.emailSelection.toggle(row);
    this.templateSelected.emit(row);
  }

  openViewEmailDialog(tmp: IEmailTemp): void {
    this._dialog.open(EmailDialogComponent, {
      width: '900px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: ['email-view-dialog-container', 'padding-0'],
      data: {
        template: {
          ...tmp,
          variables: {
            ...this.variables,
            'Supplier contact name': this.selectedContact?.first_name + ' ' + this.selectedContact?.last_name,
          },
        },
        supplier: this.supplier,
      },
    });
  }
  openEditDialog(tmp: IEmailTemp) {
    const dialogRef = this._dialog.open(ModifyTemplateDialogComponent, {
      width: '900px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: ['email-edit-dialog-container', 'overflow-hidden-dialog'],
      data: {
        template: {
          ...tmp,
          variables: {
            ...this.variables,
            'Supplier contact name': this.selectedContact?.first_name + ' ' + this.selectedContact?.last_name,
          },
        },
      },
    });

    dialogRef.afterClosed().subscribe((result?: IEmailTemp) => {
      if (result) {
        // this.emailSelection.clear();
        this.tableDataSource.data.forEach((template: IEmailTemp) => {
          if (template.name === result?.name) template.content = result.content;
        });
      }
    });
  }
  getEmailTemplates(): void {
    this._facade.getEmailTemlates$().subscribe({
      next: this._successTemplate.bind(this),
      error: this._error.bind(this),
    });
  }
  removeItem(event: Partial<IProduct>): void {
    this.removeContact.emit(event);
  }

  getItem(item?: ISupplierContact): Partial<ISupplierContact> | undefined {
    if (!item) return undefined;
    return { ...item, name: item.first_name + ' ' + item.last_name };
  }

  private _successTemplate(response: IEmailTemplatesResponse): void {
    this.emailTemlates = [...this.allTemlates] = response.results;
    this.tableDataSource.data = this.emailTemlates;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
