import { Component, Input, OnInit } from '@angular/core';
import { ICustomFieldOption, IUserCustomField } from '@app/core/interface/add-fields.interface';

interface IMapField {
  field_name: string;
  field_value: string;
  all_options: Array<{
    option: string;
    is_active: boolean;
  }>;
  is_active: boolean;
}
@Component({
  selector: 'app-view-custom-fields-details',
  templateUrl: './view-custom-fields-details.component.html',
  styleUrls: ['./view-custom-fields-details.component.scss'],
})
export class ViewCustomFieldsDetailsComponent implements OnInit {
  @Input() customFields: Array<IUserCustomField> = [];
  textFields: Array<IUserCustomField> = [];
  fieldsList: Array<IMapField> = [];
  isActiveFields = true;

  ngOnInit(): void {
    // filter and sort
    const filteredFields = this.customFields
      // filter only fields that are activated + deactivated with entered values
      .filter(field => field.is_active || (!field.is_active && (field.value || field.field_options.length)))
      // sort first activated and then deactivated
      .sort(a => {
        return a.is_active ? -1 : 1;
      });

    this.textFields = filteredFields.filter(x => {
      return !x.all_options.length;
    });

    this.fieldsList = filteredFields.map((field: IUserCustomField) => {
      return {
        field_name: field.field_name,
        field_value: field.value,
        all_options: field.all_options.map((option: ICustomFieldOption) => {
          return {
            option: option.option,
            is_active: field.field_options.some(y => y.uuid === option.uuid),
          };
        }),
        is_active: field.is_active,
      };
    });

    // Check if custom fields created and active present
    this.isActiveFields =
      this.fieldsList.length > 0 &&
      this.fieldsList.some(
        field => field.is_active || (!field.is_active && (field.field_value || field.all_options.length))
      );
  }
}
