import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  IActivateDeactivateProductResponse,
  IAddComponentProduct,
  IDeleteCertification,
  IDeleteProduct,
  IProduct,
  IProductCertificatesRespone,
  IProductChainRequest,
  IProductChainUpdateResponse,
  IProductComponentsResponse,
  IProductGroupResponse,
  IProductResponse,
  IProductsListResponse,
  IsDocMediaForPublicationResponse,
  IsLinkForPublicationResponse,
} from '../interface/products.interface';
import { ICreateProductForm, ICreateProductResponse } from './../interface/register.interface';
import { IDashboardBrandInfo, IDashboardDefaultInfo } from '../interface/dashboard.interface';

import { Breadcrumb } from '../interface/header.interface';
import { ISupplier } from '../interface/suppliers.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import { IPublicationProductChain, SetupPublicationProductRequest } from '../interface/publication.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private apiUrl = environment.api;
  public refetchProductDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refetchProductDetails$ = this.refetchProductDetails.asObservable();
  public productDetailsName: Subject<Breadcrumb> = new Subject();
  changesBehaviour = new BehaviorSubject(false);

  constructor(private _http: HttpClient) {}

  createProduct$(data: Partial<ICreateProductForm>): Observable<ICreateProductResponse> {
    return this._http.post<ICreateProductResponse>(`${this.apiUrl}/products/`, data);
  }

  getProducts$(ordering?: string): Observable<IProductsListResponse> {
    if (ordering) {
      const params = new HttpParams().set('ordering', ordering);
      return this._http.get<IProductsListResponse>(`${this.apiUrl}/products/`, { params: params });
    }
    return this._http.get<IProductsListResponse>(`${this.apiUrl}/products/`);
  }

  getActiveProducts$(): Observable<IProductsListResponse> {
    return this._http.get<IProductsListResponse>(`${this.apiUrl}/products?is_active=True`);
  }

  deleteProduct$(uuid: string): Observable<IDeleteProduct> {
    return this._http.delete<IDeleteProduct>(`${this.apiUrl}/products/${uuid}/`);
  }

  cloneProduct$(uuid: string | null): Observable<ICreateProductResponse> {
    return this._http.post<ICreateProductResponse>(`${this.apiUrl}/products/${uuid}/clone/`, {});
  }

  updateProduct$(data: Partial<ICreateProductForm>, uuid: string): Observable<ICreateProductResponse> {
    return this._http.patch<ICreateProductResponse>(`${this.apiUrl}/products/${uuid}/`, data);
  }

  getProduct$(uuid: string | null): Observable<IProductResponse> {
    return this._http.get<IProductResponse>(`${this.apiUrl}/products/${uuid}/`);
  }

  getProductForPublication$(uuid: string | null): Observable<IPublicationProductChain> {
    return this._http.get<IPublicationProductChain>(`${this.apiUrl}/publication/productchain/${uuid}/`);
  }

  getProductCertificates$(uuid: string | null): Observable<IProductCertificatesRespone> {
    return this._http.get<IProductCertificatesRespone>(`${this.apiUrl}/products/${uuid}/certificates/`);
  }

  deleteCertification$(product_uuid: string, uuid: string): Observable<IDeleteCertification> {
    return this._http.delete<IDeleteCertification>(`${this.apiUrl}/products/${product_uuid}/certificates/${uuid}`);
  }

  removeSupplier$(productUuid: string, supplierUuid: string): Observable<IDeleteProduct> {
    return this._http.delete<IDeleteProduct>(`${this.apiUrl}/products/${productUuid}/suppliers/${supplierUuid}/`);
  }

  addSupplierToProduct$(supplierUuid: FormData, productUuid: string): Observable<ISupplier> {
    return this._http.post<ISupplier>(`${this.apiUrl}/products/${productUuid}/suppliers/`, supplierUuid);
  }

  updateProductChain$(data: IProductChainRequest, uuid: string): Observable<IProductChainUpdateResponse> {
    return this._http.put<IProductChainUpdateResponse>(`${this.apiUrl}/productchain/${uuid}/`, data);
  }

  updateProductsOrder$(data: string[]): Observable<IProductsListResponse> {
    return this._http.post<IProductsListResponse>(`${this.apiUrl}/products/position/`, { product: data });
  }

  addComponentToProduct$(productUuid: string, componentUuid: FormData): Observable<IAddComponentProduct> {
    return this._http.post<IAddComponentProduct>(`${this.apiUrl}/products/${productUuid}/ingredients/`, componentUuid);
  }

  getProductComponents$(product_uuid: string, ordering?: string): Observable<IProductComponentsResponse> {
    if (ordering) {
      const params = new HttpParams().set('ordering', ordering);
      return this._http.get<IProductComponentsResponse>(`${this.apiUrl}/products/${product_uuid}/ingredients/`, {
        params: params,
      });
    }
    return this._http.get<IProductComponentsResponse>(`${this.apiUrl}/products/${product_uuid}/ingredients/`);
  }

  getDashboardDefault$(): Observable<IDashboardDefaultInfo> {
    return this._http.get<IDashboardDefaultInfo>(`${this.apiUrl}/dashboard/default/`);
  }

  getProductsDashboard$(): Observable<IDashboardBrandInfo> {
    return this._http.get<IDashboardBrandInfo>(`${this.apiUrl}/dashboard/brand/`);
  }

  activateDeactivateProduct$(
    uuid: string,
    data: Partial<IActivateDeactivateProductResponse>
  ): Observable<IActivateDeactivateProductResponse> {
    return this._http.patch<IActivateDeactivateProductResponse>(`${this.apiUrl}/products/${uuid}/active/`, data);
  }
  getPublicProducts$(): Observable<IProduct[]> {
    return this._http.get<IProduct[]>(`${this.apiUrl}/products/publicproducts`);
  }

  getProductGroups$(): Observable<IProductGroupResponse> {
    return this._http.get<IProductGroupResponse>(`${this.apiUrl}/products/groups`);
  }

  updateProductDocuments$(
    productUuid: string,
    documentUuid: string,
    document: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._http.patch<IsDocMediaForPublicationResponse>(
      `${this.apiUrl}/products/${productUuid}/documents/${documentUuid}/`,
      document
    );
  }

  updateProductMedias$(
    productUuid: string,
    mediaUuid: string,
    media: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._http.patch<IsDocMediaForPublicationResponse>(
      `${this.apiUrl}/products/${productUuid}/medias/${mediaUuid}/`,
      media
    );
  }

  updateProductLinks$(productUuid: string, linkUuid: string, link: FormData): Observable<IsLinkForPublicationResponse> {
    return this._http.patch<IsLinkForPublicationResponse>(
      `${this.apiUrl}/products/${productUuid}/links/${linkUuid}/`,
      link
    );
  }

  savePublicationSetup(uuid: string, data: SetupPublicationProductRequest): Observable<IProduct | IProductResponse> {
    return this._http.patch<IProduct | IProductResponse>(`${this.apiUrl}/products/${uuid}/savesetup/`, data);
  }
}
