import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateToService implements OnDestroy {
  private subscription: Subscription | undefined;
  constructor(private translate: TranslateService) {}

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  translateTo(text: string, lang: string): Promise<string> {
    return new Promise<string>(resolve => {
      /**
       * Temporarily set the target language
       */
      const currentLang = this.translate.currentLang ?? this.translate.defaultLang;
      this.translate.use(lang);
      /**
       * Translate the text
       */
      this.subscription = this.translate.get(text).subscribe((translation: string) => {
        /**
         * Reset the language back to the original value (leave in queueMicrotask in order to work correctly)
         */
        queueMicrotask(() => {
          this.translate.use(currentLang);
        });
        /**
         * Resolve with the translated text
         */
        resolve(translation);
      });
      this.translate.use(currentLang);
    });
  }
}
