import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IProduct } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-create-product-form',
  templateUrl: './create-product-form.component.html',
  styleUrls: ['./create-product-form.component.scss'],
})
export class CreateProductFormComponent {
  @Input() mode: 'duplicate' | 'edit' | undefined;
  @Input() form!: UntypedFormGroup;

  get imageUrl(): string {
    return this.form.controls['image'].value;
  }

  /**
   * On fileData trigered we are putting the file in the image formcontrol
   * @param file image that needs to be put in form
   */
  saveImageData(file: File): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = evt => {
      const base64 = evt.target?.result;
      this.form.get('image')?.setValue(base64);
    };
    this.form.get('image')?.markAsDirty();
  }

  setFormData(data: IProduct) {
    this.form.patchValue({
      name: this.mode === 'duplicate' ? `${data.name} COPY` : data.name,
      description: data.description,
      summary: data.summary,
      reference: data.reference,
      image: data.image,
    });
    this.mode === 'duplicate' && this.form.get('name')?.markAsDirty();
  }
}
