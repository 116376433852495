<div class="files-wrapper">
  <div class="close-icon" *ngIf="!is_supplier">
    <app-custom-icon
      class="custom-icon cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      (click)="close()"
    >
    </app-custom-icon>
  </div>
  <mat-tab-group
    #tabs
    [ngClass]="{ 'class-hide-tab1': !listView, 'is-supplier': is_supplier }"
    [(selectedIndex)]="selectedIndex"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="list-title-holder" (click)="hideSingleTab()">
          <app-file-upload
            class="img-circle white-txt"
            [placeholder]="dialogData.image ? dialogData.image : ('Logo' | translate)"
            [imageUrl]="dialogData.image"
            [readonly]="true"
          ></app-file-upload>

          <h6 class="white-txt">
            {{ dialogData.name }}
          </h6>
          <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'" class="white-txt"></app-custom-icon>
          <h5 class="white-txt">
            {{ 'All' | translate }} {{ dialogData.listName === 'images' ? 'photos' : dialogData.listName }}
          </h5>
        </div>
      </ng-template>
      <!-- <div class="divider"></div> -->
      <app-files-list
        [dialogData]="dialogData"
        (onFileSelected)="viewFile($event)"
        (refresh)="refreshData()"
      ></app-files-list>
    </mat-tab>
    <div *ngIf="!listView">
      <mat-tab>
        <ng-template mat-tab-label="single-file">
          <div class="single-file">
            <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
            <h5 class="white-txt">{{ selectedFileName }}</h5>
          </div>
        </ng-template>
        <!-- <div class="divider"></div> -->
        <app-file-view
          [listData]="{ list: dialogData.data, file: selectedFile }"
          [selectedFile]="selectedFile"
          (selectedFileName)="getSelectedFileName($event)"
          [is_supplier]="is_supplier"
          [hide_header]="true"
          class="file-view-app white-txt"
        ></app-file-view>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>
