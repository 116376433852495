import { Component, Input } from '@angular/core';

import { ICertificationProduct } from '@app/core/interface/products.interface';
import { ICertificationSupplier } from '@app/core/interface/suppliers.interface';
import { IStepCertification } from '@app/core/interface/steps.interface';

@Component({
  selector: 'app-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.scss'],
})
export class CertificateCardComponent {
  @Input() certificate?: ICertificationProduct | ICertificationSupplier | IStepCertification;
  @Input() supplier?: string;
  @Input() manufactory?: string;
}
