import { Component, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OFFICE_FILENAME_EXTENSIONS } from '@app/core/constants';
import { ICertificationSupplier, IViewFile } from '@app/core/interface/suppliers.interface';
import { EventEmitter } from '@angular/core';
import { IMedia } from '@app/core/interface/medias.interface';

@Component({
  selector: 'app-certificate-view',
  templateUrl: './certificate-view.component.html',
  styleUrls: ['./certificate-view.component.scss'],
})
export class CertificateViewComponent implements OnInit, OnChanges {
  @Input() listData!: { list: IMedia[]; file: IMedia };
  @Input() selectedFile!: IMedia;
  @Output() selectedFileName = new EventEmitter<string>();
  docURL = '';
  name = '';
  fileName = '';
  created_at? = '';
  mimetype = '';
  show_play_icon = true;
  puseMode = true;
  viewer: 'url' | 'office' = 'url';
  fileList: IMedia[] = [];
  certificate!: ICertificationSupplier;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: IViewFile) {
    if (!dialogData.list && !(dialogData instanceof Array)) {
      this.docURL = dialogData.url;
      this.name = this.docURL;
      this.certificate = dialogData.certificate;
      this.selectedFile = {
        file: this.docURL,
        name: this.dialogData.name,
        uuid: '',
        mimetype: this.dialogData.mimetype,
        created_at: this.dialogData.created_at,
        url: this.dialogData?.url,
      };
      this.setViewer(this.docURL);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listData']) {
      this.fileList = changes['listData'].currentValue.list;
      this.setViewer(this.docURL);
    }
    if (changes['selectedFile']) {
      this.docURL = changes['selectedFile'].currentValue.file;
      this.setViewer(this.docURL);
    }
  }
  ngOnInit(): void {
    if (this.listData) {
      this.docURL = this.listData.file.file;
      this.fileList = this.listData.list;
      this.fileName = this.selectedFile.name;
      this.selectedFileName.emit(this.selectedFile.name);
      this.created_at = this.selectedFile.created_at;
      this.mimetype = this.selectedFile.mimetype;
      this.setViewer(this.docURL);
    }
  }
  /**
   *
   * @param filename depending on filename extendion we are setting the doc viewer
   */
  setViewer(filename: string) {
    if (!filename) return;
    const extIndex = filename.split('.').length - 1;
    const ext = filename.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      this.viewer = 'office';
    } else {
      this.viewer = 'url';
    }
  }
  onFileSelect(file: IMedia) {
    this.selectedFile = {
      file: file.file,
      name: file.name,
      uuid: '',
      mimetype: file.mimetype,
      url: file?.url,
    };

    this.docURL = file.file;
    this.fileName = file.name;
    this.created_at = file.created_at;
    this.show_play_icon = true;
    this.selectedFileName.emit(this.selectedFile.name);
    this.setViewer(this.docURL);
  }
}
