import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CustomSnackbarData, IconMapping } from '@app/core/interface/unit.interface';
import { iconMapping } from '@app/core/constants';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent implements OnInit {
  type = '';
  text = '';
  iconName = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarData) {}

  ngOnInit(): void {
    this.setData();
  }

  setData(): void {
    this.type = this.data.type || '';
    this.text = this.data.text || '';

    const icon = iconMapping[this.type as keyof IconMapping] || { name: '' };
    this.iconName = icon.name;
  }
}
