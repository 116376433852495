<div class="dialog-wrapper">
  <div class="breadcrump">
    <app-custom-icon
      mat-dialog-close
      [icon_category]="'arrows'"
      [icon_name]="'arrow-circle-left-short'"
    ></app-custom-icon>
    <h4 mat-dialog-close>{{ certificate.supplier_name ? certificate.supplier_name : 'Back' }}</h4>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
    <h3>Certificate Details</h3>
    <div class="close">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
    </div>
  </div>

  <div class="h-line">
    <div class="horizontal-line mb-2"></div>
  </div>

  <div class="header-holder">
    <div class="title-holder">
      <h2>
        {{ certificate.certificate.name ? certificate.certificate.name : ('View File' | translate) }}
      </h2>
      <div class="icon-holder">
        <div class="calendar">
          <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar'"> </app-custom-icon>
        </div>
        <p class="calendar-text">
          {{ 'Starts:' | translate }}
          {{ (certificate.validity_start ? certificate.validity_start : '') | date: 'dd.MM.YY' }}
        </p>
        <div class="danger-red">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"> </app-custom-icon>
        </div>
        <p class="danger-text">
          {{ 'Expires:' | translate }}
          {{ (certificate.validity_end ? certificate.validity_end : '') | date: 'dd.MM.YY' }}
        </p>
      </div>
    </div>
  </div>

  <div class="data-holder">
    <div class="data">
      <p>certificator</p>
      <h6>{{ certificate.certificator }}</h6>
    </div>
    <div class="data">
      <p>area</p>
      <h6>{{ certificate.certificate.domain?.name }}</h6>
    </div>
    <div class="data">
      <p>certificate category</p>
      <h6>{{ certificate.certificate.category.name }}</h6>
    </div>
  </div>

  <div class="site-holder">
    <div class="site-wrapper">
      <div class="site-text">
        <p>certificate applied to</p>
        <p>supplier sites:</p>
      </div>
      <div class="site" *ngFor="let manufactory of certificate.manufactories">
        <p class="name">{{ manufactory.name }}</p>
        <p class="address">
          {{ manufactory.address ? manufactory.address + ',' : '' }}{{ manufactory.city ? manufactory.city + ',' : '' }}
        </p>
        <div *ngIf="manufactory.is_main_location" class="main-site">
          <div class="dot"></div>
          <p class="main-site-text">{{ 'main supplier site' | translate }}</p>
        </div>
      </div>

      <div class="dots" *ngIf="certificate.manufactories.length > 2">
        <app-custom-icon id="dots" [icon_category]="'settings'" [icon_name]="'more'"> </app-custom-icon>
      </div>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="img-view-holder" [ngClass]="{ 'col-9': fileList.length, 'col-12': !fileList.length }">
        <ngx-doc-viewer
          *ngIf="!(selectedFile.mimetype.indexOf('image') > -1)"
          [url]="docURL + '?embedded=true'"
          [viewer]="viewer"
          [ngClass]="{ 'col-8': fileList.length, 'col-12': !fileList.length }"
        >
        </ngx-doc-viewer>
      </div>
    </div>
  </mat-dialog-content>
</div>
