<mat-dialog-content class="mat-typography dialog-cont" [ngClass]="{ 'batch-content': data.isBatch }">
  <div [ngClass]="{ 'info-div': data.isBatch }" id="infDiv" #infoDiv>
    <div class="logo-text-holder">
      <div class="logo" *ngIf="data.isBatch"></div>
      <p class="infotext">
        {{ infoMsg | translate }}
      </p>
    </div>

    <app-custom-icon
      *ngIf="data.isBatch"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      class="close-btn"
      (click)="infoDiv.style.display = 'none'"
    ></app-custom-icon>
  </div>
  <div [formGroup]="form" class="form-div">
    <div *ngIf="data?.screen === 'documents'">
      <div class="card-cont">
        <div class="left-box">
          <p class="infotext3">
            {{ infoMsg3 | translate }}
          </p>
          <mat-form-field appearance="outline" class="full-width name input-primary">
            <mat-label>{{ 'Document Name' | translate }}</mat-label>
            <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
          </mat-form-field>
        </div>
        <div class="right-box">
          <div
            class="card-content"
            [ngClass]="{
              'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
              'warn-uploaded': warn || typeWarn,
            }"
          >
            <div
              class="document-icon"
              [ngClass]="{
                'document-icon-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                'warn-uploaded-icon': warn || typeWarn,
              }"
            >
              <app-custom-icon [icon_category]="'files'" [icon_name]="'document-text-white'"></app-custom-icon>
            </div>
            <p class="" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded">
              {{ ' maximum 10MB' | translate }}
            </p>
            <div class="upload-btn" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn">
              <input
                type="file"
                class="file-upload"
                #fileUpload
                (change)="setFile($event, 'document')"
                accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff"
              />
              <app-rounded-button
                color="turquoise"
                [button_text]="'Choose Document' | translate"
                [icon_category]="'essential'"
                [icon_name]="'add'"
                [size]="'md'"
                [icon_right]="true"
                (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
              >
              </app-rounded-button>
              <span class="choose">
                {{ 'choose from PC or Mac' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data?.screen === 'medias'">
      <div class="">
        <div class="card-cont">
          <div class="left-box">
            <p class="infotext3">
              {{ infoMsg3 | translate }}
            </p>
            <mat-form-field appearance="outline" class="full-width name input-primary">
              <mat-label>{{ 'Media Name' | translate }}</mat-label>
              <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
            </mat-form-field>
          </div>
          <div class="right-box">
            <div
              class="card-content card-media-content"
              [ngClass]="{
                'file-uploaded': this.form.controls['file'].value && !(warn || typeWarn),
                'warn-uploaded': warn || typeWarn,
              }"
            >
              <div
                class="document-icon"
                [ngClass]="{
                  'image-uploaded': this.form.controls['file'].value && image_uploaded,
                  'video-uploaded': this.form.controls['file'].value && video_uploaded,
                  'warn-uploaded-icon': warn || typeWarn,
                }"
              >
                <img *ngIf="image_uploaded && !warn && !typeWarn && is_image_uploaded" [src]="uploadedUrl" alt="" />
                <video *ngIf="video_uploaded && !warn && !typeWarn && !is_image_uploaded" [src]="uploadedUrl"></video>
                <app-custom-icon
                  *ngIf="!warn && !typeWarn"
                  [icon_category]="'essential'"
                  [icon_name]="'upload-white'"
                ></app-custom-icon>
                <app-custom-icon
                  *ngIf="warn || typeWarn"
                  [icon_category]="'essential'"
                  [icon_name]="'image-red'"
                ></app-custom-icon>
              </div>
              <div class="max-holder" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else uploaded">
                <p class="">
                  {{ 'photos max 10MB' | translate }}
                </p>
                <p class="">
                  {{ 'videos max 25MB' | translate }}
                </p>
              </div>
              <div class="upload-btn" *ngIf="!this.form.controls['file'].value && !warn && !typeWarn; else closeBtn">
                <input
                  type="file"
                  class="file-upload"
                  #fileUpload
                  (change)="onSelectFile($event); setFile($event, 'media')"
                  accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
                />
                <app-rounded-button
                  color="turquoise"
                  [button_text]="'Choose Media' | translate"
                  [icon_category]="'essential'"
                  [icon_name]="'add'"
                  [size]="'md'"
                  [icon_right]="true"
                  (click)="fileUpload.click(); form.controls['file'] && form.controls['file'].markAsDirty()"
                >
                </app-rounded-button>
                <span class="choose">
                  {{ 'choose from PC or Mac' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data?.screen === 'links'">
      <div class="card-cont">
        <div class="left-box">
          <p class="infotext3">
            {{ infoMsg3 | translate }}
          </p>
          <mat-form-field appearance="outline" class="full-width name input-primary">
            <mat-label>{{ 'Link Name' | translate }}</mat-label>
            <input matInput formControlName="name" class="name" placeholder="{{ 'Name' | translate }}" />
          </mat-form-field>
        </div>
        <div class="right-box">
          <div
            class="card-content"
            [ngClass]="{
              'file-uploaded': this.form.controls['url'].value && !urlWarn,
              'warn-uploaded': this.form.controls['url'].value && urlWarn,
            }"
          >
            <div
              class="document-icon"
              [ngClass]="{
                'document-icon-uploaded': this.form.controls['url'].value && !urlWarn,
                'warn-uploaded-icon-url': this.form.controls['url'].value && urlWarn,
              }"
            >
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'link-white'"></app-custom-icon>
            </div>
            <div class="input-link">
              <input
                class="url-input"
                formControlName="url"
                placeholder="{{ 'paste your link here' | translate }}"
                (ngModelChange)="urlWarn = false"
                [ngClass]="{
                  'success-input': this.form.controls['url'].value && !urlWarn,
                  'warn-input': this.form.controls['url'].value && urlWarn,
                }"
              />
              <app-custom-icon
                *ngIf="this.form.controls['url'].value"
                [icon_category]="'essential'"
                class="link-close"
                [icon_name]="'close-circle'"
                (click)="clearUrlControl()"
              ></app-custom-icon>

              <p class="col-12 link-text" *ngIf="!this.form.controls['url'].value; else linkUploaded">
                {{ 'paste a link of a related website or socials' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <h4>{{ 'Select the supplier site you want to upload the media to' | translate }}</h4>
  <div class="supplier-sites-wrapper">
    <div
      class="site-card"
      *ngFor="let manufactory of manufactories"
      [ngClass]="{ selected: manufactory.uuid === selectedManufactoryUuid }"
      (click)="selectSite(manufactory)"
    >
      <div class="icon-div">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
      </div>
      <div class="icon">
        <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory'"></app-custom-icon>
      </div>
      <div class="site-info">
        <h5>{{ manufactory.name }}</h5>
        <h6>
          {{ manufactory.address ? manufactory.address + ',' : '' }}
          {{ manufactory.city ? manufactory.city + ',' : '' }} {{ manufactory.country }}
        </h6>
        <div class="is-main-location" [ngClass]="manufactory.is_main_location ? 'is' : ''">
          <p *ngIf="manufactory.is_main_location">{{ 'main supplier site' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="cancel">
    <app-rounded-button mat-dialog-close color="tetriary-link" [button_text]="'Cancel' | translate" [size]="'md'">
    </app-rounded-button>
  </div>
  <div>
    <app-rounded-button
      color="primary"
      [button_text]="(data.screen === 'links' ? 'Add link' : 'Upload') | translate"
      [icon_category]="'essential'"
      [icon_name]="'upload'"
      [size]="'md'"
      [disabled]="form.invalid || form.pristine || warn"
      [icon_right]="true"
      (click)="form.valid && form.dirty && !warn && !typeWarn && save()"
    >
    </app-rounded-button>
  </div>
</mat-dialog-actions>
<ng-template #uploaded>
  <div class="success-upload" *ngIf="!warn && !typeWarn">
    <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick'"></app-custom-icon>

    <p class="uploaded-txt">
      {{
        image_uploaded
          ? 'Your photo is ready to be'
          : video_uploaded
            ? 'Your video is ready to be'
            : ('Your  document is ready to be' | translate)
      }}
      <br />
      {{ 'uploaded!' | translate }}
    </p>
  </div>

  <div *ngIf="warn || typeWarn" class="warn-upload-holder">
    <div class="danger-icon">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
      <p *ngIf="is_image && warn" class="uploaded-txt">
        {{ 'Your document is larger than 10mb!' | translate }}
      </p>
      <p *ngIf="is_video && warn" class="uploaded-txt">
        {{ 'Your document is larger than 25mb!' | translate }}
      </p>
      <p *ngIf="typeWarn" class="uploaded-txt">
        {{ 'Your document format is not supported!' | translate }}
      </p>
    </div>
    <div class="try-again-holder">
      <p class="" *ngIf="warn">
        {{ 'Please upload the correct size' | translate }}
      </p>
      <p class="" *ngIf="typeWarn">
        {{ 'Please choose document with one of the formats bellow.' | translate }}
      </p>
      <app-rounded-button
        *ngIf="warn || typeWarn"
        color="turquoise"
        [button_text]="'Try again' | translate"
        [icon_category]="'arrows'"
        [icon_name]="'refresh-square'"
        [size]="'sm'"
        [icon_right]="true"
        (click)="warn = typeWarn = false; form.controls['file'] && form.controls['file'].markAsDirty()"
      >
      </app-rounded-button>
    </div>
  </div>
</ng-template>

<ng-template #closeBtn>
  <app-custom-icon
    *ngIf="!warn && !typeWarn"
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
    (click)="clearFileControl()"
    class="remove-file"
  ></app-custom-icon>
</ng-template>

<ng-template #linkUploaded>
  <p *ngIf="this.form.controls['url'].value && !urlWarn" class="link-text link-text-uploaded">
    {{ 'The link has been successfully added' | translate }}
  </p>
  <p class="error-link-text" *ngIf="this.form.controls['url'].value && urlWarn">
    {{ 'This site does not exist, please try again.' | translate }}
  </p>
</ng-template>
