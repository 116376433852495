<div class="header-chain">
  <div class="app-custom-icon">
    <app-custom-icon [icon_category]="'location'" [icon_name]="'routing-2'"></app-custom-icon>
  </div>
  <h6>{{ data.productChain.name }}</h6>
  <div class="pr-img">
    <img
      [src]="
        data.productChain.product.image
          ? data.productChain.product.image
          : '../../../../assets/images/upload-placeholder-blue.svg'
      "
    />
  </div>
  <h6>{{ data.productChain.product.name }}</h6>
</div>
<app-custom-icon
  class="close-icon cursor-pointer"
  icon_category="essential"
  icon_name="close-circle"
  mat-dialog-close
></app-custom-icon>
<hr />
<div mat-dialog-content class="d-content">
  <p class="step-list-title">{{ 'Supply chain steps list' | translate }}</p>

  <div class="table-wraper row">
    <table class="custom-table" mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Step Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supplier Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.supplier?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier_site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supplier Site' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.manufactories?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier_batch_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supplier Batch Number' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.supplier_batch_number ?? '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="component_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Component Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ingredient?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="step_phase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Step Phase' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phase?.name }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="co2_impact">
        <th mat-header-cell *matHeaderCellDef class="actions-header">{{ 'CO21 Impact' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="actions-body">
          <div *ngIf="element.co2_impacts.length">
            {{
              element.co2_impacts[0]?.value +
                element.co2_impacts[0]?.unit.toLowerCase() +
                '
                        / ' +
                element.co2_impacts[0]?.unit2.toLowerCase()
            }}
          </div>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions align="end">
  <mat-paginator [pageSizeOptions]="[6, 12, 18]" showFirstLastButtons aria-label="Select page of steps"></mat-paginator>
</div>
