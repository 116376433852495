import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlStorageService {
  private routerSubscription?: Subscription;
  private redirectSubscription?: Subscription;
  private previousUrl = '';
  private currentUrl = '';
  private redirectUrl = '';
  constructor(private router: Router) {
    this.routerSubscription = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map(event => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });

    this.redirectSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.redirectUrl = e.url;
      }
    });
  }

  cleanUp(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.redirectSubscription) {
      this.redirectSubscription.unsubscribe();
    }
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }
  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }
}
