import { UntypedFormGroup } from '@angular/forms';

export const GetDirtyValues = (form: UntypedFormGroup) => {
  const dirtyValues: Record<string, string> = {};

  Object.keys(form.controls).forEach(key => {
    const currentControl = form.controls[key];

    if (currentControl.dirty) {
      dirtyValues[key] = currentControl.value;
    }
  });

  return dirtyValues;
};
