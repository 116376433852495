import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { SuppSupplierService } from '../service/supp-supplier.service';
import {
  ISuppCompanyResponse,
  ISuppManufactoryResponse,
  ISuppChainStepResponse,
  ISuppDirectSupplier,
  ISuppChainStep,
  ISuppBatchDirectSuppResponse,
  IBatchChainStep,
  IMarkBatchStepAsCompleteResponse,
  IStepBatchQuanityUnitResponse,
  ISuppStepBatchDirectSupplier,
  ISuppDashboardStepsCountResponse,
  ISuppStepDirectSuppResponse,
} from '../interface/supp.interface';
import { generateFormData } from '../utils/generate-formData';
import { IManufactory } from '../interface/manufactories.interface';
import { IMedia } from '../interface/medias.interface';

@Injectable()
export class SuppSuppliersFacade {
  constructor(private _suppSuppliersService: SuppSupplierService) {}

  getCompany(): Observable<ISuppCompanyResponse> {
    return this._suppSuppliersService.getCompayInfo$().pipe(
      take(1),
      catchError((err): ObservableInput<ISuppCompanyResponse> => throwError(() => err.error))
    );
  }
  updateCompanyInfo$(data: object): Observable<ISuppCompanyResponse> {
    const formData = generateFormData(data);

    return this._suppSuppliersService.updateCompayInfo$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppCompanyResponse> => throwError(() => err.error))
    );
  }

  getSupplierSites$(): Observable<ISuppManufactoryResponse> {
    return this._suppSuppliersService.getSupplierSites$().pipe(
      take(1),
      catchError((err): ObservableInput<ISuppManufactoryResponse> => throwError(() => err.error))
    );
  }

  createSupplierSite$(data: Partial<IManufactory>): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.createSupplierSite$(formData).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }
  updateSupplierSite$(data: Partial<IManufactory>, uuid: string): Observable<IManufactory> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.updateSupplierSite$(formData, uuid).pipe(
      take(1),
      catchError(({ error }: IManufactory): ObservableInput<IManufactory> => throwError(() => error))
    );
  }
  deleteSupplierSite$(uuid: string): Observable<IManufactory[]> {
    return this._suppSuppliersService.deleteSupplierSite$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IManufactory[]> => throwError(() => err.error))
    );
  }

  getSupplierSteps$(limit?: number): Observable<ISuppChainStepResponse> {
    return this._suppSuppliersService.getSupplierSteps$(limit).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppChainStepResponse> => throwError(() => err.error))
    );
  }

  getSupplierStep$(uuid: string): Observable<ISuppChainStep> {
    return this._suppSuppliersService.getSupplierStep$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppChainStep> => throwError(() => err.error))
    );
  }

  getSuppDashboardStepsCount$(): Observable<ISuppDashboardStepsCountResponse> {
    return this._suppSuppliersService.getSuppDashboardStepsCount$().pipe(
      take(1),
      catchError((err): ObservableInput<ISuppDashboardStepsCountResponse> => throwError(() => err.error))
    );
  }

  updateSupplierStep$(uuid: string, data: Partial<ISuppChainStep>): Observable<ISuppChainStep> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.updateSupplierStep$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppChainStep> => throwError(() => err.error))
    );
  }

  getSupplierStepBatch$(uuid: string): Observable<IBatchChainStep> {
    return this._suppSuppliersService.getSupplierStepBatch$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IBatchChainStep> => throwError(() => err.error))
    );
  }

  updateSupplierStepBatch$(uuid: string, data: Partial<IBatchChainStep>): Observable<IBatchChainStep> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.updateSupplierStepBatch$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<IBatchChainStep> => throwError(() => err.error))
    );
  }

  disapproveStepBatch$(uuid: string, data: Partial<IBatchChainStep>): Observable<IBatchChainStep> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.disapproveStepBatch$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<IBatchChainStep> => throwError(() => err.error))
    );
  }

  markBatchStepAsComplete$(uuid: string, data: Partial<IBatchChainStep>): Observable<IMarkBatchStepAsCompleteResponse> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.markBatchStepAsComplete$(uuid, formData).pipe(
      take(1),
      catchError(
        ({ error }: IMarkBatchStepAsCompleteResponse): ObservableInput<IMarkBatchStepAsCompleteResponse> =>
          throwError(() => error)
      )
    );
  }

  //////////////////////// Direct suppliers endpoints /////////////////////////

  getDirectSuppliers$(step_uuid: string): Observable<ISuppStepDirectSuppResponse> {
    return this._suppSuppliersService.getDirectSuppliers$(step_uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppStepDirectSuppResponse> => throwError(() => err.error))
    );
  }

  getStepDirectSuppliers$(step_uuid: string): Observable<ISuppBatchDirectSuppResponse> {
    return this._suppSuppliersService.getStepBatchDirectSuppliers$(step_uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppBatchDirectSuppResponse> => throwError(() => err.error))
    );
  }

  addDirectSupplier$(data: Partial<ISuppDirectSupplier>, step_uuid: string): Observable<ISuppDirectSupplier> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.addDirectSupplier$(formData, step_uuid).pipe(
      take(1),
      catchError(({ error }: ISuppDirectSupplier): ObservableInput<ISuppDirectSupplier> => throwError(() => error))
    );
  }

  editDirectSupplier$(
    data: Partial<ISuppDirectSupplier>,
    step_uuid: string,
    uuid: string
  ): Observable<ISuppDirectSupplier> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.editDirectSupplier$(formData, step_uuid, uuid).pipe(
      take(1),
      catchError(({ error }: ISuppDirectSupplier): ObservableInput<ISuppDirectSupplier> => throwError(() => error))
    );
  }

  deleteDirectSupplier$(step_uuid: string, uuid: string): Observable<ISuppDirectSupplier> {
    return this._suppSuppliersService.deleteDirectSupplier$(step_uuid, uuid).pipe(
      take(1),
      catchError((error): ObservableInput<ISuppDirectSupplier> => throwError(() => error.error))
    );
  }

  getStepBatchQuantityUnit$(): Observable<IStepBatchQuanityUnitResponse> {
    return this._suppSuppliersService.getStepBatchQuantityUnit$().pipe(
      take(1),
      catchError(
        ({ error }: IStepBatchQuanityUnitResponse): ObservableInput<IStepBatchQuanityUnitResponse> =>
          throwError(() => error)
      )
    );
  }

  getStepBatchDirectSupplier$(uuid: string): Observable<ISuppStepBatchDirectSupplier> {
    return this._suppSuppliersService.getStepBatchDirectSupplier$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ISuppStepBatchDirectSupplier> => throwError(() => err.error))
    );
  }

  updateStepBatchDirectSupplier$(
    data: Partial<ISuppStepBatchDirectSupplier>,
    uuid: string
  ): Observable<ISuppStepBatchDirectSupplier> {
    const formData = generateFormData(data);
    return this._suppSuppliersService.updateStepBatchDirectSupplier$(formData, uuid).pipe(
      take(1),
      catchError(
        ({ error }: ISuppStepBatchDirectSupplier): ObservableInput<ISuppStepBatchDirectSupplier> =>
          throwError(() => error)
      )
    );
  }
  deactivateDirectSupplier$(uuid: string): Observable<string> {
    return this._suppSuppliersService.deactivateDirectSupplier$(uuid).pipe(
      take(1),
      catchError(({ error }: ISuppStepBatchDirectSupplier): ObservableInput<string> => throwError(() => error))
    );
  }

  addDirectSupplierProof$(proofs: FormData, uuid: string): Observable<IMedia> {
    return this._suppSuppliersService.addDirectSupplierProof$(proofs, uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IMedia> => throwError(() => err.error))
    );
  }

  deleteDirectSupplierProof$(uuid: string): Observable<IMedia> {
    return this._suppSuppliersService.deleteDirectSupplierProof$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IMedia> => throwError(() => err.error))
    );
  }

  // Signals
  warningDirectSuppliersSignal = signal(false);
  changingFlagSignal = signal(false);
}
