import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class SupplierComponentsGroup {
  addSupplierComponent: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.addSupplierComponent = this._fb.group({
      name: ['', Validators.required],
      reference: [''],
      description: [''],
      supplier: ['', Validators.required],
      manufactory: ['', Validators.required],
      category: [null],
      quantity: [null],
      sourcing_location: [null],
    });
  }
}
