import { Injectable } from '@angular/core';
import { Observable, take, catchError, ObservableInput, throwError } from 'rxjs';
import {
  IEmailTemplateResponse,
  IDeleteEmailTemplateResponse,
  IEmailTemp,
  IEmailTemplatesResponse,
  IEmailVariables,
  IEmailSupportRequest,
} from '../interface/email.interface';
import { IInviteSupplierStep } from '../interface/steps.interface';
import { EmailService } from '../service/email.service';
import { generateFormData } from '../utils/generate-formData';
import { EMAIL_SUGGESTIONS } from '../constants';
import { IEmailSendRequestResponse, ISupportResponse } from '../interface/supp.interface';

@Injectable()
export class EmailFacade {
  constructor(private _emailService: EmailService) {}

  getEmailTemlates$(): Observable<IEmailTemplatesResponse> {
    return this._emailService.getEmailTemplates$().pipe(
      take(1),
      catchError((err): ObservableInput<IEmailTemplatesResponse> => throwError(() => err.error))
    );
  }

  createEmailTemlate$(data: Partial<IEmailTemplateResponse>): Observable<IEmailTemplateResponse> {
    const formData = generateFormData(data);
    return this._emailService.createEmailTemplate$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IEmailTemplateResponse): ObservableInput<IEmailTemplateResponse> => throwError(() => error)
      )
    );
  }

  getEmailTemlate$(uuid: string): Observable<IEmailTemplateResponse> {
    return this._emailService.getEmailTemplate$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IEmailTemplateResponse> => throwError(() => err.error))
    );
  }

  updateEmailTemplate$(data: Partial<IEmailTemp>, uuid: string): Observable<IEmailTemplateResponse> {
    const formData = generateFormData(data);
    return this._emailService.updateEmailTemplate$(formData, uuid).pipe(
      take(1),
      catchError(
        ({ error }: IEmailTemplateResponse): ObservableInput<IEmailTemplateResponse> => throwError(() => error)
      )
    );
  }

  deleteEmailTemplate$(uuid: string): Observable<IDeleteEmailTemplateResponse> {
    return this._emailService.deleteEmailTemplate$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeleteEmailTemplateResponse): ObservableInput<IDeleteEmailTemplateResponse> =>
          throwError(() => error)
      )
    );
  }

  sendInvitations$(data: { email_template_uuid: string; supplier_contact: string }): Observable<object> {
    const formData = generateFormData(data);
    return this._emailService.sendInvitations$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<object> => throwError(() => err.error))
    );
  }

  sendEmailToSupplier$(uuid: string): Observable<IInviteSupplierStep> {
    return this._emailService.sendEmailToSupplier$(uuid).pipe(
      take(1),
      catchError(({ error }: IInviteSupplierStep): ObservableInput<IInviteSupplierStep> => throwError(() => error))
    );
  }

  sendEmail$(body: IEmailSupportRequest): Observable<ISupportResponse> {
    const formData = generateFormData(body);
    return this._emailService.sendEmail$(formData).pipe(
      take(1),
      catchError((err): Observable<ISupportResponse> => throwError(() => err.error))
    );
  }

  sendEmailRequestStepEdit$(stepUuid: string, data: FormData): Observable<IEmailSendRequestResponse> {
    const formData = generateFormData(data);
    return this._emailService.sendEmailRequestStepEdit$(stepUuid, formData).pipe(
      take(1),
      catchError((err): Observable<IEmailSendRequestResponse> => throwError(() => err.error))
    );
  }

  /**
   *
   * @param variables receives variables with english keys
   * @param language send the lang that needs to be translated ('fr')
   * @returns variables with Freanch Names
   */
  mapVariablesToFrenchNames(variables: IEmailVariables, language: 'en' | 'fr') {
    const mappedVariables: IEmailVariables = {};
    for (const suggestion of EMAIL_SUGGESTIONS) {
      const variableName = suggestion[language] || suggestion.en;
      const englishVariableName = suggestion.en.replace(/[{}]/g, '').trim();
      // eslint-disable-next-line no-prototype-builtins
      if (variables.hasOwnProperty(englishVariableName)) {
        mappedVariables[variableName.replace(/[{}]/g, '').trim()] = variables[englishVariableName];
      }
    }
    return mappedVariables;
  }
}
