import {
  IBrandFeaturesMessage,
  IBrandFeaturesResponse,
  IBrandListResult,
  IBrandUserReinvite,
  IBrandUserResult,
  IBrandUsersResponse,
  IBrandsListResponse,
  IDeleteBrandUser,
  ISuppBrandFeatureResponse,
} from '../interface/brands.interface';
import { ICreateBrandResponse, IDomainsResponse } from '../interface/register.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  private apiUrl = environment.api;
  constructor(private _http: HttpClient) {}

  createBrand$(data: FormData): Observable<ICreateBrandResponse> {
    return this._http.post<ICreateBrandResponse>(`${this.apiUrl}/brands/`, data);
  }

  getBrands$(): Observable<IBrandsListResponse> {
    return this._http.get<IBrandsListResponse>(`${this.apiUrl}/brands/`);
  }

  updateBrand$(data: FormData, uuid: string): Observable<IBrandListResult> {
    return this._http.patch<IBrandListResult>(`${this.apiUrl}/brands/${uuid}/`, data);
  }

  getDomains$(): Observable<IDomainsResponse> {
    return this._http.get<IDomainsResponse>(`${this.apiUrl}/brands/domains/`);
  }

  getBrandUsers$(): Observable<IBrandUsersResponse> {
    return this._http.get<IBrandUsersResponse>(`${this.apiUrl}/brands/users/`);
  }
  updateBrandUser$(data: FormData, uuid: string): Observable<IBrandUsersResponse> {
    return this._http.patch<IBrandUsersResponse>(`${this.apiUrl}/brands/users/${uuid}/`, data);
  }

  inviteBrandUser$(email: FormData): Observable<IBrandUserResult> {
    return this._http.post<IBrandUserResult>(`${this.apiUrl}/brands/users/invite/`, email);
  }

  resendInvite$(data: FormData): Observable<IBrandUserReinvite> {
    return this._http.post<IBrandUserReinvite>(`${this.apiUrl}/brands/users/reinvite/`, data);
  }

  deleteBrandUser$(uuid: string): Observable<IDeleteBrandUser> {
    return this._http.delete<IDeleteBrandUser>(`${this.apiUrl}/brands/users/${uuid}/`);
  }

  getBrand$(uuid: string): Observable<IBrandListResult> {
    return this._http.get<IBrandListResult>(`${this.apiUrl}/brands/${uuid}`);
  }

  switchBrand$(data: FormData): Observable<ICreateBrandResponse> {
    return this._http.post<ICreateBrandResponse>(`${this.apiUrl}/brands/switch/`, data);
  }
  getBrandFeatures$(): Observable<IBrandFeaturesResponse> {
    return this._http.get<IBrandFeaturesResponse>(`${this.apiUrl}/brand/features/`);
  }
  getSuppBrandFeatures$(): Observable<IBrandFeaturesResponse> {
    return this._http.get<IBrandFeaturesResponse>(`${this.apiUrl}/supp/brandfeature/`);
  }
  getBrandFeaturesByUuid$(uuid: string): Observable<ISuppBrandFeatureResponse> {
    return this._http.get<ISuppBrandFeatureResponse>(`${this.apiUrl}/supp/brandfeature/${uuid}/`);
  }

  enableBrandFeatures$(uuid: string): Observable<IBrandFeaturesMessage> {
    return this._http.post<IBrandFeaturesMessage>(`${this.apiUrl}/brand/features/${uuid}/enable/`, {});
  }

  disableBrandFeatures$(uuid: string): Observable<IBrandFeaturesMessage> {
    return this._http.post<IBrandFeaturesMessage>(`${this.apiUrl}/brand/features/${uuid}/disable/`, {});
  }
}
