<!-- invitation-box -->
<div>
  <div class="invitation-box">
    <img class="invitation-logo" [src]="logo" alt="Header Img" width="55px" height="55px" />
  </div>
</div>
<div>
  <!-- CONTENT -->
  <div class="html-wrapp">
    <div class="content" #content id="invite-content"></div>
  </div>
  <!-- Collaboration button  -->
  <div class="collaboration" *ngIf="isFirstInvite && !isDisapproval">
    <a class="button">
      <button class="button button-turquoise">{{ txt1 | translate }}</button>
    </a>
  </div>
  <div class="collaboration" *ngIf="isNextInvite || isDisapproval">
    <a class="button">
      <button class="button button-turquoise">{{ txt2 | translate }}</button>
    </a>
  </div>
  <div class="collaboration" *ngIf="isEditStep">
    <a class="button">
      <button class="button button-turquoise">{{ txt15 | translate }}</button>
    </a>
  </div>
</div>
<!-- Collaboration  -->
<div class="row coll-wrapp" *ngIf="isFirstInvite">
  <div class="col-tit" colspan="3">{{ txt3 | translate }}</div>
  <div class="col-4 col-sect">
    <img src="../../../../../assets/images/deal.svg" [alt]="txt4" class="col-img" />
    <h3 class="col-header">{{ txt4 | translate }}</h3>
    <p class="col-par">{{ txt5 | translate }}</p>
  </div>

  <div class="col-4 col-sect">
    <img src="../../../../../assets/images/traced-products-story.svg" [alt]="txt6" class="col-img" />
    <h3 class="col-header">{{ txt6 | translate }}</h3>
    <p class="col-par">
      {{ txt7 | translate }}
    </p>
  </div>

  <!-- <div
        style=" align-items: center; flex-direction: column; height: auto; float:left;max-width:600px; width: 30%; min-width: 250px;  margin-left: 15px;margin-top: 15px;"> -->
  <div class="col-4 col-sect">
    <img src="../../../../../assets/images/round-like.svg" [alt]="txt8" class="col-img" />
    <h3 class="col-header">{{ txt8 | translate }}</h3>
    <p class="col-par">{{ txt9 | translate }}.</p>
  </div>
</div>
<div class="row coll-wrapp" *ngIf="isNextInvite || isDisapproval">
  <img [src]="'../../../../../assets/images/methodology-' + lang + '.svg'" alt="Methodology" />
</div>
<!-- who are we  -->
<div class="w-r-wrapper">
  <div>
    <div class="center-text">
      <h1 class="l-title">{{ txt10 | translate }}</h1>
    </div>
  </div>
  <div>
    <div class="center-text">
      <p class="pdna-content col-par pdna-txt">
        <span class="pdna-name"> Product DNA </span>
        {{ txt11 | translate }}
      </p>
    </div>
  </div>
  <div class="bottom-sec">
    <div class="center-text">
      <h2 class="pr-talk">{{ txt12 | translate }}</h2>
    </div>
    <div>
      <div class="center-text visit-btn">
        <div style="display: inline-block">
          <a>
            <button class="button button-blue">{{ txt13 | translate }}</button>
          </a>
        </div>
      </div>
    </div>
    <div class="invite-footer center-text">
      <div class="copy-rights">
        <h4 class="copy-rights__h4">
          <span style="font-size: 32px; font-family: 'Inter Light', serif">&#169;</span>
          2023 RespectSaaS. {{ txt14 | translate }}
        </h4>
      </div>
      <div class="address-2"><span class="address">Product DNA</span> Av. du Léman 10, 1005 Lausanne, Switzerland</div>
    </div>
  </div>
</div>
