<div id="dialog" #dialog>
  <div class="title">
    <h6>{{ data.title }}</h6>
    <app-custom-icon id="arrow" [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
    <h4 *ngIf="data.doc?.uuid">{{ 'Edit Photo' | translate }}</h4>
    <h4 *ngIf="!data.doc?.uuid">{{ 'Uploading Photo' | translate }}</h4>
  </div>

  <div class="h-line">
    <div class="horizontal-line"></div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="row form-wrapper" *ngIf="has_image">
      <div class="col-5">
        <form [formGroup]="form">
          <mat-form-field appearance="outline" class="full-width name input-primary">
            <mat-label>{{ 'Media Name' | translate }} </mat-label>

            <input matInput formControlName="name" class="name" placeholder="Enter Media name" />
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="wrapper row">
      <div class="image-holder col-5">
        <!-- Image design -->
        <div class="image-wrapper" *ngIf="has_image">
          <div class="img-holder">
            <image-cropper
              #cropper
              [disabled]="!isNewUpload"
              [imageURL]="data.docUrl"
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="false"
              [containWithinAspectRatio]="is_fitView"
              [aspectRatio]="1 / 1"
              format="png"
              (imageCropped)="imageCropped($event)"
              [transform]="transform"
              [canvasRotation]="canvasRotation"
            >
            </image-cropper>
          </div>
        </div>
        <!-- No image design -->
        <div class="no-image-wrapper" *ngIf="!has_image">
          <div class="img-holder">
            <div class="mask">
              <img class="no-img" src="../../../../assets/images/mask.svg" />
            </div>
          </div>
          <input class="d-none" #changePhoto type="file" (change)="fileChangeEvent($event)" />

          <div class="btn-holder">
            <div class="change-photo">
              <app-rounded-button
                id="change"
                color="secondary"
                class="link"
                [button_text]="'company-information.Upload Photo' | translate"
                [icon_category]="'essential'"
                [icon_name]="'upload'"
                [icon_right]="true"
                [size]="'md'"
                (click)="changePhoto.click()"
              >
              </app-rounded-button>
            </div>
          </div>
        </div>

        <div class="zoom" *ngIf="has_image && isNewUpload">
          <div class="zoom-in">
            <app-custom-icon
              id="zoom-in"
              [icon_category]="'essential'"
              [icon_name]="'add-circle'"
              (click)="zoomIn()"
            ></app-custom-icon>
            <p>{{ 'company-information.Zoom in' | translate }}</p>
          </div>
          <div class="zoom-out">
            <app-custom-icon
              id="zoom-out"
              [icon_category]="'essential'"
              [icon_name]="'minus-circle'"
              (click)="zoomOut()"
            ></app-custom-icon>
            <p>{{ 'company-information.Zoom out' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="instructions {{ isNewUpload ? 'col-5' : 'col-6' }}" *ngIf="has_image">
        <div class="row">
          <h6>Photo Instructions</h6>
          <p>
            {{
              isNewUpload
                ? 'Please note that you can adjust the fitting of the photo in the fitting options bellow.'
                : 'Please note that in order to modify the appearance of the photo you must first re-upload it from your
            local machine, or choose other photo.'
            }}
          </p>
          <p>
            {{
              isNewUpload
                ? 'By default the photo is set to fit in container.'
                : 'To upload new or re-upload the photo simply click on the upload button bellow.'
            }}
          </p>
        </div>

        <input class="d-none" #changePhoto type="file" (change)="fileChangeEvent($event)" />
        <div class="btn-holder row">
          <div class="change-photo col-6">
            <app-rounded-button
              id="change"
              color="secondary"
              class="link"
              [button_text]="
                data.doc?.uuid
                  ? ('company-information.Upload Photo' | translate)
                  : ('company-information.Change photo' | translate)
              "
              [icon_category]="'essential'"
              [icon_name]="'image'"
              [icon_right]="true"
              [size]="'md'"
              (click)="changePhoto.click()"
            >
            </app-rounded-button>
          </div>
          <div class="delete-photo col-6" (click)="deletePhoto()">
            <app-rounded-button
              id="delete"
              color="tetriary-link"
              [button_text]="'company-information.Delete photo' | translate"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              [icon_right]="true"
              [size]="'sm'"
              (click)="(imageChangedEvent)"
            >
            </app-rounded-button>
          </div>
        </div>

        <div class="btns-wrapper row" *ngIf="isNewUpload">
          <h6>Fitting options</h6>
          <div class="toggle-group">
            <mat-button-toggle-group #group="matButtonToggleGroup" value="fitView" (change)="onValChange(group.value)">
              <div class="fit-container toggle">
                <mat-button-toggle #fitView value="fitView" aria-label="Fit View">
                  <div class="fit-container-btn">
                    <app-custom-icon
                      id="tick-circle"
                      [icon_category]="'essential'"
                      [icon_name]="is_fitView ? 'tick-circle' : 'circle'"
                    ></app-custom-icon>
                    <p class="fit">{{ 'company-information.Fit in container' | translate }}</p>
                    <app-custom-icon id="maximize" [icon_category]="'grid'" [icon_name]="'maximize'"></app-custom-icon>
                  </div>
                </mat-button-toggle>
              </div>
              <div class="fill-container toggle">
                <mat-button-toggle value="fillView" aria-label="Fill View">
                  <div class="fill-container-btn">
                    <app-custom-icon
                      id="circle"
                      [icon_category]="'essential'"
                      [icon_name]="is_fitView ? 'circle' : 'tick-circle'"
                    ></app-custom-icon>
                    <p class="fill">{{ 'company-information.Fill container' | translate }}</p>
                    <app-custom-icon
                      id="maximize-3"
                      [icon_category]="'grid'"
                      [icon_name]="'maximize-3'"
                    ></app-custom-icon>
                  </div>
                </mat-button-toggle>
              </div>
            </mat-button-toggle-group>
          </div>
          <div class="rotate" (click)="rotateRight()">
            <p class="rotate-text">{{ 'company-information.rotate 90 degrees' | translate }}</p>
            <app-custom-icon id="rotate-right" [icon_category]="'grid'" [icon_name]="'rotate-right'"></app-custom-icon>
          </div>
        </div>
      </div>

      <div class="logo-preview col-2" *ngIf="has_image && isNewUpload">
        <div class="logo">
          <h6>Photo preview</h6>
          <app-file-upload [imageUrl]="croppedImage" [readonly]="true"></app-file-upload>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <app-rounded-button
      id="cancel"
      color="tetriary-link"
      [button_text]="'company-information.Cancel' | translate"
      [size]="'sm'"
      (click)="dialogCancel()"
    >
    </app-rounded-button>
    <app-rounded-button
      class="upload-photo"
      id="save"
      color="turquoise"
      [icon_name]="'image'"
      [icon_category]="'essential'"
      [icon_right]="true"
      [button_text]="
        (!data.doc?.uuid || isNewUpload ? 'company-information.Upload Photo' : 'Update photo info') | translate
      "
      [size]="'sm'"
      (click)="dialogClose()"
      cdkFocusInitial
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
