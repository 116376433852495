import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class AddMediasSupplierSiteGroup {
  filesForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.filesForm = this._fb.group({
      name: [null, [Validators.required]],
      url: [null, [Validators.required, Validators.maxLength(255)]],
      file: [null, [Validators.required]],
    });
  }
}
