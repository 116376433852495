import { NotificationType, Storage, USER } from '@app/core/constants';

import { Component } from '@angular/core';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IUser } from '@app/core/interface/login.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-cookies-management',
  templateUrl: './cookies-management.component.html',
  styleUrl: './cookies-management.component.scss',
  providers: [RegisterFacade],
})
export class CookiesManagementComponent {
  performanceCookies?: boolean;
  functionalCookies?: boolean;

  constructor(
    private _registerFacade: RegisterFacade,
    public dialogRef: MatDialogRef<CookiesManagementComponent>,
    private _snackbarService: SnackbarService,
    private _dataAuthService: DataAuthService,
    private _dataStorageService: DataStorageService
  ) {
    let user = this._dataAuthService.user;
    user = typeof user === 'string' ? JSON.parse(user) : user;

    this.functionalCookies = user?.is_functional_cookies_approved;
    this.performanceCookies = user?.is_performance_cookies_approved;
  }

  confirmChoices(): void {
    this._registerFacade
      .manageProfile$({
        is_performance_cookies_approved: this.performanceCookies,
        is_functional_cookies_approved: this.functionalCookies,
      })
      .subscribe({
        next: this._success.bind(this),
      });
  }

  private _success(response: IUser) {
    this._dataStorageService.set(USER, JSON.stringify(response), Storage.local);
    this._dataAuthService.user = response;
    this.dialogRef.close();
    this._snackbarService.openTypeSnackbar('Cookies settings updated', NotificationType.success);
  }
}
