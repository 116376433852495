<form [formGroup]="form" class="email-template-container">
  <div class="head-wrap col-12" *ngIf="!isEditModal">
    <div class="head-wrap col-12">
      <div class="col-12 e-head">
        <mat-label>{{ 'Email Template Name' | translate }}</mat-label>
      </div>
      <mat-form-field class="edit-field input-primary col-4" appearance="outline" floatLabel="always">
        <mat-label>{{ 'Email Template Name' | translate }}</mat-label>
        <input matInput [placeholder]="'Email Template Name Here...*' | translate" required formControlName="name" />
        <mat-error>{{ 'please add the email template name' | translate }}</mat-error>
      </mat-form-field>
      <hr />
    </div>
  </div>
  <div class="info-div row" #infoDiv>
    <div class="col-1">
      <img class="logo" src="assets/logos/symbol-logo-dark.svg" alt="" />
    </div>
    <p class="info-msg p2 col-10" *ngIf="!isEditModal">
      {{
        'Here you can modify the default template text and variations accordingly. Please note that the variations will
      be automatically replaced when this template is used in a specific email. You can add variations anywhere in the
      text, by simply typing the + sign, and then choosing the variation.' | translate
      }}
    </p>
    <p class="info-msg p2 col-10" *ngIf="isEditModal">
      {{
        'Here you can edit the content of the email. You can edit the text and also add variations anywhere in the text,
      by simply typing the + sign, and then choosing the variation.' | translate
      }}
    </p>
    <div class="col-1">
      <app-custom-icon
        class="close-icon cursor-pointer pull-right"
        icon_category="essential"
        icon_name="close-circle"
        (click)="infoDiv.style.display = 'none'"
      ></app-custom-icon>
    </div>
  </div>
  <div class="e-head spce-between">
    <mat-label>{{ 'Template content' | translate }}</mat-label>
    <div class="reset-tit" *ngIf="form.dirty">
      <mat-label class="reset-tit-lb">{{ 'Reset Content' | translate }}</mat-label>
      <app-custom-icon
        class="cursor-pointer"
        [icon_category]="'arrows'"
        [icon_name]="'refresh-left-square'"
        (click)="resetFormValues()"
      ></app-custom-icon>
    </div>
  </div>
  <textarea
    matInput
    #textarea
    class="var-textarea"
    formControlName="content"
    (keydown)="handleKeydown($event)"
    appTextareaAutoResize
  ></textarea>

  <ul *ngIf="showSuggestions" class="suggestions" [style.top.px]="suggestionsTop" [style.left.px]="suggestionsLeft">
    <li *ngFor="let suggestion of translatedSuggestions" (click)="addSuggestion(suggestion)" class="suggestion">
      {{ suggestion }}
    </li>
  </ul>
</form>
