import {
  IBatch,
  IBatchQuanityUnit,
  IBatchQuanityUnitResponse,
  IBatchResponse,
  IBatchResult,
} from './../interface/batch.interface';

import { BatchService } from '@service/batch.service';
import { IProductChainStepResponse } from '../interface/steps.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class BatchFacade {
  constructor(private _batchService: BatchService) {}

  createBatch$(data: Partial<IBatch>): Observable<IBatch> {
    const formData = generateFormData(data);

    return this._batchService.createBatch$(formData).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatch> => throwError(() => error))
    );
  }

  updateBatch$(uuid: string, data: Partial<IBatch>): Observable<IBatch> {
    const formData = generateFormData(data);

    return this._batchService.updateBatch$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatch> => throwError(() => error))
    );
  }

  getAllQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._batchService.getAllQuantityUnit$().pipe(
      take(1),
      catchError(
        ({ error }: IBatchQuanityUnitResponse): ObservableInput<IBatchQuanityUnitResponse> => throwError(() => error)
      )
    );
  }

  getQuantityUnit$(uuid: string): Observable<IBatchQuanityUnitResponse> {
    return this._batchService.getQuantityUnit$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IBatchQuanityUnitResponse): ObservableInput<IBatchQuanityUnitResponse> => throwError(() => error)
      )
    );
  }

  getBatchQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._batchService.getBatchQuantityUnit$().pipe(
      take(1),
      catchError(
        ({ error }: IBatchQuanityUnitResponse): ObservableInput<IBatchQuanityUnitResponse> => throwError(() => error)
      )
    );
  }

  getActiveQuantityUnit$(): Observable<IBatchQuanityUnitResponse> {
    return this._batchService.getActiveQuantityUnit$().pipe(
      take(1),
      catchError(
        ({ error }: IBatchQuanityUnitResponse): ObservableInput<IBatchQuanityUnitResponse> => throwError(() => error)
      )
    );
  }

  createQuantityUnit$(data: Partial<IBatch>): Observable<IBatchQuanityUnit> {
    const formData = generateFormData(data);

    return this._batchService.createQuantityUnit$(formData).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatchQuanityUnit> => throwError(() => error))
    );
  }

  updateQuantityUnit$(uuid: string, data: Partial<IBatchQuanityUnit>): Observable<IBatchQuanityUnit> {
    const formData = generateFormData(data);
    return this._batchService.updateQuantityUnit$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatchQuanityUnit> => throwError(() => error))
    );
  }

  getBatch$(uuid: string): Observable<IBatchResult> {
    return this._batchService.getBatch$(uuid).pipe(
      take(1),
      catchError(({ error }: IBatchResult): ObservableInput<IBatchResult> => throwError(() => error))
    );
  }

  updateBatchChainActivation$(uuid: string, data: Partial<IBatch>): Observable<IBatch> {
    return this._batchService.updateBatchChainActivation$(uuid, data).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatch> => throwError(() => error))
    );
  }

  updateBatchChain$(uuid: string, data: Partial<IBatch>): Observable<IBatch> {
    return this._batchService.updateBatchChain$(uuid, data).pipe(
      take(1),
      catchError(({ error }: IBatch): ObservableInput<IBatch> => throwError(() => error))
    );
  }

  getBatchSteps$(): Observable<IProductChainStepResponse> {
    return this._batchService.getBatchSteps$().pipe(
      take(1),
      catchError(
        ({ error }: IProductChainStepResponse): ObservableInput<IProductChainStepResponse> => throwError(() => error)
      )
    );
  }

  getBatchList$(): Observable<IBatchResponse> {
    return this._batchService.getBatchList$().pipe(
      take(1),
      catchError(({ error }: IBatchResponse): ObservableInput<IBatchResponse> => throwError(() => error))
    );
  }
}
