import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEmailTemp } from '@app/core/interface/email.interface';
import { CreateEmailContentComponent } from '../modify-template/create-email-content/create-email-content.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modify-template-dialog',
  templateUrl: './modify-template-dialog.component.html',
  styleUrls: ['./modify-template-dialog.component.scss'],
})
export class ModifyTemplateDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CreateEmailContentComponent) createEmailContentComponent!: CreateEmailContentComponent;
  disabled = false;
  private timeoutId: NodeJS.Timeout | undefined;
  private subsctiption?: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ModifyTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { template: IEmailTemp }
  ) {}
  ngOnDestroy(): void {
    if (this.subsctiption) this.subsctiption.unsubscribe();
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }
  ngAfterViewInit(): void {
    if (this.data) this.setForm();
    this.subsctiption = this.createEmailContentComponent.form.valueChanges.subscribe(() => {
      this.disabled = this.createEmailContentComponent.form.invalid || this.createEmailContentComponent.form.pristine;
    });
  }

  setForm(): void {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.createEmailContentComponent.form.controls['name'].setValue(this.data.template.name);
      this.createEmailContentComponent.form.controls['content'].setValue(this.data.template.content);
      this.createEmailContentComponent.form.markAsPristine();
    }, 0);
  }

  updateContent(): void {
    this.dialogRef.close(this.createEmailContentComponent.form.value);
  }
  cancel(): void {
    this.dialogRef.close();
  }
}
