import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IEmailTemp, IEmailVariables } from '@app/core/interface/email.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import {
  BATCH_STEP_DISAPPROVAL,
  BRAND_LOGO,
  BRAND_NAME,
  STEP_EDIT,
  FIRST_INVITE,
  INDIRECT_SUPPLIER,
  NEXT_INVITE,
  Storage,
  USER,
} from '@app/core/constants';
import { TranslateToService } from '@app/core/service/translate-to.service';
import { DatePipe } from '@angular/common';
import { EmailFacade } from '@app/core/facade/email.facade';
import { DataAuthService } from '@service/data-auth.service';

@Component({
  selector: 'app-invite-supplier-tmp',
  templateUrl: './invite-supplier-tmp.component.html',
  styleUrls: ['./invite-supplier-tmp.component.scss'],
  providers: [DatePipe, EmailFacade],
})
export class InviteSupplierTmpComponent implements OnChanges, OnDestroy {
  readonly logoPlaceholder = '../../../../../assets/images/upload-placeholder-blue.svg';
  contentTxt = '';
  headerTxt = '';
  isFirstInvite = false;
  isNextInvite = false;
  isDirectInvite = false;
  isDisapproval = false;
  isEditStep = false;
  lang: 'en' | 'fr' = 'en';
  logo = '../../../../../assets/images/upload-placeholder-blue.svg';
  txt1 = '';
  txt2 = '';
  txt3 = '';
  txt4 = '';
  txt5 = '';
  txt6 = '';
  txt7 = '';
  txt8 = '';
  txt9 = '';
  txt10 = '';
  txt11 = '';
  txt12 = '';
  txt13 = '';
  txt14 = '';
  txt15 = '';

  @Input() supplier!: ISupplier;
  @Input() template?: IEmailTemp;
  constructor(
    private _dataStorageService: DataStorageService,
    private _translateService: TranslateToService,
    private datePipe: DatePipe,
    private _dataAuthService: DataAuthService,
    private _facade: EmailFacade
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier'].currentValue) this.headerTxt = changes['supplier'].currentValue.name;
    if (changes['template'].currentValue) {
      this.lang = changes['template'].currentValue.email_template_type?.language;
      this.headerTxt = changes['template'].currentValue.header;
      this.contentTxt = changes['template'].currentValue.content;
      /**
       * If we have variables property then we are in editing mode before sending invite
       * and needs variables to be replaced with actual values
       */
      changes['template'].currentValue.variables
        ? this.renderTextWithVariables(this.contentTxt, changes['template'].currentValue.variables)
        : this.renderTextToHTML(this.contentTxt);
      const logo = this._dataStorageService.get(BRAND_LOGO, Storage.local);
      this.logo = logo && logo !== 'null' ? logo : '../../../../../assets/images/upload-placeholder-blue.svg';
      const name: string = changes['template'].currentValue?.email_template_type?.type ?? '';
      this.setTemplateType(name);
      queueMicrotask(() => {
        this.setTranslations(this.lang || 'en');
      });
    }
  }
  setTemplateType(name: string): void {
    this.isFirstInvite = name.toLowerCase().includes(FIRST_INVITE);
    this.isNextInvite = name.toLowerCase().includes(NEXT_INVITE);
    this.isDirectInvite = !name.toLowerCase().includes(INDIRECT_SUPPLIER);
    this.isDisapproval = name.toLowerCase().includes(BATCH_STEP_DISAPPROVAL);
    this.isEditStep = name.toLowerCase().includes(STEP_EDIT);
  }
  ngOnDestroy(): void {
    this._translateService.ngOnDestroy();
  }
  /**
   * @param lang the language that needs the value to be translated
   */

  setTranslations(lang: string): void {
    this._translateService.translateTo('email-templates.Start the collaboration', lang).then((translation: string) => {
      this.txt1 = translation;
    });
    this._translateService.translateTo('email-templates.Log into Respect SaaS', lang).then((translation: string) => {
      this.txt2 = translation;
    });
    this._translateService
      .translateTo('email-templates.How will this collaboration help you?', lang)
      .then((translation: string) => {
        this.txt3 = translation;
      });
    this._translateService.translateTo('email-templates.Build trust', lang).then((translation: string) => {
      this.txt4 = translation;
    });

    this._translateService
      .translateTo('email-templates.by collaborating with your customers, you show care for their needs', lang)
      .then((translation: string) => {
        this.txt5 = translation;
      });

    this._translateService
      .translateTo('email-templates.Be part of the story of the traced product', lang)
      .then((translation: string) => {
        this.txt6 = translation;
      });

    this._translateService
      .translateTo('email-templates.by accepting to share your value chain data', lang)
      .then((translation: string) => {
        this.txt7 = translation;
      });

    this._translateService.translateTo('email-templates.Manage your reputation', lang).then((translation: string) => {
      this.txt8 = translation;
    });

    this._translateService
      .translateTo('email-templates.with consistent tracing of your supply chain', lang)
      .then((translation: string) => {
        this.txt9 = translation;
      });

    this._translateService.translateTo('email-templates.Who are we?', lang).then((translation: string) => {
      this.txt10 = translation;
    });

    this._translateService
      .translateTo(
        'email-templates.is a company specializing in traceability and transparency. Since 2005, we have developed a unique methodology that helps companies and organizations achieve full supply chain transparency. Our software solutions enable our clients to gain an understanding of their products, suppliers, and supply chains, and communicate the data collected with confidence.',
        lang
      )
      .then((translation: string) => {
        this.txt11 = translation;
      });

    this._translateService
      .translateTo('email-templates.We make your product talk.', lang)
      .then((translation: string) => {
        this.txt12 = translation;
      });

    this._translateService.translateTo('email-templates.Visit our website', lang).then((translation: string) => {
      this.txt13 = translation;
    });

    this._translateService.translateTo('email-templates.All rights reserved.', lang).then((translation: string) => {
      this.txt14 = translation;
    });

    this._translateService.translateTo('email-templates.Open supply chain', lang).then((translation: string) => {
      this.txt15 = translation;
    });
  }
  /**
   * With this function we convert already entered text in a textarea by the user and we are converting it to an HTML element,
   * adding required style for the email template preview (turquoise curly braces, bold variables...),
   * and converting URLs into hyperlinks. At the end, we append the new element as a child into the invite-content div.
   * @param text input text that user enters using input field
   */
  renderTextToHTML(text: string) {
    /**
     * Create a new div element to hold the converted content
     */
    const container = document.createElement('div');
    container.style.whiteSpace = 'pre-wrap'; // Preserve whitespace

    /**
     * Replace HTML angle brackets with HTML entities to prevent them from being parsed as tags
     */
    const escapedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    /**
     * Convert URLs to hyperlinks. This includes URLs starting with http(s) and www.
     */
    const hyperlinkText = escapedText.replace(/(https?:\/\/[^\s]+)|(www\.[^\s]+)/g, match => {
      // Prepend 'http://' if the URL starts with 'www.'
      let url = match;
      if (match.startsWith('www.')) {
        url = 'http://' + match;
      }
      return `<a href="${url}" target="_blank">${match}</a>`;
    });

    /**
     * Apply color styling to curly braces and variables (bold variables)
     */
    const styledText = hyperlinkText
      .replace(/{/g, '<b style="color: #30D5C8;">{</b><b>')
      .replace(/}/g, '</b><b style="color: #30D5C8;">}</b>');

    /**
     * Set the innerHTML of the container to the styled text
     */
    container.innerHTML = styledText;

    /**
     * Get a reference to the invite-content element
     */
    const contentElement = document.getElementById('invite-content');

    /**
     * Append the generated content to the #content element
     */
    contentElement && contentElement.appendChild(container);
  }

  /**
   * If we have variables property then we are in editing mode before sending invite
   * and needs variables to be replaced with actual values, this methid render the content with actual values for the variables
   */
  renderTextWithVariables(text: string, variables: IEmailVariables) {
    const beandName = this._dataStorageService.get(BRAND_NAME, Storage.local);
    const user = JSON.parse(this._dataStorageService.get(USER, Storage.local)) ?? this._dataAuthService.user;
    let vars = {
      ...variables,
      'Brand name': beandName,
      'Brand contact name': user.first_name + ' ' + user.last_name,
      'Supplier name': this.supplier.name,
      'Current date': this.datePipe.transform(new Date(), 'dd/MM/yyyy'), // todo add new variabless
      'Supplier contact name': this.template?.variables?.['Supplier contact name'],
      'Step name': this.template?.variables?.['Step name'],
    } as IEmailVariables;
    if (this.lang === 'fr') vars = this._facade.mapVariablesToFrenchNames(vars, this.lang);
    /**
     * Create a new div element to hold the converted content
     */
    const container = document.createElement('div');
    container.style.whiteSpace = 'pre-wrap'; // Preserve whitespace

    /**
     * Replace HTML angle brackets with HTML entities to prevent them from being parsed as tags
     */
    const escapedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    /**
     * Convert URLs to hyperlinks. This includes URLs starting with http(s) and www.
     */
    const hyperlinkText = escapedText.replace(/(https?:\/\/[^\s]+)|(www\.[^\s]+)/g, match => {
      // Prepend 'http://' if the URL starts with 'www.'
      let url = match;
      if (match.startsWith('www.')) {
        url = 'https://' + match;
      }
      return `<a href="${url}" target="_blank">${match}</a>`;
    });

    /**
     * Replace variables enclosed in curly braces with their values
     */
    const replacedText = hyperlinkText.replace(/{(.*?)}/g, (match, variableName) => {
      const value = vars[variableName.trim()];
      if (value === null) {
        return `<b style="color: #30D5C8;">{</b><b> ${variableName.trim()} </b><b style="color: #30D5C8;">}</b>`;
      }
      if (value !== undefined) {
        return `<b>${value}</b>`;
      }
      // If the variable is not found, leave it unchanged
      return match;
    });

    /**
     * Remove Project with styles if it's not present present because project is optional and can be null
     *   { en: '{ Project name }', fr: '{ Nom du projet }' },
     */
    // const finalText = replacedText.replace(/\/ Project <b>null<\/b>|<b>null<\/b>|\/ Projet <b>null<\/b>/g, '');
    const pattern =
      /\/ Project <b style="color: #30D5C8;">{<\/b><b> Project name <\/b><b style="color: #30D5C8;">}<\/b>|\/ Projet <b style="color: #30D5C8;">{<\/b><b> Nom du projet <\/b><b style="color: #30D5C8;">}<\/b>/g;
    const finalText = replacedText.replace(pattern, ''); //{ en: '{ Project name }', fr: '{ Nom du projet }' },

    /**
     * Set the innerHTML of the container to the replaced text
     */
    container.innerHTML = finalText;

    /**
     * Get a reference to the invite-content element
     */
    const contentElement = document.getElementById('invite-content');

    /**
     * Append the generated content to the #content element
     */
    contentElement && contentElement.appendChild(container);
  }
}
