<div class="modal-wraper" [ngClass]="{ deactivate: data.isDeactivate }">
  <div class="form-note">
    <img src="../../../../../../assets/images/symbol-blue.svg" />

    <div class="" *ngIf="!data.isMarkStep">
      <p>
        {{
          (data.isDeactivate
            ? 'Note that once you deactivate this Batch you can reactivate it form any of the Batches lists.'
            : 'Are you sure you want to reactivate the batch ' + data.batchName + ' ?'
          ) | translate
        }}
      </p>
    </div>
    <div class="col-11" *ngIf="data.isMarkStep">
      <p>{{ 'Please note that once the Batch is completed you cannot modify it anymore.' | translate }}</p>
    </div>
  </div>

  <div class="">
    <div class="col-12">
      <h4>
        {{ 'Do you wish to continue?' | translate }}
      </h4>
    </div>
  </div>

  <mat-dialog-actions align="center">
    <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
    </app-rounded-button>
    <app-rounded-button
      color="{{ data.isDeactivate ? 'danger' : 'primary' }}"
      [button_text]="'Yes, Continue'"
      [size]="'md'"
      [mat-dialog-close]="true"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
