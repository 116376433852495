<div class="preview-container" *ngIf="!uploadBtnText">
  <span class="icon-holder" *ngIf="(previews.length || docURL) && !readonly" (click)="openImageEditDialog()">
    <app-custom-icon [icon_category]="'files'" [icon_name]="'edit-2'"></app-custom-icon>
  </span>

  <img *ngFor="let preview of previews" [src]="preview" class="preview" />
  <img *ngIf="!previews.length && docURL" [src]="docURL" class="preview" />
  <div class="upload-image-container align-items-center">
    <div
      *ngIf="!previews.length && !docURL"
      class="preview preview-placeholder"
      (click)="!readonly && uploadInput.click()"
    >
      <div class="click-upload" *ngIf="!readonly">
        <app-custom-icon [icon_category]="'files'" [icon_name]="'cloud-plus'"></app-custom-icon>
        <p>{{ 'click to upload' | translate }}</p>
      </div>
    </div>
    <div *ngIf="!readonly" class="upload-image-text">
      <h3>{{ uploadImageText | translate }}</h3>
      <p>{{ uploadImageSubtext | translate }}</p>
    </div>
  </div>
</div>

<app-rounded-button
  *ngIf="uploadBtnText && !readonly"
  color="success"
  [button_text]="uploadBtnText"
  [icon_category]="'arrows'"
  [icon_name]="'export'"
  [size]="'md'"
  [icon_right]="true"
  (click)="!readonly && uploadInput.click()"
>
</app-rounded-button>

<mat-form-field appearance="fill">
  <div class="img-file-input">
    <mat-toolbar>
      <input
        [ngClass]="{
          'color-warning': status === 'INVALID' && uploadFormControl.touched,
        }"
        [formControl]="styleFormControl"
        matInput
        (blur)="setValidation()"
      />
    </mat-toolbar>

    <input
      type="file"
      class="fileInput"
      name="fileInput"
      accept="image/*"
      (change)="selectFiles($event)"
      [formControl]="uploadFormControl"
      #uploadInput
    />
  </div>
  <div matSuffix mat-icon-button (click)="!readonly && uploadInput.click()">
    <mat-icon>{{ iconType }}</mat-icon>
  </div>
</mat-form-field>
