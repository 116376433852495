<div class="dialog-header">
  <h2>{{ 'Invite Users' | translate }}</h2>
  <!-- <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon> -->
</div>
<mat-dialog-content>
  <mat-stepper linear #stepper>
    <!-- Step 1: Select contacts -->
    <mat-step>
      <div class="step-wrap">
        <app-invite-users-list
          (usersSelected)="handleSelection($event)"
          (refreshAfterClose)="refreshAfterClose = $event"
          [uuid]="data.uuid"
          [languages]="data.languages"
        ></app-invite-users-list>
      </div>
    </mat-step>

    <!-- Step 2: Select Template -->
    <mat-step>
      <app-select-email-template
        (templateSelected)="handleTemplateSelection($event)"
        [selectedContact]="selectedContact"
        [variables]="data.variables"
        [supplier]="data.supplier"
        (goBack)="goBack()"
      ></app-select-email-template>
    </mat-step>

    <!-- Step 3: Success Message -->
    <mat-step>
      <div>
        <app-invitations-send></app-invitations-send>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()" class="col-2">
    {{ (stepper.selectedIndex !== 2 ? 'Close' : 'OK, got it') | translate }}
  </button>
  <app-rounded-button
    color="primary"
    [size]="'md'"
    matStepperNext
    (click)="continue()"
    [disabled]="disabled || !selectedContact"
    [button_text]="(stepper.selectedIndex !== 1 ? 'Continue' : 'Send') | translate"
    *ngIf="stepper.selectedIndex !== 2"
  >
  </app-rounded-button>
</mat-dialog-actions>
