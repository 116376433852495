import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IMedia } from '@app/core/interface/medias.interface';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
})
export class FileCardComponent {
  @Output() onFileSelected = new EventEmitter<IMedia>();
  @Input() file!: IMedia;
  @Input() supplierName?: string;

  openSingleFile(selectedFile: IMedia) {
    this.onFileSelected.emit(selectedFile);
  }

  matchFileIcon(mimetype: string): Record<string, string> {
    if (mimetype.indexOf('application') > -1) {
      return {
        name: 'clipboard-text',
        category: 'files',
      };
    } else if (mimetype.indexOf('video') > -1) {
      return {
        name: 'video-2',
        category: 'essential',
      };
    } else {
      return {
        name: 'image',
        category: 'essential',
      };
    }
  }
}
