<div class="file-list-wrap">
  <mat-dialog-content class="file-list-content">
    <div *ngIf="dialogData.screen !== 'links'" class="cards-wraper">
      <div *ngFor="let file of files$ | async" class="custom-card">
        <div class="cursor-pointer" (click)="openSingleFile(file)">
          <div class="product-details-card">
            <ngx-doc-viewer
              [url]="file.file"
              [viewer]="getViewerType(file.mimetype)"
              *ngIf="file.mimetype.indexOf('application') > -1"
              class="preview card-cover"
              [disableContent]="'all'"
            >
            </ngx-doc-viewer>
            <div
              style="background-image: url('{{ file.file }}');"
              *ngIf="file.mimetype.indexOf('image') > -1"
              class="preview card-cover"
            ></div>
            <video
              [src]="file.file"
              *ngIf="file.mimetype.indexOf('video') > -1"
              height="200"
              class="preview card-cover"
              [controls]="false"
            ></video>
            <br />
          </div>
        </div>
        <div class="divider"></div>
        <div class="bottom-holder">
          <div class="upload-wrap">
            <div class="file-name-folder">
              <app-custom-icon
                *ngIf="file.mimetype.indexOf('video') > -1 && dialogData.screen !== 'documents'"
                [icon_category]="'essential'"
                [icon_name]="'video-2'"
              >
              </app-custom-icon>
              <app-custom-icon
                *ngIf="file.mimetype.indexOf('image') > -1 && dialogData.screen !== 'documents'"
                [icon_category]="'essential'"
                [icon_name]="'image'"
              >
              </app-custom-icon>
              <p class="filename">{{ file.name }}</p>
            </div>

            <div class="upload-holder">
              <!-- <app-custom-icon [icon_category]="'essential'" [icon_name]="'trick-circle-green'"> </app-custom-icon>
              <p class="upload-txt">{{ 'uploaded' | translate }}: {{ file.created_at | date: 'dd.MM.yyyy' }}</p> -->
              <div class="action-text">
                {{ 'Display for publication' | translate }}
              </div>
              <mat-slide-toggle
                [checked]="!file.is_entity_private"
                class="custom-slide-toggle"
                [color]="'primary'"
                (change)="onChange(file)"
              >
              </mat-slide-toggle>
            </div>
          </div>
          <div class="actions">
            <app-custom-icon
              class="cursor-pointer"
              mat-button
              [matMenuTriggerFor]="more"
              [icon_category]="'settings'"
              [icon_name]="'more'"
            >
            </app-custom-icon>
            <mat-menu #more="matMenu" class="more-menu-in-dialog action-menu" sition="before" yPostition="bellow">
              <button mat-menu-item (click)="openSingleFile(file)">
                <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
                {{ 'Open' | translate }}
              </button>
              <button mat-menu-item (click)="editFile(file)">
                <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"></app-custom-icon>
                {{ 'Edit' | translate }}
              </button>
              <button mat-menu-item (click)="openConfirmationDialog(file.name, file.uuid)" class="color-red">
                <app-custom-icon
                  [icon_category]="'essential'"
                  [icon_name]="'trash-red'"
                  class="trash-red"
                ></app-custom-icon>
                {{ 'Remove' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dialogData.screen === 'links'" class="cards-wraper">
      <div *ngFor="let site of files$ | async" class="custom-cardi item-card-link">
        <div class="link-heder-holder">
          <div class="link-holder">
            <a [href]="site.url" aria-label="Visit page" target="_blank" class="cursor-pointer">
              <app-custom-icon class="custom-icon" [icon_category]="'essential'" [icon_name]="'link'">
              </app-custom-icon>
              <a [href]="site.url" target="_blank" class="section-header card-name">
                {{ site.name }}
              </a>
            </a>
          </div>
          <div class="upload-wrap">
            <!-- <div class="">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'trick-circle-green'"> </app-custom-icon>
            </div>
            <p class="upload-txt">{{ 'Uploaded' | translate }}:{{ site.created_at | date }}</p> -->
            <div class="action-text">
              {{ 'Display for publication' | translate }}
            </div>
          </div>
        </div>
        <div class="icon-holder">
          <div class="edit-icon">
            <app-custom-icon
              class="cursor-pointer"
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editFile(site)"
            >
            </app-custom-icon>
          </div>
          <div class="deletee-icon">
            <app-custom-icon
              class="cursor-pointer"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              (click)="openConfirmationDialog(site.url, site.uuid)"
            >
            </app-custom-icon>
          </div>
          <mat-slide-toggle
            [checked]="!site.is_entity_private"
            class="custom-slide-toggle p-a"
            [color]="'primary'"
            (change)="onChange(site)"
          >
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="card-footer">
    <mat-paginator #paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of files">
    </mat-paginator>
  </div>
</div>
