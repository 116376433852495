<div class="custom-card">
  <div class="cursor-pointer" (click)="openSingleFile(file)">
    <div class="product-details-card">
      <ngx-doc-viewer
        [url]="file.file"
        [disableContent]="'all'"
        [viewer]="file.mimetype.indexOf('pdf') > -1 ? 'url' : 'office'"
        *ngIf="file.mimetype.indexOf('application') > -1"
        class="preview card-cover"
      >
      </ngx-doc-viewer>
      <div
        style="background-image: url('{{ file.file }}');"
        *ngIf="file.mimetype.indexOf('image') > -1"
        class="preview card-cover"
      ></div>
      <video
        [src]="file.file"
        *ngIf="file.mimetype.indexOf('video') > -1"
        height="200"
        class="preview card-cover"
        [controls]="false"
      ></video>
      <br />
    </div>
  </div>
  <div class="divider"></div>
  <div class="bottom-holder">
    <div class="upload-wrap">
      <div class="title">
        <app-custom-icon
          [icon_category]="matchFileIcon(file.mimetype)['category']"
          [icon_name]="matchFileIcon(file.mimetype)['name']"
        ></app-custom-icon>
        <p class="filename">{{ file.name }}</p>
      </div>
      <div class="upload-holder">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'trick-circle-green'"> </app-custom-icon>
        <p class="upload-txt">
          {{ 'uploaded' | translate }}:
          {{ file.created_at | date : 'dd.MM.yyyy' }}
        </p>
      </div>
    </div>
    <div class="supplier" *ngIf="supplierName">
      <div>
        <app-custom-icon [icon_category]="'product'" [icon_name]="'truck'"></app-custom-icon>
        <p>{{ 'Supplier' | translate }}</p>
      </div>
      <h5>{{ supplierName }}</h5>
    </div>
  </div>
</div>
