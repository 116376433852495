import {
  IDeleteIngredient,
  IIngredient,
  IIngredientCategory,
  IIngredientCategoryResponse,
  IIngredientsResponse,
} from './../interface/ingredient.interface';

import { IngredientService } from './../service/ingredient.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class IngredientFacade {
  constructor(private _service: IngredientService) {}

  getIngredients$(): Observable<IIngredientsResponse> {
    return this._service.getIngredients$().pipe(
      take(1),
      catchError(({ error }: IIngredientsResponse): ObservableInput<IIngredientsResponse> => throwError(() => error))
    );
  }

  getIngredient$(uuid: string): Observable<IIngredient> {
    return this._service.getIngredient$(uuid).pipe(
      take(1),
      catchError(({ error }: IIngredient): ObservableInput<IIngredient> => throwError(() => error))
    );
  }

  createIngredient$(data: Partial<IIngredient>): Observable<IIngredient> {
    const formData = generateFormData(data);
    return this._service.createIngredient$(formData).pipe(
      take(1),
      catchError(({ error }: IIngredient): ObservableInput<IIngredient> => throwError(() => error))
    );
  }

  updateIngredient$(uuid: string, data: Partial<IIngredient>): Observable<IIngredient> {
    const formData = generateFormData(data);
    return this._service.updateIngredient$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: IIngredient): ObservableInput<IIngredient> => throwError(() => error))
    );
  }

  deleteIngredient$(uuid: string): Observable<IDeleteIngredient> {
    return this._service.deleteIngredient$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteIngredient): ObservableInput<IDeleteIngredient> => throwError(() => error))
    );
  }

  getIngredientCategories$(is_active: string): Observable<IIngredientCategoryResponse> {
    return this._service.getIngredientCategories$(is_active).pipe(
      take(1),
      catchError(
        ({ error }: IIngredientCategoryResponse): ObservableInput<IIngredientCategoryResponse> =>
          throwError(() => error)
      )
    );
  }

  createIngredientCategory$(data: Partial<IIngredientCategory>): Observable<IIngredientCategory> {
    const formData = generateFormData(data);
    return this._service.createIngredientCategory$(formData).pipe(
      take(1),
      catchError(({ error }: IIngredientCategory): ObservableInput<IIngredientCategory> => throwError(() => error))
    );
  }

  updateIngredientCategory$(uuid: string, data: Partial<IIngredientCategory>): Observable<IIngredientCategory> {
    const formData = generateFormData(data);
    return this._service.updateIngredientCategory$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: IIngredientCategory): ObservableInput<IIngredientCategory> => throwError(() => error))
    );
  }
}
