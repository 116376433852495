import { ACCESS_TOKEN, Storage, USER } from '@app/core/constants';
import {
  ICompleteProfileForm,
  ICompleteProfileResponse,
  ICreateAccountForm,
  ICreateBrandForm,
  ICreateBrandResponse,
  ICreateProductForm,
  ICreateProductResponse,
  IDomainsResponse,
} from '@app/core/interface/register.interface';
import { Injectable, OnDestroy } from '@angular/core';

import { AccountsService } from '@service/accounts.service';
import { BrandsService } from '../service/brands.service';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { ILanguagesResponse } from '../interface/languages.interface';
import { ILoginResponse, IUser } from '../interface/login.interface';
import { LanguagesService } from '../service/languages.service';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { ProductsService } from '@service/products.service';
import { Subject } from 'rxjs/internal/Subject';
import { catchError } from 'rxjs/internal/operators/catchError';
import { filter } from 'rxjs/internal/operators/filter';
import { forkJoin, take } from 'rxjs';
import { generateFormData } from '../utils/generate-formData';
import { map } from 'rxjs/internal/operators/map';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class RegisterFacade implements OnDestroy {
  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _accountsService: AccountsService,
    private _brandsService: BrandsService,
    private _dataAuthService: DataAuthService,
    private _dataStorageService: DataStorageService,
    private _productsService: ProductsService,
    private _languagesService: LanguagesService
  ) {}

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  createAccount$(data: ICreateAccountForm): Observable<ILoginResponse> {
    const formData = generateFormData(data);

    return this._accountsService.createAccount$(formData).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ILoginResponse): boolean => !!load),
      tap((res: ILoginResponse) => {
        this._dataAuthService.user = res.user;
        this._dataStorageService.set(USER, JSON.stringify(res.user), Storage.session);
        this._dataStorageService.set(ACCESS_TOKEN, res.access_token, Storage.session);
      }),
      catchError(({ error }: ILoginResponse): ObservableInput<ILoginResponse> => throwError(() => error))
    );
  }

  completeProfile$(data: Partial<ICompleteProfileForm>): Observable<ICompleteProfileResponse> {
    const formData = generateFormData(data);

    return this._accountsService.completeProfile$(formData).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ICompleteProfileResponse): boolean => !!load),
      tap((data: ICompleteProfileResponse) => {
        const storedUser: IUser = data;
        this._dataStorageService.set(USER, JSON.stringify(storedUser), Storage.local);
      }),
      catchError(
        ({ error }: ICompleteProfileResponse): ObservableInput<ICompleteProfileResponse> => throwError(() => error)
      )
    );
  }

  createBrand$(data: Partial<ICreateBrandForm>): Observable<ICreateBrandResponse> {
    const formData = generateFormData(data);

    return this._brandsService.createBrand$(formData).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ICreateBrandResponse): boolean => !!load),
      tap((data: ICreateBrandResponse) => {
        const storedUser: IUser = JSON.parse(this._dataStorageService.get(USER, Storage.local));
        storedUser.brand = data.uuid;
        this._dataStorageService.set(USER, JSON.stringify(storedUser), Storage.local);
      }),
      catchError(({ error }: ICreateBrandResponse): ObservableInput<ICreateBrandResponse> => throwError(() => error))
    );
  }

  createProduct$(data: Partial<ICreateProductForm>): Observable<ICreateProductResponse> {
    return this._productsService.createProduct$(data).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ICreateProductResponse): boolean => !!load),
      map((data: ICreateProductResponse) => data),
      catchError(
        ({ error }: ICreateProductResponse): ObservableInput<ICreateProductResponse> => throwError(() => error)
      )
    );
  }

  getDomains$(): Observable<IDomainsResponse> {
    return this._brandsService.getDomains$().pipe(
      takeUntil(this._unsubscribe$),
      filter((load: IDomainsResponse): boolean => !!load),
      map((data: IDomainsResponse) => data),
      catchError(({ error }: IDomainsResponse): ObservableInput<IDomainsResponse> => throwError(() => error))
    );
  }

  getDomainsAndLangs$(): Observable<[IDomainsResponse, ILanguagesResponse]> {
    return forkJoin([this.getDomains$(), this._languagesService.getLanguages$()]);
  }

  getAccountDetails$(): Observable<ICompleteProfileResponse> {
    return this._accountsService.getAccountDetails().pipe(take(1));
  }

  manageProfile$(data: Partial<ICompleteProfileForm>): Observable<ICompleteProfileResponse> {
    const formData = generateFormData(data);

    return this._accountsService.manageProfile$(formData).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ICompleteProfileResponse): boolean => !!load),
      map((data: ICompleteProfileResponse) => data),
      catchError(
        ({ error }: ICompleteProfileResponse): ObservableInput<ICompleteProfileResponse> => throwError(() => error)
      )
    );
  }

  inviteSupplier$(data: Partial<ICompleteProfileForm>): Observable<ICompleteProfileResponse> {
    const formData = generateFormData(data);

    return this._accountsService.inviteSupplier$(formData).pipe(
      takeUntil(this._unsubscribe$),
      filter((load: ICompleteProfileResponse): boolean => !!load),
      map((data: ICompleteProfileResponse) => data),
      catchError(
        ({ error }: ICompleteProfileResponse): ObservableInput<ICompleteProfileResponse> => throwError(() => error)
      )
    );
  }
}
