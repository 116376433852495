import { Component, Inject, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { ILanguage } from '@app/core/interface/languages.interface';
import {
  IMediaDialogData,
  IFilesMeadiasLinks,
  IMediaResponse,
  IMediaScreens,
} from '@app/core/interface/medias.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ADD_DOCUMENT_ALLOWED_TYPES, NotificationType } from '@app/core/constants';
import { TranslateService } from '@ngx-translate/core';
import { AddDocumentsAndMediasComponent } from '../add-documents-and-medias/add-documents-and-medias.component';
import { AddMediasSupplierSiteGroup } from './add-medias-supplier-site.group';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { uploadFileSize, uploadMediaSize } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-add-medias-supplier-site',
  templateUrl: './add-medias-supplier-site.component.html',
  styleUrls: ['./add-medias-supplier-site.component.scss'],
  providers: [AddMediasSupplierSiteGroup, FilesMeadiasLinksFacade],
})
export class AddMediasSupplierSiteComponent {
  @Input() manufactories!: Array<IManufactory> | undefined;
  languages: ILanguage[] = [];
  selectedLanguages: ILanguage[] = [];
  form: UntypedFormGroup;
  format: 'video' | 'image' = 'image';
  itemType: 'product' | 'supplier' | 'step' = 'product';
  title = 'Add';
  infoMsg = '';
  infoMsg2 = '';
  infoMsg3 = '';
  warn = false;
  typeWarn = false;
  is_image = false;
  is_video = false;
  video_uploaded = false;
  is_image_uploaded = false;
  uploadedUrl!: string | null | ArrayBuffer;
  image_uploaded = false;
  urlWarn = false;
  isNoteShowing = true;
  selected!: boolean;
  selectedManufactoryUuid = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMediaDialogData,
    private _dialogRef: MatDialogRef<AddDocumentsAndMediasComponent>,
    private _group: AddMediasSupplierSiteGroup,
    private _facade: FilesMeadiasLinksFacade,
    private _snackbarService: SnackbarService,
    private _translate: TranslateService
  ) {
    this.form = this._group.filesForm;
    if (data.type === 'products') this.itemType = 'product';
    if (data.type === 'suppliers') this.itemType = 'supplier';
    if (data.type === 'steps') this.itemType = 'step';
    this.setTexts(data.screen);
    if (data.screen === 'links') {
      this.form.removeControl('file');
    } else {
      this.form.removeControl('url');
    }
    if (data.doc) {
      this.title = this._translate.instant('Edit ' + this.data.screen);
      this.form.controls['name'].patchValue(this.data.doc?.name);
      if (data.screen !== 'links') {
        this.form.controls['file'].patchValue(this.data.doc?.file);
      } else {
        this.infoMsg = 'Here you can add link of website or ' + this.itemType + ' link.';
        this.form.controls['url'].patchValue(this.data.doc?.url);
      }
    } else {
      this.title = 'Upload ' + this.data.screen;
    }
  }

  save(): void {
    const saveForm: Partial<IFilesMeadiasLinks> = GetDirtyValues(this.form);
    if (this.data.doc) {
      this._facade
        .updateMedia$(saveForm, this.data.type, this.data.typeUuid, this.data.screen, this.data.doc.uuid)
        .subscribe({
          next: this._successUpdate.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._facade.createMedia$(saveForm, this.data.type, this.data.typeUuid, this.data.screen).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  setFile(event: Event, type?: 'document' | 'media'): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (file) {
      this.typeWarn = !this._facade.isValidUploadType(file.type, ADD_DOCUMENT_ALLOWED_TYPES);
      if (this.typeWarn) return;
      if (type === 'document' && file.size > uploadFileSize) {
        this.warn = true;
        return;
      }
      if (type === 'media' && file.type.indexOf('image') > -1 && file.size > uploadFileSize) {
        this.is_image = true;
        this.warn = true;
        return;
      }
      if (type === 'media' && file.type.indexOf('video') > -1 && file.size > uploadMediaSize) {
        this.warn = true;
        this.is_video = true;
        return;
      }
      const name = file.name.split('.').slice(0, -1).join('-');
      this.form.patchValue({ file: file, name: name });
      this.form.controls['name'].markAsDirty();
      this.form.controls['file'].markAsDirty();
    }
  }
  setTexts(screen: IMediaScreens): void {
    if (screen === 'documents') {
      this.infoMsg = this._translate.instant('Upload a document to supplier site ');
      this.infoMsg2 = this._translate.instant('Upload a document to ' + this.itemType + 'site');
      this.infoMsg3 = this._translate.instant('Please upload your document in the required format');
    }
    if (screen === 'medias') {
      this.infoMsg = this._translate.instant(
        'Upload media to Supplier Site '
        // + this.itemType + '.'
      );
      this.infoMsg2 = '';
      this.infoMsg3 = this._translate.instant('Please upload photo or video in the required format');
    }
    if (screen === 'links') {
      this.infoMsg = this._translate.instant('Add a website or social link to Supplier Site');
      this.infoMsg2 = '';
      this.infoMsg3 = this._translate.instant('Please upload existing web or sociallinks related to the supplier site');
    }
  }

  clearFileControl(): void {
    this.form.controls['file'].setValue('');
    this.image_uploaded = this.video_uploaded = this.warn = this.typeWarn = false;
  }
  clearUrlControl(): void {
    this.form.controls['url'].setValue('');
  }

  onSelectFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      this.is_image_uploaded = file.type.includes('image');
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = event => {
        if (event.target) {
          this.uploadedUrl = event.target?.result;
          if (this.is_image_uploaded && !this.warn) {
            this.image_uploaded = true;
          } else if (!this.is_image_uploaded && !this.warn) {
            this.video_uploaded = true;
          }
        }
      };
    }
  }

  selectSite(manufactory: IManufactory): void {
    this.form.get('manufactory')?.setValue(manufactory.uuid);
    this.selectedManufactoryUuid = manufactory.uuid;
  }
  private _success(): void {
    this._dialogRef.close(true);
    this._snackbarService.openTypeSnackbar('Successfully added', NotificationType.success);
  }

  private _successUpdate(res: IMediaResponse): void {
    this._dialogRef.close(res);
    this._snackbarService.openTypeSnackbar('Successfully updated', NotificationType.success);
  }
  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
    if (error['file']) {
      this.typeWarn = error['file'][0].toLowerCase().includes('allowed extensions');
      this.warn = error['file'][0].toLowerCase().includes('max size');
    }
    if (error['url']) this.urlWarn = error['url'][0].toLowerCase().includes('enter a valid url');
  }
}
