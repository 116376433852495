import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IProductChainStep, IProductChainStepErrorResponse } from '@app/core/interface/steps.interface';
import { UntypedFormGroup, Validators } from '@angular/forms';

import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { INode } from '@app/core/interface/productchain.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { MatDialog } from '@angular/material/dialog';
import { NodeService } from '@app/core/service/node.service';
import { NotificationType } from '@app/core/constants';
import { ProductChainGroup } from '@app/module/product-chain/product-chain.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepsFacade } from '@app/core/facade/steps.facade';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.scss'],
})
export class AddSupplierComponent implements OnInit {
  @Input() node!: INode;
  @Input() supplierList!: ISupplier[];
  filteredSupplierList!: ISupplier[];
  step = -1;
  form: UntypedFormGroup;
  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;
  focus = true;
  filterAppled = false;

  constructor(
    private _group: ProductChainGroup,
    private nodeService: NodeService,
    private _stepFacade: StepsFacade,
    private _snackbarService: SnackbarService,
    public dialog: MatDialog
  ) {
    this.form = this._group.updateStep;
    this.filteredSupplierList = this.supplierList;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['node']) {
      this.form.patchValue({
        position_x: this.node.position_x,
        position_y: this.node.position_y,
      });

      this.form.get('position_x')?.markAsDirty();
      this.form.get('position_y')?.markAsDirty();
    }
  }

  ngOnInit(): void {
    this.form.patchValue({
      name: this.node.name,
    });

    this.form.get('supplier')?.setValidators(Validators.required);
  }

  setStep(index: number, supplierUuid: string, isChecked: boolean) {
    this.step = index;
    this.form.controls['manufactories'].reset('', false);
    this.form.controls['manufactories'].markAsPristine();

    if (isChecked) {
      this.form.patchValue({
        supplier: supplierUuid,
      });
    } else {
      this.form.patchValue({
        supplier: null,
      });
    }

    this.form.get('supplier')?.markAsDirty();
  }

  addSupplier(): void {
    const changedFormValues: Partial<IProductChainStep> = GetDirtyValues(this.form);

    if (this.form.valid && this.node.uuid) {
      this._stepFacade.updateStep$({ ...changedFormValues }, this.node.uuid).subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._updateStepError.bind(this),
      });
    }
  }

  onBlur(): void {
    this.focus && this.inputFilter.nativeElement.focus();
  }

  filterItem(event: Event | KeyboardEvent): void {
    this.filterAppled = false;
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.filteredSupplierList = this.supplierList;
    } else {
      this.filterAppled = true;
      this.filteredSupplierList = this.supplierList.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  private _updateStepSuccess(data: IProductChainStep): void {
    this.nodeService.selectedStepData.next(null);
    this._snackbarService.openTypeSnackbar(`Step ${data.name} updated`, NotificationType.success);
  }

  private _updateStepError(error: IProductChainStepErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
