import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-info',
  templateUrl: './input-info.component.html',
  styleUrls: ['./input-info.component.scss'],
})
export class InputInfoComponent {
  @Input() label?: string;
  @Input() value?: string;
  @Input() number?: string;
  @Input() type?: string;
}
