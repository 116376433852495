<div class="row">
  <div class="sel-info" #selInfo>
    <div class="detail-txt row">
      <img src="../../../../assets/images/symbol-blue.svg" alt="Symbol" height="45" width="45" class="col-1" />
      <span class="more-details col-11">
        <p class="p1-strong">
          {{ 'Please note that once you invite the Contact, they will have:' | translate }}
        </p>
        <p class="p2-normal inf-msg">
          <span class="msg-blue">
            {{ 'free management access to your traceability account' | translate }} {{ ' ' }}
          </span>
          {{ '(they can edit and add info only for the specific data they are invited for).' }}
        </p>
      </span>
    </div>
    <app-custom-icon
      class="close-icon cursor-pointer cls-more-details"
      icon_category="essential"
      icon_name="close-circle"
      (click)="selInfo.style.display = 'none'"
    ></app-custom-icon>
  </div>
  <h6 class="col-9">
    {{ 'Please select a contact you like to invite, or create a new one' | translate }}
  </h6>
  <div class="col-3">
    <app-rounded-button
      id="profile-add"
      color="secondary"
      [button_text]="'Create contact'"
      [icon_category]="'user'"
      [icon_name]="'user-add'"
      [size]="'md'"
      [icon_right]="true"
      (click)="addSupplierContact()"
    >
    </app-rounded-button>
  </div>
</div>
<div class="users-list-header">
  <div class="sel-data"></div>
  <div class="long-data">{{ 'Name' | translate }}</div>
  <div class="long-data">{{ 'Email' | translate }}</div>
  <div class="long-data">{{ 'Phone' | translate }}</div>
  <div class="long-data">{{ 'Title' | translate }}</div>
  <div class="long-data">{{ 'Language' | translate }}</div>
  <div class="short-data">{{ 'Status' | translate }}</div>
  <div class="short-data">{{ 'Main Contact' | translate }}</div>
</div>

<div *ngFor="let user of users" class="users-list-item">
  <div>
    <!-- <mat-checkbox color="primary" (change)="toggleUserSelection(user)"></mat-checkbox> -->
    <mat-radio-button
      color="primary"
      (click)="$event.stopPropagation()"
      (change)="toggleUserSelection(user)"
      [checked]="selection.isSelected(user)"
      class="sel-data"
    ></mat-radio-button>
  </div>
  <div class="cont-name long-data">{{ user.first_name + ' ' + user.last_name }}</div>
  <div class="long-data">
    <app-custom-icon [icon_category]="'messages'" [icon_name]="'sms'"></app-custom-icon>
    {{ user.email ? user.email : '-' }}
  </div>
  <div class="long-data">
    <app-custom-icon [icon_category]="'essential'" [icon_name]="'call'"></app-custom-icon>
    {{ user.phone ? user.phone : '-' }}
  </div>
  <div class="long-data msg-blue">{{ user.position ? user.position : '-' }}</div>
  <div class="long-data msg-blue">{{ user.language_prefered?.name ? user.language_prefered?.name : '-' }}</div>
  <div class="short-data">{{ (user.is_active ? 'Active' : 'Inactive') | translate }}</div>
  <div class="short-data" [ngClass]="{ 'short-data': true, green: user.main_contact, orange: !user.main_contact }">
    {{ (user.main_contact ? 'Yes' : 'No') | translate }}
  </div>
</div>
