<div class="wraper">
  <h2 *ngIf="!isChainComplete" mat-dialog-title>
    {{ data.batch ? 'Editing Batch' : ('Batch Creation' | translate) }}
  </h2>
  <div class="view-header" *ngIf="isChainComplete">
    <h2>
      {{ 'Batch Info' | translate }}
    </h2>
    <app-custom-icon
      class="close-btn cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      mat-dialog-close
    ></app-custom-icon>
  </div>

  <div class="details-holder">
    <h6>
      <strong>{{ 'Batch Details' | translate }}</strong>
    </h6>
    <div class="image-holder">
      <p>{{ 'Product:' }}</p>
      <div class="logo">
        <img
          class="avatar"
          src="{{
            data.product_chain?.product?.image
              ? data.product_chain?.product?.image
              : '../../../../../../assets/images/upload-placeholder-blue.svg'
          }}"
        />
      </div>
      <div class="name">
        <p>{{ data.product_chain?.product?.name }}</p>
      </div>
    </div>
    <div class="image-holder" *ngIf="show_project">
      <p>{{ 'Project:' | translate }}</p>
      <div class="logo">
        <img
          class="avatar"
          src="{{
            data.product_chain?.product?.project?.logo
              ? data.product_chain?.product?.project?.logo
              : '../../../../../../assets/images/upload-placeholder-blue.svg'
          }}"
        />
      </div>
      <div class="name" *ngIf="data.product_chain?.product?.project?.name; else notEntered">
        <p>{{ data.product_chain?.product?.project?.name }}</p>
      </div>
      <ng-template #notEntered>
        <p>{{ 'Not entered' | translate }}</p>
      </ng-template>
    </div>
  </div>
  <div class="note" *ngIf="!isChainComplete" (click)="batchInfoTooltip = !batchInfoTooltip">
    <img src="../../../../../assets/images/symbol-1.svg" />
    <p class="gray">{{ 'Need help ?' | translate }}</p>

    <div class="tooltip" *ngIf="batchInfoTooltip">
      <div>
        <p class="strong m-8">
          {{ 'Batch Name: ' | translate }}
          <span class="normal">{{
            ' you can name it as a version of the supply chain or traced product.' | translate
          }}</span>
        </p>
        <p class="strong m-8">
          {{ 'Units: ' | translate }}
          <span class="normal">{{
            ' If the unit you want to set isn`t in the list, it can be added from the settings.' | translate
          }}</span>
        </p>
        <p class="strong m-8">
          {{ 'Batch Date: ' | translate }} <span class="normal">{{ ' could be a single ' | translate }} </span>
          <span class="strong">{{ 'date ' | translate }}</span>
          <span class="normal">{{
            '(order date, delivery date, shipment date...), or it could be a ' | translate
          }}</span>
          <span class="strong">{{ 'period ' | translate }}</span>
          <span class="normal">{{ ' of start and end date (production date, time period...)' | translate }}</span>
        </p>
        <p class="strong m-8">
          {{ 'Free text: ' | translate }}
          <span class="normal">{{
            ' use the free text if you need another field to enter specific information. It could be a period of time, a harvesting year or date ...'
              | translate
          }}</span>
        </p>
        <p class="strong m-8">
          {{ 'Batch reference: ' | translate }}
          <span class="normal">{{
            ' reference that you need to track and is linked to this batch. Could be your lot number, a production number or any reference that serves you.'
              | translate
          }}</span>
        </p>
      </div>

      <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'"></app-custom-icon>
    </div>
  </div>
  <div class="content-section" cdkScrollable *ngIf="!isChainComplete; else viewBatchInfo">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-4">
          <h6>{{ 'Batch name*' | translate }}</h6>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Enter batch name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="Enter batch name" required />
          </mat-form-field>
        </div>
        <div class="col-4">
          <h6>{{ 'Batch quantity*' | translate }}</h6>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Enter batch quantity' | translate }}</mat-label>
            <input matInput formControlName="quantity" placeholder="Enter batch quantity" required />
          </mat-form-field>
        </div>
        <div class="col-4 f-height">
          <h6>{{ 'Choose quantity unit*' | translate }}</h6>
          <mat-form-field class="dropdown-primary col mat-form-field-10-px-brd-rd" appearance="outline">
            <input
              formControlName="quantity_unit"
              placeholder="{{ 'Type to search for Unit*' | translate }}"
              panelClass="dropdown-primary"
              matInput
              [matAutocomplete]="auto"
              (input)="filterItem($event)"
              #inputFilter
              #trigger="matAutocompleteTrigger"
              matAutocompletePosition="below"
              required
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn"
              class="autocomplete-primary"
              (closed)="opened = !opened"
              (opened)="opened = !opened"
              (optionSelected)="onUnitSelected($event)"
            >
              <mat-option *ngFor="let unit of quantityUnits" [value]="unit" placeholder="Choose unit">
                <ng-container *ngIf="quantityUnits.length"> {{ unit?.symbol }} ({{ unit?.name }}) </ng-container>
              </mat-option>
              <ng-container *ngIf="!quantityUnits.length">
                <mat-option disabled>
                  <p class="neutral-text">
                    {{ 'There are no active units at the moment' | translate }}
                  </p>
                </mat-option>
                <mat-option disabled>
                  <p class="highlighted-text">
                    {{ 'Please note that you can manage the units in the settings in units management.' | translate }}
                  </p>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <app-custom-icon
              class="cursor-pointer"
              (click)="openOrClosePanel($event, trigger)"
              matSuffix
              [icon_category]="'arrows'"
              [icon_name]="opened ? 'arrow-up-2' : 'arrow-down-2'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="horizontal-line mt6"></div>
      <h6>{{ 'Batch date*' | translate }}</h6>
      <div class="toggle-group">
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onToggleChange($event)">
          <div class="toggle">
            <mat-button-toggle value="date" aria-label="Date" [checked]="!togleChecked">
              <span>{{ 'Date' | translate }}</span>
            </mat-button-toggle>
          </div>
          <div class="toggle">
            <mat-button-toggle value="period" aria-label="Period" [checked]="togleChecked">
              <span>{{ 'Period' | translate }}</span>
            </mat-button-toggle>
          </div>
        </mat-button-toggle-group>
      </div>
      <div class="row">
        <div class="col-4" *ngIf="group.value === 'date'">
          <mat-form-field class="input-primary col" appearance="outline" *ngIf="group.value === 'date'">
            <mat-label>{{ 'Enter date dd/mm/yyyy' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker_date" formControlName="date_delivered" required />
            <mat-datepicker-toggle matSuffix [for]="picker_date">
              <app-custom-icon
                class="date-icon"
                icon_category="time"
                icon_name="calendar-search"
                matDatepickerToggleIcon
              ></app-custom-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row period" *ngIf="group.value === 'period'">
        <div class="col-4">
          <mat-form-field class="input-primary col" appearance="outline">
            <mat-label>{{ 'Enter start date dd/mm/yyyy' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker_start"
              formControlName="date_order"
              (dateChange)="onStartDateChange()"
            />
            <mat-datepicker-toggle matSuffix [for]="picker_start">
              <app-custom-icon
                class="date-icon"
                icon_category="time"
                icon_name="calendar-search"
                matDatepickerToggleIcon
              ></app-custom-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker_start></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="input-primary col" appearance="outline">
            <mat-label>{{ 'Enter end date dd/mm/yyyy' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker_end" formControlName="date_delivered" [min]="minEndDate" required />
            <mat-datepicker-toggle matSuffix [for]="picker_end">
              <app-custom-icon
                class="date-icon"
                icon_category="time"
                icon_name="calendar-search"
                matDatepickerToggleIcon
              ></app-custom-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker_end></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="horizontal-line mt6"></div>
      <div class="row">
        <div class="col-4">
          <h6>{{ 'Batch reference' | translate }}</h6>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Enter reference' | translate }}</mat-label>
            <input matInput formControlName="external_reference" placeholder="Enter reference" />
          </mat-form-field>
        </div>
        <div class="col-8">
          <h6>{{ 'Free text' | translate }}</h6>
          <mat-form-field class="input-primary textarea" appearance="outline">
            <mat-label>{{ 'Enter text' | translate }}</mat-label>
            <textarea
              matInput
              appTextareaAutoResize
              formControlName="free_text"
              placeholder="Enter text"
              class="custom-textarea"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <ng-template #viewBatchInfo>
    <div class="row">
      <div class="details-holder batch-chain-readonly">
        <div class="info-section">
          <p>{{ 'Batch name:' | translate }}</p>
          <p>{{ data.batch?.name }}</p>
        </div>
        <div class="info-section">
          <p>{{ 'Quantity:' | translate }}</p>
          <p>{{ data.batch?.quantity }} {{ data.batch?.quantity_unit?.symbol }}</p>
        </div>
        <div class="info-section">
          <p>{{ 'Batch date:' | translate }}</p>
          <p>
            {{ data.batch?.date_order }} {{ data.batch?.date_order && (data.batch?.date_delivered ?? '/') }}
            {{ data.batch?.date_delivered | date: 'dd/MM/yyyy' }}
          </p>
        </div>
        <div class="info-section">
          <p>{{ 'Batch reference:' | translate }}</p>
          <p>{{ data.batch?.external_reference }}</p>
        </div>
        <div class="info-section" *ngIf="data.batch?.free_text">
          <p>{{ 'Free text:' | translate }}</p>
          <p>{{ data.batch?.free_text }}</p>
        </div>
      </div>
    </div>
  </ng-template>

  <mat-dialog-actions align="end" *ngIf="!isChainComplete">
    <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
    </app-rounded-button>
    <app-rounded-button
      *ngIf="!isChainComplete"
      [color]="data.batch ? 'turquoise' : 'primary'"
      icon_category="security"
      icon_name="scan-barcode"
      [icon_right]="true"
      [button_text]="data.batch ? 'Update Batch' : 'Create Batch'"
      [size]="'md'"
      [disabled]="form && !form.valid"
      (click)="data.batch ? updateBatch() : createBatch()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
