import { Component, Input } from '@angular/core';
import { INode } from '@app/core/interface/productchain.interface';

@Component({
  selector: 'app-deactivated-step-tooltip',
  templateUrl: './deactivated-step-tooltip.component.html',
  styleUrls: ['./deactivated-step-tooltip.component.scss'],
})
export class DeactivatedStepTooltipComponent {
  @Input() node!: INode;
}
