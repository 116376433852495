import { ACCESS_TOKEN, SELECTED_LANGUAGE, Storage, USER } from '@app/core/constants';
import { ILogin, ILoginResponse, ILogout } from '../interface/login.interface';

import { AccountsService } from '@service/accounts.service';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs/internal/operators/take';
import { tap } from 'rxjs/internal/operators/tap';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../service/translation.service';

@Injectable({
  providedIn: 'root',
})
export class LoginFacade {
  emailReg =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private _accountsService: AccountsService,
    private _dataStorageService: DataStorageService,
    private _dataAuthService: DataAuthService,
    public translate: TranslateService,
    public translationService: TranslationService
  ) {}

  login$(data: ILogin): Observable<ILoginResponse> {
    const formData: FormData = new FormData();
    // if the email regex pass we add email to the form else the user has entered username and we add username to form
    if (this.emailReg.test(data.username)) {
      formData.append('email', data.username);
    } else {
      formData.append('username', data.username);
    }
    formData.append('password', data.password);
    return this._accountsService.login$(formData).pipe(
      take(1),
      tap((res: ILoginResponse) => {
        this._dataAuthService.user = res.user;
        if (data.rememberMe) {
          this._dataStorageService.set(USER, JSON.stringify(res.user), Storage.local);
          this._dataStorageService.set(ACCESS_TOKEN, res.access_token, Storage.local);
        } else {
          this._dataStorageService.set(USER, JSON.stringify(res.user), Storage.session);
          this._dataStorageService.set(ACCESS_TOKEN, res.access_token, Storage.session);
        }
      }),
      catchError((err): ObservableInput<ILoginResponse> => throwError(() => err.error))
    );
  }

  logout$(): Observable<ILogout> {
    const selectedLanguage = this._dataStorageService.get(SELECTED_LANGUAGE, Storage.local);
    return this._accountsService.logout$().pipe(
      take(1),
      tap(() => {
        // Clear storage and set user to null
        this._dataStorageService.clearStorage();
        this._dataAuthService.user = null;
        this.translationService.setSelectedLanguage(selectedLanguage);
      }),
      catchError((err): ObservableInput<ILogout> => throwError(() => err.error))
    );
  }
}
