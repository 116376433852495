import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { AddFieldsFacade } from '@app/core/facade/add-fields.facade';
import {
  ICustomField,
  ICustomFieldForListing,
  ICustomFieldOption,
  IUserCustomField,
} from '@app/core/interface/add-fields.interface';
import { ProjectsGroup } from '../../../module/projects/projects.group';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface IRawCustomField {
  field_name: string;
  field_options: Array<{
    uuid: string;
    option: string;
    is_active: boolean;
  }>;
  field_uuid: string;
  field_value: string;
}

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss'],
  providers: [AddFieldsFacade, ProjectsGroup],
})
export class CustomFieldsFormComponent implements OnInit {
  @Input() customFieldsData!: Array<IUserCustomField>;
  @Input() customFields: ICustomField[] = [];
  @Input() is_editing!: boolean;
  @Output() closeDialog = new EventEmitter<void>();
  @Input() form!: UntypedFormGroup;

  fieldsList: ICustomFieldForListing[] = [];
  isActiveFields = true;

  get customFieldsArray() {
    return <FormArray<FormGroup>>this.form.get('custom_fields');
  }

  constructor(
    private _fb: FormBuilder,
    private _group: ProjectsGroup
  ) {}

  ngOnInit(): void {
    this.fillListForListing();
  }

  /**
   * Fill list for listing fields in template
   */
  fillListForListing() {
    //////////////////////////////////// on Update ////////////////////////////////////
    if (this.is_editing) {
      // filter and sort
      const filteredFields = this.customFieldsData
        // filter only fields that are activated + deactivated with entered values
        .filter(field => field.is_active || (!field.is_active && (field.value || field.field_options.length)))
        // sort first activated and then deactivated
        .sort(a => {
          return a.is_active ? -1 : 1;
        });

      // map custom fields data for listing in html
      this.fieldsList = filteredFields.map((field: IUserCustomField) => {
        return {
          name: field.field_name,
          value: field.value,
          max_value_length: field.field_type === 'TEXT' ? 255 : field.field_type === 'TEXT AREA' ? 1000 : 0,
          field_options: field.all_options.map((option: ICustomFieldOption) => {
            return {
              ...option,
              is_active: field.field_options.some(x => x.uuid === option.uuid),
            };
          }),
          is_active: field.is_active,
        };
      });

      // Create formarray for the fields
      const transformedFields = filteredFields.map((field: IUserCustomField) => {
        const fieldForm = this._group.createFieldForm();

        if (field.field_type !== 'RADIO MULTIPLES') {
          // Set validator on field value
          fieldForm
            .get('field_value')
            ?.addValidators(field.field_type === 'TEXT' ? Validators.maxLength(255) : Validators.maxLength(1000));
        }

        fieldForm.patchValue({
          field_uuid: field.uuid,
          field_value: field.value ?? '',
          field_options: field.field_options.map(field => {
            return field.uuid;
          }),
        });

        // Disable the formgroup for a deactivated field
        if (!field.is_active) {
          fieldForm.disable();
        }
        return fieldForm;
      });

      this.form.setControl('custom_fields', this._fb.array(transformedFields));
    }
    //////////////////////////////////// on Create ////////////////////////////////////
    else {
      // Filter only the activated fields
      const filteredFields = this.customFields.filter(field => field.is_active);

      // Create list for listing fields in html
      this.fieldsList = filteredFields.map((field: ICustomField) => {
        return {
          name: field.name,
          value: field.value,
          max_value_length: field.field_type === 'TEXT' ? 255 : field.field_type === 'TEXT AREA' ? 1000 : 0,
          field_options: field.field_options.map((option: ICustomFieldOption) => {
            return { ...option, is_active: false };
          }),
          is_active: field.is_active,
        };
      });

      // Create formarray for the fields
      const transformedFields = filteredFields.map((field: ICustomField) => {
        const fieldForm = this._group.createFieldForm();

        if (field.field_type !== 'RADIO MULTIPLES') {
          fieldForm
            .get('field_value')
            ?.addValidators(field.field_type === 'TEXT' ? Validators.maxLength(255) : Validators.maxLength(1000));
        }

        fieldForm.patchValue({
          field_uuid: field.uuid,
          field_value: '',
        });

        return fieldForm;
      });

      this.form.setControl('custom_fields', this._fb.array(transformedFields));
    }

    // Check if custom fields created and active present
    this.isActiveFields =
      this.fieldsList.length > 0 &&
      this.fieldsList.some(
        field => field.is_active || (!field.is_active && (field.value || field.field_options.length))
      );
  }

  isMultiupleOption(field: ICustomFieldForListing): boolean {
    return field.field_options.length > 0;
  }

  getMultipleOptions(control: FormGroup) {
    return (<FormArray<FormGroup>>control.controls['field_options']).controls;
  }

  /**
   * Function to manage multiple option custom fields
   * @param event
   * @param index
   */
  checkboxChange(event: MatCheckboxChange, index: number) {
    const fieldForm: FormGroup = this.customFieldsArray.at(index);
    const options_array: string[] = fieldForm.get('field_options')?.value;

    if (event.checked) {
      options_array.push(event.source.value);
    } else {
      const optionIndex: number = options_array.indexOf(event.source.value);
      options_array.splice(optionIndex, 1);
    }

    fieldForm.get('field_options')?.markAsDirty();
  }

  checkLengthValidity(index: number): boolean {
    const fieldValueControl = (this.form.controls['custom_fields'] as FormArray).at(index).get('field_value');

    if (fieldValueControl?.hasError('maxlength')) return true;
    else return false;
  }
}
