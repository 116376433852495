<div class="modal-wrapper d-flex flex-column align-items-center">
  <div class="content d-flex align-items-center">
    <div class="circle d-flex align-items-center justify-content-center">
      <app-custom-icon icon_category="essential" icon_name="tick-circle"></app-custom-icon>
    </div>

    <h6 class="m-0">
      <strong>{{ data.text }}</strong>
    </h6>
  </div>

  <app-rounded-button color="secondary" [button_text]="'Ok. Got it' | translate" size="sm" (click)="dialogRef.close()">
  </app-rounded-button>
</div>
