import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { IProductChainUpdate } from '@app/core/interface/products.interface';
import { IProductChainStep } from '@app/core/interface/steps.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supply-chain-view-list',
  templateUrl: './supply-chain-view-list.component.html',
  styleUrls: ['./supply-chain-view-list.component.scss'],
  providers: [ProductChainFacade],
})
export class SupplyChainViewListComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<IProductChainStep>();
  toggleCo2 = false;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns = [
    'name',
    'supplier_name',
    'supplier_site',
    'supplier_batch_number',
    'component_name',
    'step_phase',
    // 'co2_impact',
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProductChainUpdate,
    private _facade: ProductChainFacade,
    private _snackbarService: SnackbarService
  ) {}
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void {
    this._facade.getProductChain$(this.data.productChain.uuid).subscribe({
      next: this.onGetSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  onGetSuccess(res: IProductChainResult) {
    this.dataSource.data = res.steps;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
