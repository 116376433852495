<div
  #nodeEl
  class="node"
  appChainNodeBorder
  [phaseColor]="node.type_step?.color"
  id="{{ node.uuid }}"
  class="{{ node.uuid }}"
  [style.top.px]="node.position_x || 20"
  [style.left.px]="node.position_y || 20"
  [attr.data-name]="node.name"
  [attr.data-desc]="node.description"
  [attr.data-supplier]="node.supplier?.uuid"
  [attr.data-phase]="node.type_step?.uuid"
  [attr.data-ingredient]="node.ingredient?.uuid"
  [attr.data-manufactories]="node.manufactories?.uuid"
  [attr.data-product_chain]="node.product_chain"
  [attr.data-type_step]="node.type_step?.uuid"
  (dblclick)="!batchSetup && stepClick(node, false)"
  (mousedown)="!batchSetup && stepSingleClick($event, node.uuid)"
  (mouseup)="mouseLeave()"
  [ngClass]="{ inactive: !node.is_active && batchSetup }"
  appTooltip
  [tooltipContent]="tooltipTemplate"
  [position]="TooltipPosition.ABOVE"
  [tooltipDisabled]="node.is_active || !batchSetup"
>
  <h6>{{ node.name }}</h6>
  <div
    class="no-supplier"
    *ngIf="!node.supplier && !batchSetup && !node.isChainComplete"
    [matMenuTriggerFor]="menu"
    (click)="getSuppliers()"
  >
    <p>{{ 'Add Supplier' | translate }}</p>
    <app-custom-icon [icon_name]="'fast-truck'" [icon_category]="'product'"></app-custom-icon>
  </div>
  <div class="supplier" *ngIf="node.supplier">
    <h5>Supplier:</h5>
    <p>{{ node.supplier.name }}</p>
  </div>
  <div class="supplier-site" *ngIf="node.supplier ? node.manufactories : ''">
    <h5>Supplier Site:</h5>
    <p
      [ngClass]="{ 'main-location': node.manufactories.is_main_location }"
      matTooltip="{{ node.manufactories.is_main_location ? 'main site' : '' }}"
      matTooltipClass="custom-tooltip-center"
      [matTooltipPosition]="'above'"
    >
      {{ node.manufactories.name ? node.manufactories.name + ',' : '' }}
      {{ node.manufactories.city ? node.manufactories.city + ',' : '' }} {{ node.manufactories.country }}
    </p>
  </div>

  <div class="supplier-status">
    <app-custom-icon
      [icon_category]="'essential'"
      [icon_name]="supplierStatusIcon"
      [ngClass]="supplierStatusIcon"
      matTooltip="{{ node.supplier_status }}"
      matTooltipClass="custom-tooltip-center"
      [matTooltipPosition]="'above'"
    ></app-custom-icon>
    <app-custom-icon
      *ngIf="verifySupplier"
      [icon_category]="'essential'"
      [icon_name]="'danger'"
      [ngClass]="{ 'danger-batch': batchSetup, 'danger-supply-chain': !batchSetup }"
      [matTooltip]="verifySupplierText"
      matTooltipClass="custom-tooltip-center"
      [matTooltipPosition]="'above'"
    ></app-custom-icon>
  </div>
  <app-custom-icon
    *ngIf="!batchSetup && !node.isChainComplete"
    class="actions"
    [icon_category]="'settings'"
    [icon_name]="'more'"
    [matMenuTriggerFor]="menu3"
    matTooltip="View All Actions"
    matTooltipClass="custom-tooltip-center"
    [matTooltipPosition]="'above'"
  ></app-custom-icon>

  <mat-menu
    class="chain-action mat-menu-node-action"
    #menu3="matMenu"
    (click)="$event.stopPropagation()"
    #actionMenu="matMenu"
    xPosition="before"
  >
    <app-action-list
      [actionLists]="actionListSupplyChain"
      (operationClicked)="operationClickedSupplyChain($event)"
    ></app-action-list>
  </mat-menu>

  <app-custom-icon
    [ngClass]="{ 'batch-icon': batchSetup }"
    [icon_category]="'security'"
    [icon_name]="'eye'"
    (click)="stepClick(node, batchSetup)"
    *ngIf="node.isChainComplete"
  ></app-custom-icon>
  <app-custom-icon
    class="lock"
    [icon_category]="'security'"
    [icon_name]="'lock'"
    *ngIf="batchSetup && node.status === 'COMPLETED'"
  ></app-custom-icon>
  <app-custom-icon
    *ngIf="batchSetup && node.is_active && !node.isChainComplete"
    class="actions"
    [icon_category]="'settings'"
    [icon_name]="'more'"
    [matMenuTriggerFor]="menu2"
    matTooltip="View All Actions"
    matTooltipClass="custom-tooltip-center"
    [matTooltipPosition]="'above'"
  ></app-custom-icon>

  <mat-menu
    class="chain-action mat-menu-node-action"
    #menu2="matMenu"
    (click)="$event.stopPropagation()"
    #actionMenu="matMenu"
    xPosition="before"
  >
    <app-action-list [actionLists]="actionList" (operationClicked)="operationClicked($event)"></app-action-list>
  </mat-menu>

  <div class="cluster" [style.background]="node.group?.color"></div>

  <mat-menu #menu="matMenu" (click)="$event.stopPropagation()">
    <app-add-supplier [node]="node" [supplierList]="supplierList"></app-add-supplier>
  </mat-menu>
  <div *ngIf="!node.is_active && batchSetup" class="overlay">
    <app-custom-icon
      class="actions"
      [icon_category]="'settings'"
      [icon_name]="'more'"
      [matMenuTriggerFor]="menu4"
      matTooltip="View All Actions"
      matTooltipClass="custom-tooltip-center"
      [matTooltipPosition]="'above'"
    ></app-custom-icon>
    <mat-menu
      class="chain-action mat-menu-node-action"
      #menu4="matMenu"
      (click)="$event.stopPropagation()"
      #actionMenu="matMenu"
      xPosition="before"
    >
      <app-action-list
        [actionLists]="actionListReactiveStep"
        (operationClicked)="operationClickedReactiveStep($event)"
      ></app-action-list>
    </mat-menu>
  </div>
</div>
<ng-template #tooltipTemplate>
  <app-deactivated-step-tooltip [node]="node"></app-deactivated-step-tooltip>
</ng-template>
