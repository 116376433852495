import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-view-rich-text',
  template: ` <div [ngClass]="inheritStyles ? 'read-only-rte-inh' : 'read-only-rte'" [innerHTML]="content"></div> `,
  styleUrls: ['./view-rich-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewRichTextComponent {
  @Input() content = '';
  @Input() inheritStyles = false;
}
