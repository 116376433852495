<div class="wrapper d-flex align-items-center flex-column justify-content-between">
  <div class="'d-flex flex-wrap align-items-center justify-content-center flex-grow-1">
    <div class="info-box">
      <div class="logo">
        <img src="../../../../assets/images/symbol-blue-logo.svg" />
      </div>

      <div class="text-holder">
        <p class="m-0">{{ dataObject.infoText | translate }}</p>
      </div>
    </div>

    <div class="input-wrap d-flex flex-column">
      <p class="m-0">{{ dataObject.label | translate }}</p>
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ dataObject.placeholder | translate }}</mat-label>
        <input matInput [formControl]="reasonInput" />
        <mat-error> </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="buttons-wrap d-flex justify-content-center align-items-center">
    <app-rounded-button
      class="close-btn"
      color="tetriary-link"
      [button_text]="'supply-chain-step.Cancel' | translate"
      [size]="'md'"
      mat-dialog-close
    >
    </app-rounded-button>

    <app-rounded-button
      class="mark-btn"
      color="primary"
      [button_text]="dataObject.btnText | translate"
      [size]="'md'"
      [disabled]="reasonInput.invalid"
      (click)="onProceed()"
    >
    </app-rounded-button>
  </div>
</div>
