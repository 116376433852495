import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-data-tooltip',
  templateUrl: './personal-data-tooltip.component.html',
  styleUrl: './personal-data-tooltip.component.scss',
})
export class PersonalDataTooltipComponent {
  showTooltip = false;
}
