import {
  IDeleteStepLocation,
  IDeleteSupplyChainStep,
  IInviteSupplierStep,
  IProductChainStep,
  IStepLocation,
  TypeStepResponse,
} from '../interface/steps.interface';

import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import { IMedia } from '../interface/medias.interface';

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  public refetchStepDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refetchStepDetails$ = this.refetchStepDetails.asObservable();
  private apiUrl = environment.api;
  public matchingSupplierVerification = new Subject<boolean>();
  public notMatchingSupplierSubject = new Subject<boolean>();

  constructor(private _http: HttpClient) {}

  createStep$(data: FormData): Observable<IProductChainStep> {
    return this._http.post<IProductChainStep>(`${this.apiUrl}/steps/`, data);
  }

  getStep$(uuid: string, isBatchStep?: boolean): Observable<IProductChainStep> {
    const prefix = isBatchStep ? 'batchsteps' : 'steps';
    return this._http.get<IProductChainStep>(`${this.apiUrl}/${prefix}/${uuid}/`);
  }

  updateStep$(data: FormData, uuid: string): Observable<IProductChainStep> {
    return this._http.patch<IProductChainStep>(`${this.apiUrl}/steps/${uuid}/`, data);
  }

  updateBatchStep$(data: FormData, uuid: string): Observable<IProductChainStep> {
    return this._http.patch<IProductChainStep>(`${this.apiUrl}/batchsteps/${uuid}/`, data);
  }

  deleteStep$(uuid: string): Observable<IDeleteSupplyChainStep> {
    return this._http.delete<IDeleteSupplyChainStep>(`${this.apiUrl}/steps/${uuid}/`);
  }

  getStepTypes$(): Observable<TypeStepResponse> {
    return this._http.get<TypeStepResponse>(`${this.apiUrl}/steps/types/`);
  }

  addStepLocation$(data: FormData): Observable<IStepLocation> {
    return this._http.post<IStepLocation>(`${this.apiUrl}/steps/locations/`, data);
  }

  deleteStepLocation$(uuid: string): Observable<IDeleteStepLocation> {
    return this._http.delete<IDeleteStepLocation>(`${this.apiUrl}/steps/locations/${uuid}/`);
  }

  sendEmailToSupplier$(uuid: string): Observable<IInviteSupplierStep> {
    return this._http.post<IInviteSupplierStep>(`${this.apiUrl}/steps/${uuid}/invite`, {});
  }

  matchingSupplier(stepUuid: string, data: FormData): Observable<IProductChainStep> {
    return this._http.post<IProductChainStep>(`${this.apiUrl}/steps/${stepUuid}/suppliermatching/`, data);
  }

  notMatchingSupplier(uuid: string): Observable<IProductChainStep> {
    return this._http.post<IProductChainStep>(`${this.apiUrl}/steps/${uuid}/notmatching/`, { uuid: '' });
  }

  addBatchStepProof(batchUuid: string, data: FormData): Observable<IMedia> {
    return this._http.post<IMedia>(`${this.apiUrl}/batchsteps/${batchUuid}/proofs/`, data);
  }

  removeBatchStepProof(batchUuid: string, mediaUuid: string): Observable<void> {
    return this._http.delete<void>(`${this.apiUrl}/batchsteps/${batchUuid}/proofs/${mediaUuid}`);
  }

  activateDeactivateBatchStep(batchUuid: string, data: FormData): Observable<IProductChainStep> {
    return this._http.patch<IProductChainStep>(`${this.apiUrl}/batchsteps/${batchUuid}/active/`, data);
  }
}
