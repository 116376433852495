<div class="mail-sent"></div>
<div class="row">
  <div class="offset-1 col-1">
    <div class="logo"></div>
  </div>
  <div class="col-9">
    <h4 class="succes-head">{{ 'Your invitation was successfully sent!' | translate }}</h4>
    <!-- <p class="succes-sent">
      {{
        'Please note that from now on, you create tasks for the invited user, that will help with your mission.'
          | translate
      }}
    </p> -->
  </div>
</div>
