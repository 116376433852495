import { IMediaContentType, IMediaResponse, IMediaScreens } from '../interface/medias.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesMediasLinksService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  createMedia$(
    data: FormData,
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens
  ): Observable<IMediaResponse> {
    const url = type === 'publicationsteps' ? `steps/${typeId}/${screen}` : `${type}/${typeId}/${screen}`;
    return this._http.post<IMediaResponse>(`${this.apiUrl}/${url}/`, data);
  }

  deleteMedia$(
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens,
    mediaId: string
  ): Observable<IMediaResponse> {
    const url =
      type === 'publicationsteps' ? `steps/${typeId}/${screen}/${mediaId}/` : `${type}/${typeId}/${screen}/${mediaId}/`;
    return this._http.delete<IMediaResponse>(`${this.apiUrl}/${url}`);
  }

  updateMedia$(
    data: FormData,
    type: IMediaContentType,
    typeId: string,
    screen: IMediaScreens,
    mediaId: string
  ): Observable<IMediaResponse> {
    const url =
      type === 'publicationsteps' ? `steps/${typeId}/${screen}/${mediaId}/` : `${type}/${typeId}/${screen}/${mediaId}/`;
    return this._http.patch<IMediaResponse>(`${this.apiUrl}/${url}`, data);
  }

  updatePublicationStepMedia$(data: FormData, typeUuid: string, uuid: string): Observable<IMediaResponse> {
    return this._http.patch<IMediaResponse>(`${this.apiUrl}/steps/${typeUuid}/medias/${uuid}`, data);
  }
}
