<form *ngIf="isActiveFields; else noActiveFields" [formGroup]="form">
  <div formArrayName="custom_fields">
    <ng-container *ngFor="let field of fieldsList; let index = index">
      <div
        [formGroupName]="index"
        class="field-item d-flex"
        [ngClass]="!field.max_value_length ? 'align-items-start' : 'align-items-center'"
      >
        <div class="fields-name-holder">
          <p class="m-0" [ngStyle]="{ opacity: field.is_active ? '1' : '0.4' }">{{ field.name | translate }}</p>
          <app-custom-icon
            class="info-icon"
            id="info-circle"
            icon_category="essential"
            icon_name="info-circle"
            [matTooltip]="
              'This field cannot be edited \n because it was deactivated. \n in order to edit it, please first \n reactivate it from the settings.'
                | translate
            "
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-center"
            matTooltipVisibility="visible"
            *ngIf="!field.is_active"
          ></app-custom-icon>
        </div>

        <div
          class="fields-content"
          [ngSwitch]="field.max_value_length"
          [ngStyle]="{ opacity: field.is_active ? '1' : '0.4' }"
        >
          <!-- Render input/textarea if field is text (field.max_value_length > 0) -->
          <ng-container *ngSwitchDefault>
            <mat-form-field
              class="input-primary"
              [ngClass]="{ textarea: field.max_value_length === 1000 }"
              appearance="outline"
              [ngSwitch]="field.max_value_length"
            >
              <mat-label>{{ 'Enter ' + field.name | translate }}</mat-label>
              <!-- Render input if simple text (field.max_value_length = 255) -->
              <input matInput formControlName="field_value" *ngSwitchDefault [readonly]="!field.is_active" />
              <!-- Render textarea if long text (field.max_value_length = 1000) -->
              <textarea
                *ngSwitchCase="1000"
                matInput
                formControlName="field_value"
                appTextareaAutoResize
                [readonly]="!field.is_active"
              ></textarea>
              <mat-error *ngIf="checkLengthValidity(index)">{{
                ('sorry the maximum number of characters is ' | translate) + field.max_value_length
              }}</mat-error>
            </mat-form-field>
          </ng-container>
          <!-- Render checkboxes if field is multiple option (field.max_value_length = 0) -->
          <div class="multiple-options d-flex flex-wrap" *ngSwitchCase="0">
            <ng-container *ngFor="let option of field.field_options">
              <mat-checkbox
                class="checkbox-primary"
                (change)="checkboxChange($event, index)"
                [value]="option.uuid"
                [checked]="option.is_active"
                [disabled]="!field.is_active || (option.is_active && field.field_options.length === 0)"
                >{{ option.option | translate }}</mat-checkbox
              >
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<ng-template #noActiveFields>
  <div class="no-custom-fields d-flex align-items-center">
    <div class="icon-circle">
      <app-custom-icon id="element-plus" [icon_category]="'grid'" [icon_name]="'element-plus'"></app-custom-icon>
    </div>
    <p class="d-flex align-items-center justify-content-center">
      {{ 'There are no active custom fields \n or there are none created yet.' | translate }}
    </p>
  </div>
</ng-template>
