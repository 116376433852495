<div *ngIf="node.is_active === false" class="inactive-tooltip d-flex flex-column">
  <p class="m-0 title">{{ 'Step deactivated by:' | translate }}</p>
  <div class="d-flex flex-column text-folder">
    <div class="d-flex line-holder">
      <app-custom-icon [icon_name]="'user'" [icon_category]="'user'"></app-custom-icon>
      <p class="m-0">
        {{
          node.active_status_by_user?.first_name
            ? node.active_status_by_user?.first_name + ' ' + node.active_status_by_user?.last_name
            : node.active_status_by_user?.username
        }}
      </p>
    </div>
    <div class="d-flex line-holder">
      <app-custom-icon [icon_name]="'sms'" [icon_category]="'messages'"></app-custom-icon>
      <p class="m-0">{{ node.active_status_by_user?.email }}</p>
    </div>
    <div *ngIf="node.active_status_by_ui === 1" class="d-flex line-holder">
      <app-custom-icon [icon_name]="'truck'" [icon_category]="'product'"></app-custom-icon>
      <p class="m-0">{{ node.active_status_by_user?.supplier }}</p>
    </div>
  </div>
</div>
