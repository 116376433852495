import {
  IDeleteStepLocation,
  IDeleteSupplyChainStep,
  IInviteSupplierStep,
  IProductChainStep,
  TypeStepResponse,
} from '../interface/steps.interface';
import { switchMap, take } from 'rxjs';

import { INode } from './../interface/productchain.interface';
import { IStepLocation } from '@app/core/interface/steps.interface';
import { Injectable } from '@angular/core';
import { NodeService } from './../service/node.service';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { StepsService } from '../service/steps.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { throwError } from 'rxjs/internal/observable/throwError';
import { UntypedFormGroup } from '@angular/forms';
import { IMedia, IProofRequest } from '../interface/medias.interface';

@Injectable()
export class StepsFacade {
  constructor(private _stepService: StepsService, private _nodeService: NodeService) {}

  createStep$(data: Partial<IProductChainStep>): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    return this._stepService.createStep$(formData).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  updateStep$(data: Partial<IProductChainStep>, uuid: string): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    return this._stepService.updateStep$(formData, uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  updateBatchStep$(data: Partial<IProductChainStep>, uuid: string): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    return this._stepService.updateBatchStep$(formData, uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  deleteStep$(uuid: string): Observable<IDeleteSupplyChainStep> {
    return this._stepService.deleteStep$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeleteSupplyChainStep): ObservableInput<IDeleteSupplyChainStep> => throwError(() => error)
      )
    );
  }

  getStep$(uuid: string, isBatchStep?: boolean): Observable<IProductChainStep> {
    return this._stepService.getStep$(uuid, isBatchStep).pipe(
      take(1),
      catchError((err): ObservableInput<IProductChainStep> => throwError(() => err.error))
    );
  }

  selectAndGetStep$(): Observable<IProductChainStep | INode> {
    return this._nodeService.selectedStepData$.pipe(
      take(1),
      switchMap((res: INode | null): Observable<INode> => {
        const nodeUuid = res?.uuid ? res.uuid : res?.id;
        return this.getStep$(nodeUuid ? nodeUuid : '').pipe(
          take(1),
          catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
        );
      }),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  getStepTypes$(): Observable<TypeStepResponse> {
    return this._stepService.getStepTypes$().pipe(
      take(1),
      catchError((err): ObservableInput<TypeStepResponse> => throwError(() => err.error))
    );
  }

  addStepLocation$(data: Partial<IStepLocation>): Observable<IStepLocation> {
    const formData = generateFormData(data);
    return this._stepService.addStepLocation$(formData).pipe(
      take(1),
      catchError(({ error }: IStepLocation): ObservableInput<IStepLocation> => throwError(() => error))
    );
  }

  deleteStepLocation$(uuid: string): Observable<IDeleteStepLocation> {
    return this._stepService.deleteStepLocation$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteStepLocation): ObservableInput<IDeleteStepLocation> => throwError(() => error))
    );
  }

  sendEmailToSupplier$(uuid: string): Observable<IInviteSupplierStep> {
    return this._stepService.sendEmailToSupplier$(uuid).pipe(
      take(1),
      catchError(({ error }: IInviteSupplierStep): ObservableInput<IInviteSupplierStep> => throwError(() => error))
    );
  }

  matchingSupplier(stepUuid: string, data: UntypedFormGroup): Observable<IProductChainStep> {
    const formData = generateFormData(data);
    return this._stepService.matchingSupplier(stepUuid, formData).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  notMatchingSupplier(uuid: string): Observable<IProductChainStep> {
    return this._stepService.notMatchingSupplier(uuid).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }

  addBatchStepProof(batchUuid: string, data: IProofRequest): Observable<IMedia> {
    const formData = generateFormData(data);
    return this._stepService.addBatchStepProof(batchUuid, formData).pipe(
      take(1),
      catchError(({ error }: IMedia): ObservableInput<IMedia> => throwError(() => error))
    );
  }

  removeBatchStepProof(batchUuid: string, mediaUuid: string): Observable<void> {
    return this._stepService.removeBatchStepProof(batchUuid, mediaUuid).pipe(
      take(1),
      catchError(({ error }): ObservableInput<void> => throwError(() => error))
    );
  }

  activateDeactivateBatchStep(batchUuid: string, activate: boolean): Observable<IProductChainStep> {
    const formData: FormData = new FormData();
    formData.append('is_active', `${activate}`);
    return this._stepService.activateDeactivateBatchStep(batchUuid, formData).pipe(
      take(1),
      catchError(({ error }: IProductChainStep): ObservableInput<IProductChainStep> => throwError(() => error))
    );
  }
}
