/* eslint-disable @typescript-eslint/no-explicit-any */
import { MatTableDataSource } from '@angular/material/table';

export function applyFilter(
  event: Event,
  cardDataSource?: MatTableDataSource<any>,
  tableDataSource?: MatTableDataSource<any>
): void {
  const filterValue = (event.target as HTMLInputElement).value;
  if (cardDataSource) {
    cardDataSource.filter = filterValue.trim().toLowerCase();
    cardDataSource.paginator && cardDataSource.paginator.firstPage();
  }
  if (tableDataSource) {
    tableDataSource.filter = filterValue.trim().toLowerCase();
    tableDataSource.paginator && tableDataSource.paginator.firstPage();
  }
}

// Custom filter predicate to handle nested data structures
function customFilterPredicate(data: any, filter: string): boolean {
  // Convert the filter string to lowercase and trim any whitespace
  const searchTerm = filter.trim().toLowerCase();
  // Function to search within nested objects using a stack-based approach
  function search(obj: any): boolean {
    const stack = [obj]; // Initialize a stack with the root object
    const seen = new Set(); // Set to keep track of visited objects to avoid circular references

    // Process objects in the stack
    while (stack.length > 0) {
      const current = stack.pop(); // Pop the top object from the stack

      // Skip processing if the object has already been seen
      if (seen.has(current)) {
        continue;
      }

      seen.add(current); // Mark the current object as seen

      // Check if the current object is a string and contains the search term
      if (typeof current === 'string') {
        if (current.toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
      // Check if the current object is a number and contains the search term
      else if (typeof current === 'number') {
        if (current.toString().includes(searchTerm)) {
          return true;
        }
      }
      // If the current object is an array, push all its elements onto the stack
      else if (Array.isArray(current)) {
        stack.push(...current);
      }
      // If the current object is a non-null object, push all its values onto the stack
      else if (current !== null && typeof current === 'object') {
        stack.push(...Object.values(current));
      }
    }

    // Return false if the search term is not found in any part of the object
    return false;
  }

  // Apply the search function to the data and log the result
  const result = search(data);
  return result;
}

// Function to apply the filter to the data sources
export function applyFullDataFilter(
  event: Event,
  cardDataSource?: MatTableDataSource<any>,
  tableDataSource?: MatTableDataSource<any>
): void {
  const filterValue = (event.target as HTMLInputElement).value; // Get the filter value from the event
  // Apply the custom filter predicate to the card data source if it exists
  if (cardDataSource) {
    cardDataSource.filterPredicate = customFilterPredicate;
    cardDataSource.filter = filterValue; // Set the filter value
    cardDataSource.paginator && cardDataSource.paginator.firstPage(); // Reset to the first page if pagination is enabled
  }

  // Apply the custom filter predicate to the table data source if it exists
  if (tableDataSource) {
    tableDataSource.filterPredicate = customFilterPredicate;
    tableDataSource.filter = filterValue; // Set the filter value
    tableDataSource.paginator && tableDataSource.paginator.firstPage(); // Reset to the first page if pagination is enabled
  }
}
