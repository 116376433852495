<div class="data-secured" [ngClass]="{ 'column-direction': isColumnDirection }">
  <div class="d-flex justify-content-center align-items-center">
    <p>{{ 'need-help.DATA SECURED' | translate }}</p>
    <app-custom-icon [icon_category]="'security'" [icon_name]="'shield-tick'"></app-custom-icon>
  </div>
  <span
    class="policy-link"
    (click)="navigationService.openTermAndCondition('/terms-and-conditions/Data protection policy')"
    mat-dialog-close
    >{{ 'need-help.Data protection policy' | translate }}</span
  >
</div>
