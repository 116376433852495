import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import {
  ISuppCompanyResponse,
  ISuppManufactoryResponse,
  ISuppChainStepResponse,
  ISuppDirectSupplier,
  ISuppChainStep,
  IBatchChainStep,
  IMarkBatchStepAsCompleteResponse,
  IStepBatchQuanityUnitResponse,
  ISuppStepBatchDirectSupplier,
  ISuppDashboardStepsCountResponse,
  ISuppBatchDirectSuppResponse,
  ISuppStepDirectSuppResponse,
} from '../interface/supp.interface';
import { IManufactory } from '../interface/manufactories.interface';
import { IMedia } from '../interface/medias.interface';

@Injectable({
  providedIn: 'root',
})
export class SuppSupplierService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCompayInfo$(): Observable<ISuppCompanyResponse> {
    return this._http.get<ISuppCompanyResponse>(`${this.apiUrl}/supp/supplier`);
  }

  updateCompayInfo$(data: FormData): Observable<ISuppCompanyResponse> {
    return this._http.patch<ISuppCompanyResponse>(`${this.apiUrl}/supp/supplier`, data);
  }

  getSupplierSites$(): Observable<ISuppManufactoryResponse> {
    return this._http.get<ISuppManufactoryResponse>(`${this.apiUrl}/supp/manufactories/`);
  }

  createSupplierSite$(data: FormData): Observable<IManufactory> {
    return this._http.post<IManufactory>(`${this.apiUrl}/supp/manufactories/`, data);
  }

  updateSupplierSite$(data: FormData, uuid: string): Observable<IManufactory> {
    return this._http.put<IManufactory>(`${this.apiUrl}/supp/manufactories/${uuid}`, data);
  }

  deleteSupplierSite$(uuid: string): Observable<IManufactory[]> {
    return this._http.delete<IManufactory[]>(`${this.apiUrl}/supp/manufactories/${uuid}`);
  }

  getSupplierSteps$(limit?: number): Observable<ISuppChainStepResponse> {
    if (limit) {
      const params = new HttpParams().set('limit', limit);
      return this._http.get<ISuppChainStepResponse>(`${this.apiUrl}/supp/steps/`, { params: params });
    }
    return this._http.get<ISuppChainStepResponse>(`${this.apiUrl}/supp/steps/`);
  }

  getSupplierStep$(uuid: string): Observable<ISuppChainStep> {
    return this._http.get<ISuppChainStep>(`${this.apiUrl}/supp/steps/${uuid}`);
  }

  getSuppDashboardStepsCount$(): Observable<ISuppDashboardStepsCountResponse> {
    return this._http.get<ISuppDashboardStepsCountResponse>(`${this.apiUrl}/supp/dashboard/`);
  }

  updateSupplierStep$(uuid: string, data: FormData): Observable<ISuppChainStep> {
    return this._http.patch<ISuppChainStep>(`${this.apiUrl}/supp/steps/${uuid}/`, data);
  }

  getSupplierStepBatch$(uuid: string): Observable<IBatchChainStep> {
    return this._http.get<IBatchChainStep>(`${this.apiUrl}/supp/batchsteps/${uuid}`);
  }

  updateSupplierStepBatch$(uuid: string, data: FormData): Observable<IBatchChainStep> {
    return this._http.patch<IBatchChainStep>(`${this.apiUrl}/supp/batchsteps/${uuid}/`, data);
  }

  disapproveStepBatch$(uuid: string, data: FormData): Observable<IBatchChainStep> {
    return this._http.put<IBatchChainStep>(`${this.apiUrl}/supp/batchsteps/${uuid}/`, data);
  }

  markBatchStepAsComplete$(uuid: string, data: FormData): Observable<IMarkBatchStepAsCompleteResponse> {
    return this._http.put<IMarkBatchStepAsCompleteResponse>(
      `${this.apiUrl}/supp/batchsteps/${uuid}/markstepcompleted/`,
      data
    );
  }

  ////////////////////// Direct suppliers endpoints /////////////////////
  getDirectSuppliers$(step_uuid: string): Observable<ISuppStepDirectSuppResponse> {
    return this._http.get<ISuppStepDirectSuppResponse>(`${this.apiUrl}/supp/steps/${step_uuid}/directsuppliers/`);
    // return this._http.get<ISuppDirectSupplierResponse>(`${this.apiUrl}/supp/steps/${step_uuid}/suppliers/`);
  }

  getStepBatchDirectSuppliers$(step_uuid: string): Observable<ISuppBatchDirectSuppResponse> {
    return this._http.get<ISuppBatchDirectSuppResponse>(`${this.apiUrl}/supp/batchsteps/${step_uuid}/directsuppliers/`);
  }

  addDirectSupplier$(data: FormData, step_uuid: string): Observable<ISuppDirectSupplier> {
    return this._http.post<ISuppDirectSupplier>(`${this.apiUrl}/supp/steps/${step_uuid}/suppliers/`, data);
  }

  editDirectSupplier$(data: FormData, step_uuid: string, uuid: string): Observable<ISuppDirectSupplier> {
    return this._http.patch<ISuppDirectSupplier>(`${this.apiUrl}/supp/steps/${step_uuid}/suppliers/${uuid}/`, data);
  }

  deleteDirectSupplier$(step_uuid: string, uuid: string): Observable<ISuppDirectSupplier> {
    return this._http.delete<ISuppDirectSupplier>(`${this.apiUrl}/supp/steps/${step_uuid}/suppliers/${uuid}/`);
  }

  getStepBatchQuantityUnit$(): Observable<IStepBatchQuanityUnitResponse> {
    return this._http.get<IStepBatchQuanityUnitResponse>(`${this.apiUrl}/supp/quantityunits/
    `);
  }

  getStepBatchDirectSupplier$(uuid: string): Observable<ISuppStepBatchDirectSupplier> {
    return this._http.get<ISuppStepBatchDirectSupplier>(`${this.apiUrl}/supp/directsuppliers/${uuid}/`);
  }

  updateStepBatchDirectSupplier$(data: FormData, uuid: string): Observable<ISuppStepBatchDirectSupplier> {
    return this._http.patch<ISuppStepBatchDirectSupplier>(`${this.apiUrl}/supp/directsuppliers/${uuid}/`, data);
  }

  deactivateDirectSupplier$(uuid: string): Observable<string> {
    return this._http.put<string>(`${this.apiUrl}/supp/directsuppliers/${uuid}/deactivate/`, {});
  }

  //Api for uploading proof document

  addDirectSupplierProof$(proofs: FormData, uuid: string): Observable<IMedia> {
    return this._http.post<IMedia>(`${this.apiUrl}/supp/directsuppliers/${uuid}/proofs/`, proofs);
  }

  deleteDirectSupplierProof$(uuid: string): Observable<IMedia> {
    return this._http.delete<IMedia>(`${this.apiUrl}/supp/directsuppliers/proofs/${uuid}/`);
  }

  getStepBatchDirectSupplierProofs$(uuid: string): Observable<IMedia[]> {
    return this._http.get<IMedia[]>(`${this.apiUrl}/supp/directsuppliers/${uuid}/proofs/`);
  }
}
