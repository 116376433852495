<div class="text-center d-flex justify-content-center flex-column align-items-center">
  <div class="box" *ngIf="brands.length">
    <img class="logo" src="../../../../assets/images/respect-saas-logo.svg" />
    <h1>{{ 'supplier-menu.Choose a Brand' | translate }}</h1>
    <h3>
      {{
        'supplier-menu.Your account is associated with multiple brands, please choose the Brand that you want to proceed with'
          | translate
      }}
    </h3>

    <div class="brand" *ngFor="let brand of brands">
      <div class="brand-info">
        <img src="{{ brand?.logo ? brand.logo : '../../../../assets/images/brand-avatar.png' }}" />
        <p>{{ brand.name }}</p>
      </div>
      <div class="continue" (click)="switchBrand(brand)">
        <p>{{ 'supplier-menu.Continue' | translate }}</p>
        <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-long'"></app-custom-icon>
      </div>
    </div>
  </div>

  <app-rounded-button
    *ngIf="!supplierAccount"
    color="secondary"
    [button_text]="'Go Back'"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-left-long'"
    [size]="'md'"
    [icon_left]="true"
    (click)="goBack()"
  >
  </app-rounded-button>
</div>
