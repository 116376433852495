import { NotificationType, snackBarDuration } from '../constants';

import { CustomSnackbarComponent } from '@app/shared/components/custom-snackbar/custom-snackbar.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  openTypeSnackbar(text: string, type: NotificationType): void {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      panelClass: ['custom-snackbar', type],
      data: { type, text },
      duration: snackBarDuration,
    });
  }
}
