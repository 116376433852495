import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appChainNodeBorder]',
})
export class ChainNodeBorderDirective {
  @Input() phaseColor: string | undefined;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['phaseColor']) {
      this.el.nativeElement.style.border = `2.5px solid ${this.phaseColor}`;
    }
  }
}
