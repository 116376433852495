import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss'],
  host: {
    // fix for click behaviour on the host when button is disabled
    '[style.pointer-events]': "disabled ? 'none' : 'all'",
  },
})
export class RoundedButtonComponent {
  @Input() color = '';
  @Input() icon_name = '';
  @Input() icon_category = '';
  @Input() button_text = '';
  @Input() icon_left: boolean | undefined;
  @Input() icon_right: boolean | undefined;
  @Input() disabled? = false;
  @Input() size = 'lg';
}
