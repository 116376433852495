import {
  ActionList,
  ActionListOperations,
  Settings,
  SettingsItem,
  SettingsRow,
} from '@interface/action-list.interface';

export class ActionListHelper {
  public static readonly chainListActionList: ActionList[] = [
    {
      keyword: 'Open supply chain',
      icon_name: 'eye',
      icon_category: 'security',
      operation: ActionListOperations.ViewProductChain,
      id: 'eye',
    },
    {
      keyword: 'Duplicate Chain',
      icon_name: 'copy',
      icon_category: 'grid',
      operation: ActionListOperations.DuplicateChain,
      id: 'copy',
    },
    {
      keyword: 'Create batch',
      icon_name: 'scan-barcode',
      icon_category: 'security',
      operation: ActionListOperations.CreateBatch,
      id: 'scan-barcode',
    },
    // Temporary remove the Publish Chain from action menu.
    // {
    //   keyword: 'Publish chain',
    //   icon_name: 'document-forward',
    //   icon_category: 'files',
    //   operation: ActionListOperations.PublishChain,
    //   id: 'document-forward',
    // },
    {
      keyword: 'Display chain steps in list',
      icon_category: 'files',
      icon_name: 'table',
      operation: ActionListOperations.OpenChainList,
      id: 'table',
    },
    {
      keyword: 'Deactivate chain',
      icon_name: 'deactivate-red',
      icon_category: 'essential',
      operation: ActionListOperations.DeactivateChain,
      id: 'deactivate-red',
    },
    // {
    //   keyword: 'Edit chain data',
    //   icon_name: 'edit',
    //   icon_category: 'files',
    //   operation: ActionListOperations.EditProductChain,
    //   id: 'edit',
    // },
  ];
  public static readonly batchListActionList: ActionList[] = [
    {
      keyword: 'Open batch',
      icon_name: 'eye',
      icon_category: 'security',
      operation: ActionListOperations.ViewProductBatch,
      id: 'eye',
    },
    // Temporary remove Publich Batch from Action menu.
    // {
    //   keyword: 'Publish batch',
    //   icon_name: 'document-forward',
    //   icon_category: 'files',
    //   operation: ActionListOperations.PublishOnRespectCode,
    //   id: 'document-forward',
    // },
    {
      keyword: 'Display batch steps in list',
      icon_name: 'table',
      icon_category: 'files',
      operation: ActionListOperations.ViewRespectCycle,
      id: 'table',
    },
    {
      keyword: 'Deactivate batch',
      icon_name: 'deactivate-red',
      icon_category: 'essential',
      operation: ActionListOperations.DeactivateBatch,
      id: 'deactivate-red',
    },
    /*{
      keyword: 'View respect-cycle',
      icon_name: 'format-circle',
      icon_category: 'grid',
      operation: ActionListOperations.ViewRespectCycle,
      id: 'format-circle',
    },*/
  ];

  public static readonly settingItemsList: SettingsItem[] = [
    {
      icon_category: 'user',
      routerLink: 'user-settings',
      icon_name: 'user-edit',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Users Management',
      id: 1,
      show: true,
      parent: SettingsRow.Administrative,
    },
    // Temporary remove the Billing Management from All settings.
    // {
    //   icon_category: 'finance',
    //   routerLink: 'billing-management',
    //   icon_name: 'card-edit',
    //   arrow_icon_name: 'arrow-right-long',
    //   arrow_icon_category: 'arrows',
    //   keyword: 'Billing Management',
    //   id: 2,
    //   show: true,
    //   parent: SettingsRow.Administrative,
    // },
    {
      icon_category: 'user',
      routerLink: 'users-access',
      icon_name: 'user-tick',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Users Access',
      id: 99,
      show: false,
      parent: SettingsRow.Administrative,
    },
    {
      icon_category: 'support',
      routerLink: '/csm/assign-brands',
      icon_name: 'message-question',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Assign Brands to CSM',
      id: 4,
      show: true,
      parent: SettingsRow.Administrative,
    },
    {
      routerLink: 'email-templates',
      icon_category: 'messages',
      icon_name: 'sms-tracking',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Email Templates',
      id: 4,
      show: true,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'brand-management',
      icon_category: 'buildings',
      icon_name: 'buildings',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Brand Management',
      id: 1,
      show: true,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'address-management',
      icon_category: 'location',
      icon_name: 'map',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Address Management',
      id: 3,
      show: true,
      parent: SettingsRow.Administrative,
    },
    {
      routerLink: '',
      icon_category: 'settings',
      icon_name: 'setting-3',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Address Management',
      id: 99,
      show: false,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'add-fields',
      icon_category: 'grid',
      icon_name: 'element-plus',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Add Custom Fields',
      id: 3,
      show: true,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'unit-management',
      icon_category: 'essential',
      icon_name: 'unit',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Unit Management',
      id: 2,
      show: true,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'features-management',
      icon_category: 'essential',
      icon_name: 'features',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Features Management',
      id: 5,
      show: true,
      parent: SettingsRow.Brand,
    },
    {
      routerLink: 'languages',
      icon_category: 'location',
      icon_name: 'global-edit',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Manage Languages',
      id: 1,
      show: false,
      parent: SettingsRow.Languages,
    },
    {
      routerLink: 'certificate-management',
      icon_category: 'learning',
      icon_name: 'certificate',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Manage Certificate Types',
      id: 1,
      show: true,
      parent: SettingsRow.Certificates,
    },
    {
      routerLink: 'questionnaires-management',
      icon_category: 'learning',
      icon_name: 'certificate',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Questionnaires Management',
      id: 1,
      show: false,
      parent: SettingsRow.Questionnaire,
    },
    {
      routerLink: 'archived-objects-management',
      icon_category: 'essential',
      icon_name: 'archive',
      arrow_icon_name: 'arrow-right-long',
      arrow_icon_category: 'arrows',
      keyword: 'Archived Objects Management',
      id: 1,
      show: false,
      parent: SettingsRow.Archiving,
    },
  ];

  public static readonly settingsList: Settings[] = [
    {
      row_name: SettingsRow.Administrative,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Administrative).sort((n1, n2) => n1.id - n2.id),
      show: true,
      id: 1,
    },
    {
      row_name: SettingsRow.Certificates,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Certificates).sort((n1, n2) => n1.id - n2.id),
      show: true,
      id: 3,
    },
    {
      row_name: SettingsRow.Questionnaire,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Questionnaire).sort((n1, n2) => n1.id - n2.id),
      show: false,
      id: 4,
    },
    {
      row_name: SettingsRow.Brand,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Brand).sort((n1, n2) => n1.id - n2.id),
      show: true,
      id: 2,
    },
    {
      row_name: SettingsRow.Languages,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Languages).sort((n1, n2) => n1.id - n2.id),
      show: false,
      id: 5,
    },
    {
      row_name: SettingsRow.Archiving,
      items: this.settingItemsList.filter(x => x.parent === SettingsRow.Archiving).sort((n1, n2) => n1.id - n2.id),
      show: false,
      id: 6,
    },
  ];

  public static readonly deactivateBatchNodeActionList: ActionList[] = [
    {
      keyword: 'Edit step',
      icon_category: 'files',
      icon_name: 'edit-2',
      operation: ActionListOperations.EditChainNode,
      id: 'edit-2',
    },
    {
      keyword: 'Deactivate Step',
      icon_category: 'essential',
      icon_name: 'activate',
      operation: ActionListOperations.DeactivateStep,
      id: 'deactivate',
    },
  ];

  public static readonly supplyChainNodeAcitionList: ActionList[] = [
    {
      keyword: 'Edit step',
      icon_category: 'files',
      icon_name: 'edit-2',
      operation: ActionListOperations.EditChainNode,
      id: 'edit-2',
    },
    {
      keyword: 'Delete Step',
      icon_category: 'essential',
      icon_name: 'close',
      operation: ActionListOperations.DeleteStep,
      id: 'delete',
    },
  ];

  public static readonly reactiveBatchNodeActionList: ActionList[] = [
    {
      keyword: 'View step',
      icon_category: 'security',
      icon_name: 'eye',
      operation: ActionListOperations.ViewProductChain,
      id: 'eye',
    },
    {
      keyword: 'Reactivate Step',
      icon_category: 'essential',
      icon_name: 'activate',
      operation: ActionListOperations.ReactivateStep,
      id: 'reactive',
    },
  ];
}
