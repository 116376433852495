import { ILoginResponse, ILogout } from '@app/core/interface/login.interface';
import {
  IResetPasswordConfirmResponse,
  IResetPasswordResponse,
  IUserConsent,
  IUserUuid,
} from '../interface/forgot-password.interface';

import { HttpClient } from '@angular/common/http';
import { ICompleteProfileResponse } from '@app/core/interface/register.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private apiUrl = environment.api;
  private changesUnsaved = false;

  constructor(private _http: HttpClient) {}

  createAccount$(data: FormData): Observable<ILoginResponse> {
    return this._http.post<ILoginResponse>(`${this.apiUrl}/accounts/registration/`, data);
  }

  login$(data: FormData): Observable<ILoginResponse> {
    return this._http.post<ILoginResponse>(`${this.apiUrl}/accounts/login/`, data);
  }

  logout$(): Observable<ILogout> {
    return this._http.post<ILogout>(`${this.apiUrl}/accounts/logout/`, null);
  }

  resetPassword$(email: FormData): Observable<IResetPasswordResponse> {
    return this._http.post<IResetPasswordResponse>(`${this.apiUrl}/accounts/password/reset/`, email);
  }

  completeProfile$(data: FormData): Observable<ICompleteProfileResponse> {
    return this._http.put<ICompleteProfileResponse>(`${this.apiUrl}/accounts/user/`, data);
  }

  resetPasswordConfirm(data: FormData): Observable<IResetPasswordConfirmResponse> {
    return this._http.post<IResetPasswordConfirmResponse>(`${this.apiUrl}/accounts/password/reset/confirm/`, data);
  }

  getAccountDetails(): Observable<ICompleteProfileResponse> {
    return this._http.get<ICompleteProfileResponse>(`${this.apiUrl}/accounts/user/`);
  }

  manageProfile$(data: FormData): Observable<ICompleteProfileResponse> {
    return this._http.patch<ICompleteProfileResponse>(`${this.apiUrl}/accounts/user/`, data);
  }

  inviteSupplier$(data: FormData): Observable<ICompleteProfileResponse> {
    return this._http.post<ICompleteProfileResponse>(`${this.apiUrl}/accounts/supplier/invite/`, data);
  }

  getUserUuid$(email: string): Observable<IUserUuid> {
    return this._http.get<IUserUuid>(`${this.apiUrl}/accounts/user/uuid`, { params: { email } });
  }

  setUserConsent$(data: FormData, uuid: string): Observable<IUserConsent> {
    return this._http.patch<IUserConsent>(`${this.apiUrl}/accounts/user/consent/${uuid}/`, data);
  }

  setIsSaved(saved: boolean): void {
    this.changesUnsaved = saved;
  }

  getIsSaved(): boolean {
    return this.changesUnsaved;
  }
}
