<div [ngClass]="[type]" #snackbarElement>
  <div class="wrapper">
    <div class="icon" [ngClass]="type">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="iconName"> </app-custom-icon>
    </div>
    <div class="text">
      <p>{{ text }}</p>
    </div>
  </div>
</div>
