import {
  IDeleteProductChain,
  IProductChainResult,
  IProductChainsResponse,
  IStepSupplierStatus,
} from '../interface/productchain.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductChainService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getProductChains$(): Observable<IProductChainsResponse> {
    return this._http.get<IProductChainsResponse>(`${this.apiUrl}/productchain/`);
  }

  getProductChain$(uuid: string): Observable<IProductChainResult> {
    return this._http.get<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/`);
  }

  createProductChain$(data: Partial<IProductChainResult>): Observable<IProductChainResult> {
    return this._http.post<IProductChainResult>(`${this.apiUrl}/productchain/`, data);
  }

  updateProductChainActivation$(data: Partial<IProductChainResult>, uuid: string): Observable<IProductChainResult> {
    return this._http.patch<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/activate/`, data);
  }
  updateProductChain$(data: Partial<IProductChainResult>, uuid: string): Observable<IProductChainResult> {
    return this._http.patch<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/`, data);
  }

  deleteProductChain$(uuid: string): Observable<IDeleteProductChain> {
    return this._http.delete<IDeleteProductChain>(`${this.apiUrl}/productchain/${uuid}/`);
  }

  publishProductChain$(uuid: string): Observable<IProductChainResult> {
    return this._http.post<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/publish/`, {
      is_custom_project: true,
    });
  }

  duplicateProductChain$(uuid: string, data: Partial<IProductChainResult>): Observable<IProductChainResult> {
    return this._http.post<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/clone/`, data);
  }

  getStepSupplierStatus$(uuid: string, type: string): Observable<IStepSupplierStatus> {
    return this._http.get<IStepSupplierStatus>(`${this.apiUrl}/${type}/${uuid}/supplierstatus/`);
  }

  changeProductChainStatus$(uuid: string, status: string): Observable<IProductChainResult> {
    return this._http.patch<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/changestatus/`, { status });
  }
}
