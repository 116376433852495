<div class="d-mini-w row">
  <div class="col-1">
    <div class="img-wrap">
      <img
        src="{{ item?.image ? item?.image : '../../../../../../../assets/images/image-placeholder.jpg' }}"
        alt="logo"
        width="36px"
        height="36px"
      />
    </div>
  </div>
  <div [ngClass]="editable ? 'col-6' : 'col-8'">
    <p class="tit">
      {{ item?.name }}
    </p>
    <p class="role" *ngIf="item?.position" [ngClass]="editable ? 'col-6 offset-1' : 'col-8 offset-1'">
      {{ item?.position }}
    </p>
  </div>
  <div class="col-1" *ngIf="editable">
    <div class="remove-icon-wrap">
      <app-custom-icon
        class="right"
        [icon_category]="'essential'"
        [icon_name]="'minus-circle'"
        (click)="removeItem(item)"
      ></app-custom-icon>
    </div>
  </div>
</div>
