export interface ActionList {
  keyword: string;
  icon_category: string;
  icon_name: string;
  operation: ActionListOperations;
  id: string;
}

export interface Settings {
  row_name: string;
  items: SettingsItem[];
  show: boolean;
  id: number;
}

export interface SettingsItem {
  keyword: string;
  icon_category: string;
  icon_name: string;
  arrow_icon_name: string;
  arrow_icon_category: string;
  routerLink: string;
  id: number;
  show: boolean;
  parent: SettingsRow;
}

export class ActionListOperations {
  public static OpenChainList = 'chain-list';
  public static EditProductChain = 'edit-chain';
  public static ViewProductChain = 'product-chain';
  public static DuplicateChain = 'duplicate-chain';
  public static CreateBatch = 'create-batch';
  public static PublishChain = 'publish-chain';
  public static DeactivateChain = 'deactivate-chain';
  public static ViewProductBatch = 'product-batch';
  public static PublishOnRespectCode = 'respect-code';
  public static ViewRespectCycle = 'respect-cycle';
  public static OpenBatchList = 'batch-list';
  public static DeactivateBatch = 'batch-deactivate';
  public static EditChainNode = 'edit-chain-node';
  public static DeactivateStep = 'deactivate-step';
  public static EditSupplyChainNode = 'edit-chain-node';
  public static DeleteStep = 'delete-step';
  public static ReactivateStep = 'reactivate-step';

  public static get All(): string[] {
    return [
      ActionListOperations.OpenChainList,
      ActionListOperations.EditProductChain,
      ActionListOperations.ViewProductChain,
      ActionListOperations.DuplicateChain,
      ActionListOperations.CreateBatch,
      ActionListOperations.CreateBatch,
      ActionListOperations.PublishChain,
      ActionListOperations.DeactivateChain,
      ActionListOperations.ViewProductBatch,
      ActionListOperations.PublishOnRespectCode,
      ActionListOperations.ViewRespectCycle,
      ActionListOperations.DeactivateBatch,
      ActionListOperations.ReactivateStep,
    ];
  }
}

export class SettingsRow {
  public static Administrative = 'ADMINISTRATIVE SETTINGS';
  public static Brand = 'BRANDS SETTINGS';
  public static Certificates = 'CERTIFICATES SETTINGS';
  public static Languages = 'LANGUAGE SETTINGS';
  public static Questionnaire = 'QUESTIONNAIRES SETTINGS';
  public static Archiving = 'ARCHIVING SETTINGS';
}
