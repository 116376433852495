<div class="toggle-group" [ngClass]="{ hidden: stepLevelView }">
  <mat-button-toggle-group #group="matButtonToggleGroup">
    <div class="step-level toggle">
      <mat-button-toggle value="stepLevel" aria-label="Step Level" [checked]="!stepLevelView">
        <span>{{ 'Step Level' | translate }}</span>
      </mat-button-toggle>
    </div>
  </mat-button-toggle-group>
</div>

<div class="product-level" *ngIf="group.value === 'productLevel'">
  <h6>{{ 'You are viewing all of the Documents & Media that are added to this Product' | translate }}</h6>

  <div class="horizontal-line"></div>

  <div class="row">
    <div class="col-6 documents-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Documents' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            [button_text]="'Upload New Document' | translate"
            [size]="'md'"
            (click)="addFile('documents', 'products', product?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of product?.documents">
          <app-file-card [file]="file" (onFileSelected)="viewFile($event)"></app-file-card>
        </div>
      </div>
    </div>
    <div class="col-6 medias-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Media' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            [button_text]="'Upload New Media' | translate"
            [size]="'md'"
            (click)="addFile('medias', 'products', product?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of product?.medias">
          <app-file-card [file]="file" (onFileSelected)="viewFile($event)"></app-file-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="supplier-level" *ngIf="group.value === 'supplierLevel'">
  <div class="intro-section pr-1">
    <h6>{{ 'You are viewing all of the Documents & Media added from' | translate }} {{ selectedSupplier?.name }}</h6>
    <div mat-icon-button [matMenuTriggerFor]="menu" class="menu-title">
      <span>{{ selectedSupplier?.name }}</span>
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-down-short'"></app-custom-icon>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let supplier of supplierDocuments" (click)="changeSupplier(supplier)">
        <span>{{ supplier.name }}</span>
      </button>
    </mat-menu>
  </div>

  <div class="horizontal-line"></div>

  <div class="row">
    <div class="col-6 documents-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Documents' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            [button_text]="'Upload New Document' | translate"
            [size]="'md'"
            (click)="addFile('documents', 'suppliers', selectedSupplier?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of selectedSupplier?.documents">
          <app-file-card [file]="file" (onFileSelected)="viewFile($event)"></app-file-card>
        </div>
      </div>
    </div>
    <div class="col-6 medias-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Media' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            [button_text]="'Upload New Media' | translate"
            [size]="'md'"
            (click)="addFile('medias', 'suppliers', selectedSupplier?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of selectedSupplier?.medias">
          <app-file-card [file]="file" (onFileSelected)="viewFile($event)"></app-file-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="step-level" *ngIf="group.value === 'stepLevel'">
  <div class="intro-section pr-1">
    <h6>{{ 'You are viewing all of the Documents & Media added from' | translate }} {{ selectedStep?.name }}</h6>
    <div mat-icon-button [matMenuTriggerFor]="menu" class="menu-title" *ngIf="!stepLevelView">
      <span>{{ selectedStep?.name }}</span>
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-down-short'"></app-custom-icon>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let step of batchSteps" (click)="changeStep(step)">
        <span>{{ step.name }}</span>
      </button>
    </mat-menu>
  </div>

  <div class="horizontal-line"></div>

  <div class="row">
    <div class="col-6 documents-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Documents' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            *ngIf="selectedStep?.status !== 'COMPLETED'"
            [button_text]="'Upload New Document' | translate"
            [size]="'md'"
            (click)="addFile('documents', 'batchsteps', selectedStep?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of selectedStep?.documents">
          <app-file-card
            [file]="file"
            [supplierName]="selectedStep?.supplier?.name"
            (onFileSelected)="viewFile($event)"
          ></app-file-card>
        </div>
      </div>
    </div>
    <div class="col-6 medias-side">
      <div class="row">
        <div class="header-section">
          <h5>{{ 'Your Media' | translate }}</h5>
          <app-rounded-button
            color="secondary"
            *ngIf="selectedStep?.status !== 'COMPLETED'"
            [button_text]="'Upload New Media' | translate"
            [size]="'md'"
            (click)="addFile('medias', 'batchsteps', selectedStep?.uuid)"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="row file-card-wraper">
        <div class="col-4" *ngFor="let file of selectedStep?.medias">
          <app-file-card
            [file]="file"
            [supplierName]="selectedStep?.supplier?.name"
            (onFileSelected)="viewFile($event)"
          ></app-file-card>
        </div>
      </div>
    </div>
  </div>
</div>
