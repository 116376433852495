import {
  IDeleteSupplierSite,
  IManufactory,
  IManufactoryCategoriesResponse,
} from './../interface/manufactories.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ManufactoriesService {
  private apiUrl = environment.api;

  supplierSiteActions: Subject<string> = new Subject();

  constructor(private _http: HttpClient) {}

  getSupplierSiteDetails$(uuid: string): Observable<IManufactory> {
    return this._http.get<IManufactory>(`${this.apiUrl}/manufactories/${uuid}`);
  }

  createSupplierSite$(data: FormData): Observable<IManufactory> {
    return this._http.post<IManufactory>(`${this.apiUrl}/manufactories/`, data);
  }

  updateSupplierSite$(uuid: string, data: FormData): Observable<IManufactory> {
    return this._http.patch<IManufactory>(`${this.apiUrl}/manufactories/${uuid}/`, data);
  }

  deleteSupplierSite$(uuid: string): Observable<IDeleteSupplierSite> {
    return this._http.delete<IDeleteSupplierSite>(`${this.apiUrl}/manufactories/${uuid}`);
  }

  getSupplierSites$(uuid: string): Observable<IManufactory[]> {
    return this._http.get<IManufactory[]>(`${this.apiUrl}/suppliers/${uuid}/manufactories/`);
  }
  getManufactoryCategories$(): Observable<IManufactoryCategoriesResponse> {
    return this._http.get<IManufactoryCategoriesResponse>(`${this.apiUrl}/manufactories/categories/`);
  }
}
