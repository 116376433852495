import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class CreateBatchGroup {
  batchDetails: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.batchDetails = this._fb.group({
      quantity: [''],
      name: ['', Validators.required],
      product_chain: ['', Validators.required],
      quantity_unit: [''],
      transport: [''],
      external_reference: [''],
      free_text: [''],
      date_order: [''],
      date_delivered: [''],
    });
  }
}
