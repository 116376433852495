<div class="holder d-flex flex-column" *ngIf="isActiveFields; else noActiveFields">
  <ng-container *ngFor="let field of fieldsList; index as index">
    <!-- Show only before the first deactivated field -->
    <div
      *ngIf="!fieldsList[index - 1] || field.is_active !== fieldsList[index - 1].is_active"
      class="horizontal-line"
    ></div>
    <!-- Text fields -->
    <div
      class="field-item text"
      *ngIf="!field.all_options.length"
      [ngStyle]="{ opacity: field.is_active ? '1' : '0.4' }"
    >
      <h6>
        <strong>{{ field.field_name }}</strong>
      </h6>
      <p *ngIf="field.field_value; else notEnteredYet" class="m-0">{{ field.field_value }}</p>
      <ng-template #notEnteredYet>
        <div class="not-entered-yet d-flex">
          <app-custom-icon
            icon_category="essential"
            icon_name="info-circle"
            matTooltip="{{
              'Please note that you can enter \n the information about this field, \n in the edit project section.'
                | translate
            }}"
            matTooltipClass="custom-tooltip-center"
            matTooltipPosition="above"
          >
          </app-custom-icon>
          <p class="m-0">{{ 'not entered yet' | translate }}</p>
        </div>
      </ng-template>
    </div>

    <!-- Multiple option fields -->
    <div
      class="field-item multiple"
      *ngIf="field.all_options.length"
      [ngStyle]="{ opacity: field.is_active ? '1' : '0.4' }"
    >
      <h6>
        <strong>{{ field.field_name }}</strong>
      </h6>
      <div class="checkboxes d-flex flex-wrap">
        <mat-checkbox
          class="checkbox-primary"
          *ngFor="let option of field.all_options"
          [checked]="option.is_active"
          disabled
          >{{ option.option }}</mat-checkbox
        >
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noActiveFields>
  <div class="no-custom-fields d-flex align-items-center">
    <div class="icon-circle">
      <app-custom-icon id="element-plus" [icon_category]="'grid'" [icon_name]="'element-plus'"></app-custom-icon>
    </div>
    <p class="d-flex align-items-center justify-content-center">
      {{ 'There are no active custom fields \n or there are none created yet.' | translate }}
    </p>
  </div>
</ng-template>
