import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMedia, IMediaContentType, IMediaScreens } from '@app/core/interface/medias.interface';

@Component({
  selector: 'app-view-usefull-data-dialog',
  templateUrl: './view-usefull-data-dialog.component.html',
  styleUrls: ['./view-usefull-data-dialog.component.scss'],
})
export class ViewUsefullDataDialogComponent {
  selectedFile!: IMedia;
  listView = true;
  selectedIndex = 0;
  refresh = false;
  image = '';
  name = '';
  listName = '';
  selectedFileName = '';
  is_supplier = false;
  constructor(
    public dialogRef: MatDialogRef<ViewUsefullDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      type: IMediaContentType;
      typeUUID: string;
      data: IMedia[];
      screen: IMediaScreens;
      name: string;
      image: string;
      listName: string;
      selectedFile?: IMedia;
      is_supplier: boolean;
    }
  ) {
    dialogData.selectedFile && this.viewFile(dialogData.selectedFile);
    this.is_supplier = this.dialogData.is_supplier;
  }

  viewFile(file: IMedia) {
    this.selectedFile = file;
    this.listView = false;
    this.selectedIndex = 1;
  }
  close() {
    this.dialogRef.close(this.refresh);
  }
  hideSingleTab() {
    this.listView = true;
  }
  refreshData() {
    this.refresh = true;
  }

  getSelectedFileName(selectedFileName: string) {
    this.selectedFileName = selectedFileName;
  }
}
