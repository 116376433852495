<div class="certificate-card" *ngIf="certificate">
  <div class="cover">
    <app-custom-icon [icon_category]="'learning'" [icon_name]="'certificate'"></app-custom-icon>
    <div class="type">
      <p>{{ 'Certificate type' | translate }}</p>
      <h4>{{ certificate.certificate.category?.name }}</h4>
    </div>
  </div>
  <div class="card-body">
    <h4>{{ certificate.certificate.name }}</h4>
    <div class="supplier" [ngClass]="{ active: !manufactory }" *ngIf="supplier">
      <p>{{ 'certified supplier:' }}</p>
      <h5>{{ supplier }}</h5>
    </div>
    <div class="supplier active" *ngIf="manufactory">
      <p>{{ 'certified supplier site:' }}</p>
      <h5>{{ manufactory }}</h5>
    </div>
    <div class="start-date">
      <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar'"></app-custom-icon>
      <p>
        {{ 'Start Date:' | translate }}
        {{ certificate.validity_start ? (certificate.validity_start | date : 'MM.dd.yy') : 'None' }}
      </p>
    </div>
    <div class="end-date">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
      <p>
        {{ 'End Date:' | translate }}
        {{ certificate.validity_end ? (certificate.validity_end | date : 'MM.dd.yy') : 'None' }}
      </p>
    </div>
  </div>
</div>
