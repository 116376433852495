import { CdkStepper } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BRAND_NAME, USER, Storage, NotificationType } from '@app/core/constants';
import { EmailFacade } from '@app/core/facade/email.facade';
import { IEmailTemp, IEmailVarValues, IEmailVariables } from '@app/core/interface/email.interface';
import { ISupplierContact, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { InviteUsersListComponent } from './invite-users-list/invite-users-list.component';
import { ILanguage } from '@app/core/interface/languages.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-invite-users-dialog',
  templateUrl: './invite-users-dialog.component.html',
  styleUrls: ['./invite-users-dialog.component.scss'],
  providers: [CdkStepper, EmailFacade, DatePipe],
})
export class InviteUsersDialogComponent {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(InviteUsersListComponent) inviteUsersListComponent!: InviteUsersListComponent;

  selectedContact?: ISupplierContact;
  selectedTemplate?: IEmailTemp;
  templateList: IEmailTemp[] = [];
  disabled = false;
  refreshAfterClose = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      uuid: string;
      variables: IEmailVarValues;
      languages: ILanguage[];
      supplier: ISupplierResponse;
      step: string;
    },
    public dialogRef: MatDialogRef<InviteUsersDialogComponent>,
    private _emailFacade: EmailFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService,
    private datePipe: DatePipe
  ) {}

  handleSelection(selectedUsers: ISupplierContact): void {
    this.selectedContact = selectedUsers;
  }

  handleTemplateSelection(templateSelection?: IEmailTemp): void {
    this.selectedTemplate = templateSelection;
    this.disabled = !this.selectedTemplate;
  }

  continue(): void {
    if (this.stepper.selectedIndex === 1) {
      this.sendInvitations();
    } else {
      this.stepper.next();
      /**
       * if after chenged page we are on index 1 (slectTemplate) we trigger handleTemplateSelection
       */
      this.stepper.selectedIndex === 1 && this.handleTemplateSelection(this.selectedTemplate);
    }
  }

  sendInvitations(): void {
    const content = this.getContentWithValues();
    const payload = {
      email_template_uuid: this.selectedTemplate?.uuid ?? '',
      supplier_contact: this.selectedContact?.uuid ?? '',
      content: content,
      step: this.data.step,
    };
    this._emailFacade.sendInvitations$(payload).subscribe({
      next: this._inviteSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  getContentWithValues(): string {
    const variables = this.getVariables();
    let replacedText = '';
    if (this.selectedTemplate) {
      replacedText = this.selectedTemplate.content.replace(/{(.*?)}/g, (match, variableName) => {
        const value = variables[variableName.trim()];
        if (value === null) {
          return `{ ${variableName.trim()} }`;
        }
        if (value !== undefined) {
          return `${value}`;
        }

        return match;
      });
    }
    // const finalText = replacedText.replace(/\/ Project null|null|\/ Projet null/g, '');
    const finalText = replacedText.replace(/\/ Project { Project name }|null|\/ Projet { Nom du projet }/g, ''); // { en: '{ Project name }', fr: '{ Nom du projet }' },
    return finalText;
    // return replacedText;
  }
  getVariables(): IEmailVariables {
    const beandName = this._dataStorageService.get(BRAND_NAME, Storage.local);
    const user = JSON.parse(this._dataStorageService.get(USER, Storage.local));
    const vars = {
      ...this.data.variables,
      'Supplier contact name': this.selectedContact?.first_name + ' ' + this.selectedContact?.last_name,
      'Brand name': beandName,
      'Supplier name': this.data.supplier.name,
      'Brand contact name': user.first_name + ' ' + user.last_name,
      'Current date': this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      'Step name': this.data.supplier.steps?.find(step => step.uuid === this.data.step)?.name || '{ Step name }',
    } as IEmailVariables;

    const variables =
      this.selectedTemplate?.email_template_type.language === 'fr'
        ? this._emailFacade.mapVariablesToFrenchNames(vars, 'fr')
        : vars;
    return variables;
  }

  close(): void {
    this.dialogRef.close(this.refreshAfterClose);
  }
  goBack(): void {
    this.selectedContact && this.inviteUsersListComponent.selection.select(this.selectedContact);
    this.disabled = !this.selectedContact;
    this.stepper.previous();
  }
  private _inviteSuccess(): void {
    this.refreshAfterClose = true;
    this.stepper.next();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
