import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-illustration-design',
  templateUrl: './illustration-design.component.html',
  styleUrls: ['./illustration-design.component.scss'],
})
export class IllustrationDesignComponent {
  @Input() color = '';
}
