import { Injectable } from '@angular/core';
import { FIRST_LOGIN, Storage } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  set(key: string, value: string, storageType: Storage): void {
    if (storageType === Storage.local) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(key: string, storageType: Storage): any {
    if (storageType === Storage.local) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

  clearStorage(): void {
    const firstLogin = localStorage.getItem(FIRST_LOGIN);
    localStorage.clear();
    sessionStorage.clear();
    firstLogin && localStorage.setItem(FIRST_LOGIN, firstLogin);
  }

  removeKey(key: string, storageType: Storage): void {
    if (storageType === Storage.local) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
}
