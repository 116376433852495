<div class="wrapper" aria-hidden="false">
  <div
    [ngClass]="{
      holder: dialogData.title,
      'd-flex': !dialogData.title,
      'flex-wrap': !dialogData.title,
      'align-items-center': !dialogData.title,
      'justify-content-center': !dialogData.title,
    }"
  >
    <div class="title" *ngIf="dialogData.title">
      <p>{{ dialogData.title }}</p>
      <mat-divider></mat-divider>
    </div>
    <div
      *ngIf="dialogData.infoText || dialogData.descriptionText"
      [ngClass]="{
        warning: type === 'warning',
        info: type === 'info',
        forbidden: type === 'forbidden',
        forbiddenInfo: type === 'forbidden-info',
        forbidden_info_blue: type === 'forbidden_info_blue',
      }"
      class="info-box align-items-center"
    >
      <div class="logo">
        <img src="../../../../assets/images/symbol-blue-logo.svg" />
      </div>

      <div class="text-holder">
        <p class="m-0" *ngIf="dialogData.infoText">{{ dialogData.infoText }}</p>
        <p class="m-0" *ngIf="dialogData.descriptionText">{{ dialogData.descriptionText }}</p>
      </div>
    </div>

    <div class="confirmation-text d-flex flex-column align-items-center" *ngIf="dialogData.confirmationText">
      <div
        *ngIf="type === 'forbidden' || type === 'forbidden-info' || type === 'forbidden_info_blue'"
        class="icon-holder d-flex justify-content-center align-items-center"
      >
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-white'"> </app-custom-icon>
      </div>
      <p
        [ngClass]="{
          'warning-text': text === 'warning-text',
          'info-text': text === 'info-text',
          'forbidden-text': text === 'forbidden-text',
        }"
        [innerHTML]="dialogData.confirmationText"
      ></p>
    </div>
  </div>

  <div [ngClass]="dialogData.title ? 'actions' : ''">
    <mat-dialog-actions>
      <div *ngIf="type === 'warning' && !dialogData.title" class="warning-btn-holder d-flex">
        <app-rounded-button
          class="cancel-btn"
          color="tetriary-link"
          [button_text]="'supply-chain-step.Cancel' | translate"
          [size]="'md'"
          mat-dialog-close
        >
        </app-rounded-button>

        <app-rounded-button
          class="action-btn"
          (click)="deactivateUnit()"
          mat-dialog-close
          color="primary"
          [button_text]="dialogData.btnText"
          [size]="'md'"
        >
        </app-rounded-button>
      </div>

      <app-rounded-button
        *ngIf="type === 'info' || type === 'forbidden' || type === 'forbidden-info' || type === 'forbidden_info_blue'"
        mat-dialog-close
        color="primary"
        [button_text]="dialogData.btnText"
        [size]="'md'"
      >
      </app-rounded-button>

      <div *ngIf="type === 'info-actions'" class="info-action-holder d-flex">
        <app-rounded-button
          class="cancel-btn"
          color="tetriary-link"
          [button_text]="'supply-chain-step.Cancel' | translate"
          [size]="'md'"
          mat-dialog-close
        >
        </app-rounded-button>

        <app-rounded-button
          class="action-btn"
          color="primary"
          [button_text]="dialogData.btnText"
          [size]="'md'"
          (click)="reactivateUnit(); disapproveInfoBatch()"
        >
        </app-rounded-button>
      </div>
    </mat-dialog-actions>

    <mat-dialog-actions class="d-flex gap" [align]="'end'" *ngIf="dialogData.title && type === 'warning'">
      <app-rounded-button color="tetriary-link" [button_text]="'No, go back'" [size]="'md'" mat-dialog-close>
      </app-rounded-button>
      <app-rounded-button
        size="md"
        color="secondary"
        class="link"
        [button_text]="dialogData.btnText"
        [mat-dialog-close]="true"
      >
      </app-rounded-button>
    </mat-dialog-actions>
  </div>
</div>
