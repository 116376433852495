import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import QRCodeStyling from 'qr-code-styling';
import { environment } from '@env/environment';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvas!: ElementRef;
  @Input() qrCodeUrl?: string;
  @Input() qrCodeLogo!: string;
  assetPath = environment.assetPath;
  qrCode!: QRCodeStyling;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['qrCodeUrl'] && this.qrCode) {
      this.qrCode.update({ data: this.qrCodeUrl });
    }
    if (changes['qrCodeLogo'] && this.qrCode) {
      this.qrCode.update({ image: `${this.assetPath}/images/${this.qrCodeLogo}` });
    }
  }

  ngOnInit(): void {
    if (!QRCodeStyling) {
      return;
    }
    this.qrCode = new QRCodeStyling({
      width: 155,
      height: 155,
      margin: 5,
      data: this.qrCodeUrl,
      image: `${this.assetPath}/images/${this.qrCodeLogo}`,
      dotsOptions: {
        // type: "rounded"
      },
      backgroundOptions: {
        color: 'transparent',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 0,
      },
    });

    this.qrCode.append(this.canvas.nativeElement);
  }
}
