<div class="holder full-height d-flex flex-column flex-nowrap">
  <div class="supplier-components justify-content-between row" *ngIf="!showAddComponentForm && updateComponent">
    <h6>{{ 'Components from ' + site?.name | translate }}</h6>
    <div class="component-card" *ngFor="let component of supplierComponents">
      <div class="actions">
        <app-custom-icon
          [icon_category]="'files'"
          [icon_name]="'edit-2'"
          (click)="showUpdateForm(component)"
        ></app-custom-icon>
        <app-custom-icon
          [icon_category]="'essential'"
          [icon_name]="'trash'"
          (click)="deleteSupplierComponent(component)"
        ></app-custom-icon>
      </div>
      <div class="component-info">
        <h5>{{ component.name }}</h5>
        <h6>
          <span>{{ component.manufactory?.name }}</span>
          {{ component.manufactory?.country?.name }}
        </h6>
        <p>
          <span>{{ 'Supplier:' | translate }} </span>{{ supplierName }}
        </p>
      </div>
    </div>
  </div>

  <div
    *ngIf="(showAddComponentForm && addComponent) || (updateComponent && !componentView)"
    class="add-component-info d-flex justify-content-between align-items-center"
  >
    <h6 class="m-0">{{ addComponent ? 'Adding Component' : 'Editing Component' }}</h6>
    <div class="btn-holder d-flex">
      <app-rounded-button
        color="turquoise"
        [size]="'md'"
        [icon_right]="true"
        [icon_category]="'essential'"
        [icon_name]="'box-2'"
        [button_text]="addComponent ? 'Add component' : 'Update Component'"
        (click)="
          form.valid && addComponent
            ? createSupplierComponent()
            : updateComponent
              ? updateSupplierComponent()
              : form.controls['name'].markAsTouched()
        "
      >
      </app-rounded-button>
      <app-rounded-button
        [color]="'tetriary-link'"
        [size]="'sm'"
        [button_text]="'Cancel'"
        class="cancel"
        (click)="cancelComponentView()"
      >
      </app-rounded-button>
    </div>
  </div>

  <div class="add-component" *ngIf="showAddComponentForm">
    <div>
      <div *ngIf="ingredient">
        <h6 class="edit-tit" *ngIf="ingredient !== 'add'">
          <strong>{{ ('The Supplier Site this component is linked to' | translate) + ' ' + ingredient.name }}</strong>
        </h6>
        <h6 class="edit-tit" *ngIf="ingredient === 'add'">
          <strong>{{ 'Choose a Supplier Site first, or if there is none yet create one' | translate }}</strong>
        </h6>
      </div>
      <form [formGroup]="form">
        <div
          *ngIf="!addComponent && !componentView && !updateComponent"
          class="input-holder choose-supplier-site align-items-center"
        >
          <div class="paragraph">
            <p>
              {{ 'In order to create a component, first you need to choose supplier site.' | translate }}
            </p>
          </div>
          <mat-form-field class="input-primary" appearance="outline" subscriptSizing="dynamic">
            <mat-select
              formControlName="manufactory"
              panelClass="dropdown-primary choose-unit-dropdown"
              placeholder="{{ 'Choose Supplier Site' | translate }}"
            >
              <mat-option *ngIf="!manufactoryList.length">{{
                'No supplier site is created yet' | translate
              }}</mat-option>
              <mat-option *ngFor="let manufactory of manufactoryList" [value]="manufactory.uuid">
                {{ manufactory.name ? manufactory.name + ',' : '' }}
                {{ manufactory.address ? manufactory.address + ',' : '' }}
                {{ manufactory.city ? manufactory.city + ',' : '' }} {{ manufactory.country }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-rounded-button
            color="turquoise"
            [size]="'md'"
            [button_text]="'Create Supplier Site' | translate"
            (click)="openCreateSupplierSite()"
          >
          </app-rounded-button>
        </div>

        <div class="" [ngClass]="addComponent || updateComponent ? 'input-holder-create' : 'input-holder'">
          <h6 *ngIf="!addComponent" class="m-0 full-width">
            <strong>{{ 'Component general info' | translate }}</strong>
          </h6>
          <div class="name-reference col-gap">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Component Name' | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="Component Name" required />
              <mat-error *ngIf="form.controls['name'].hasError('required')">
                {{ 'Please enter component name in order to proceed' | translate }}</mat-error
              >
            </mat-form-field>

            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Reference' | translate }}</mat-label>
              <input matInput formControlName="reference" placeholder="Reference" />
            </mat-form-field>
          </div>
          <div class="description col-gap">
            <mat-form-field class="input-primary textarea" id="text-area" appearance="outline">
              <mat-label>{{ 'Description' | translate }}</mat-label>
              <textarea
                id="area"
                matInput
                formControlName="description"
                placeholder="Enter Description"
                rows="6"
                appTextareaAutoResize
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="show_buttons">
      <div class="action-buttons" *ngIf="!ingredient">
        <app-rounded-button
          color="primary"
          [button_text]="'Save component'"
          [size]="'md'"
          [icon_right]="true"
          [icon_category]="'essential'"
          [icon_name]="'box-2'"
          [disabled]="form && !form.valid"
          (click)="!isUpdateComponent ? createSupplierComponent() : updateSupplierComponent()"
        >
        </app-rounded-button>

        <app-rounded-button color="secondary" [size]="'md'" [button_text]="'Discard'" (click)="discard()">
        </app-rounded-button>
      </div>

      <div class="action-buttons-single" *ngIf="ingredient">
        <app-rounded-button
          mat-dialog-close
          color="tetriary-link"
          [size]="'md'"
          [button_text]="'Cancel'"
          (click)="discard()"
        >
        </app-rounded-button>

        <app-rounded-button
          color="primary"
          [button_text]="isUpdateComponent ? 'Save Changes' : 'Create Component'"
          [size]="'md'"
          [icon_right]="true"
          [icon_category]="'essential'"
          [icon_name]="'box-2'"
          [disabled]="form && !form.valid"
          (click)="!isUpdateComponent ? createSupplierComponent() : updateSupplierComponent()"
        >
        </app-rounded-button>
      </div>
    </div>
  </div>
</div>
