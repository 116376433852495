import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProduct } from '@app/core/interface/products.interface';
import { ISupplierContact } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss'],
})
export class MiniCardComponent {
  @Input() editable = false;
  @Input() item?: Partial<ISupplierContact>;
  @Output() remove: EventEmitter<Partial<IProduct>> = new EventEmitter<Partial<IProduct>>();

  removeItem(event?: Partial<IProduct>) {
    event && this.remove.emit(event);
  }
}
