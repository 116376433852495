<form [formGroup]="requestForm">
  <div class="dialog-wrapper d-flex flex-column">
    <h2 mat-dialog-title>{{ 'need-help.Contact support' | translate }}</h2>

    <mat-dialog-content class="m-0">
      <div class="tabs-section d-flex flex-column full-height">
        <div class="tabs-section-content">
          <div class="choose-object-note d-flex align-items-center">
            <img src="assets/logos/symbol-logo-dark.svg" alt="" />
            <p class="p1-strong col m-0">
              {{
                'need-help.Please write your request in details and specify exactly the elements of the SaaS for which is this request for'
                  | translate
              }}.
            </p>
          </div>
          <mat-card *ngIf="!data" class="holder">
            <p>{{ 'need-help.Write your request' | translate }}</p>
            <mat-form-field class="input-primary textarea" appearance="outline">
              <mat-label>{{ 'need-help.Enter request' | translate }}</mat-label>
              <textarea matInput formControlName="content" appTextareaAutoResize></textarea>

              <mat-error
                *ngIf="requestForm.get('content')?.hasError('required') && requestForm.get('content')?.touched"
              >
              </mat-error>
            </mat-form-field>
            <p class="examples">
              {{
                'need-help.SaaS elements examples: specific menu, specific step, question about direct suppliers'
                  | translate
              }}...
            </p>
          </mat-card>
          <mat-card *ngIf="data" class="holder">
            <p>{{ 'need-help.Write your request' | translate }}</p>
            <mat-form-field class="input-primary textarea" appearance="outline">
              <mat-label>{{ 'need-help.Enter request' | translate }}</mat-label>
              <textarea matInput formControlName="request_reason" appTextareaAutoResize></textarea>

              <mat-error
                *ngIf="
                  requestForm.get('request_reason')?.hasError('required') && requestForm.get('request_reason')?.touched
                "
              >
              </mat-error>
            </mat-form-field>
            <p class="examples">
              {{
                'need-help.SaaS elements examples: specific menu, specific step, question about direct suppliers'
                  | translate
              }}...
            </p>
          </mat-card>
        </div>
      </div>
      <div class="privacy-info mt-4">
        <p>
          {{ 'need-help.All personal data is protected. Learn more in our ' | translate }}
          <span
            class="policy-link"
            mat-dialog-close
            (click)="navigationService.openTermAndCondition('/terms-and-conditions/Privacy policy')"
          >
            {{ 'need-help.Privacy Policy' | translate }}</span
          >
        </p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex justify-content-between">
      <app-data-secured-badge></app-data-secured-badge>
      <div>
        <app-rounded-button
          class="close-btn"
          mat-dialog-close
          color="tetriary-link underlined"
          [size]="'md'"
          [button_text]="'need-help.Cancel' | translate"
        >
        </app-rounded-button>
        <app-rounded-button
          *ngIf="!data"
          class="send-request"
          color="primary"
          [button_text]="'need-help.Send Request' | translate"
          [icon_category]="'support'"
          [icon_name]="'support'"
          [icon_right]="true"
          [size]="'md'"
          [disabled]="!requestForm.valid"
          (click)="sendRequestText()"
        >
        </app-rounded-button>
        <app-rounded-button
          *ngIf="data"
          class="send-request"
          color="primary"
          [button_text]="'need-help.Send Request' | translate"
          [icon_category]="'support'"
          [icon_name]="'support'"
          [icon_right]="true"
          [size]="'md'"
          [disabled]="!requestForm.valid"
          (click)="sendEditStepRequestText()"
        >
        </app-rounded-button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
