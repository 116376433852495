import {
  IDeleteIngredient,
  IIngredient,
  IIngredientCategory,
  IIngredientCategoryResponse,
  IIngredientsResponse,
} from '../interface/ingredient.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class IngredientService {
  private apiUrl = environment.api;

  supplierComponentActions: Subject<string> = new Subject();

  constructor(private _http: HttpClient) {}

  getIngredients$(): Observable<IIngredientsResponse> {
    return this._http.get<IIngredientsResponse>(`${this.apiUrl}/ingredient/`);
  }

  createIngredient$(data: FormData): Observable<IIngredient> {
    return this._http.post<IIngredient>(`${this.apiUrl}/ingredient/`, data);
  }

  updateIngredient$(uuid: string, data: FormData): Observable<IIngredient> {
    return this._http.patch<IIngredient>(`${this.apiUrl}/ingredient/${uuid}/`, data);
  }

  deleteIngredient$(uuid: string): Observable<IDeleteIngredient> {
    return this._http.delete<IDeleteIngredient>(`${this.apiUrl}/ingredient/${uuid}`);
  }

  getIngredient$(uuid: string): Observable<IIngredient> {
    return this._http.get<IIngredient>(`${this.apiUrl}/ingredient/${uuid}`);
  }

  getIngredientCategories$(is_active: string): Observable<IIngredientCategoryResponse> {
    return this._http.get<IIngredientCategoryResponse>(`${this.apiUrl}/ingredient/categories/?is_active=${is_active}`);
  }

  createIngredientCategory$(data: FormData): Observable<IIngredientCategory> {
    return this._http.post<IIngredientCategory>(`${this.apiUrl}/ingredient/categories/`, data);
  }

  updateIngredientCategory$(uuid: string, data: FormData): Observable<IIngredientCategory> {
    return this._http.patch<IIngredientCategory>(`${this.apiUrl}/ingredient/categories/${uuid}`, data);
  }
}
