<div class="wraper">
  <app-custom-icon
    [icon_category]="'essential'"
    [icon_name]="'informations'"
    (click)="showTooltip = !showTooltip"
  ></app-custom-icon>
  <div class="tooltip" *ngIf="showTooltip">
    <div class="header d-flex justify-content-between align-items-center">
      <h3>{{ 'Personal Data' }}</h3>
      <app-custom-icon
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        (click)="showTooltip = false"
      ></app-custom-icon>
    </div>
    <p>
      {{
        'For your protection, we use anonymization and pseudonymization to handle your data. This means your personal details are either masked or altered to safeguard your identity. For more information, see our:'
          | translate
      }}
    </p>
    <span class="policy-link" [routerLink]="'/terms-and-conditions/Privacy policy'" mat-dialog-close>{{
      'Privacy policy' | translate
    }}</span>
  </div>
</div>
