<div class="cont-list">
  <p class="head-info pull-left">
    {{ 'Email will be sent to this contact' | translate }}
  </p>
  <div class="cards-container pull-left">
    <div class="m-card-w pull-left">
      <app-mini-card [editable]="false" [item]="getItem(selectedContact)"></app-mini-card>
    </div>
  </div>
</div>
<div class="back-btn">
  <app-rounded-button
    color="secondary"
    [button_text]="'Choose different contact'"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-left-long'"
    [size]="'md'"
    [icon_left]="true"
    (click)="goBack.emit(true)"
    color="tetriary-link"
  >
  </app-rounded-button>
  <div class="invite-msg">
    {{ 'Select one of the email templates for the email invitation' | translate }}
  </div>
</div>
<!-- Table view -->
<div class="table-wraper">
  <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="select"></th>
      <td mat-cell *matCellDef="let row" class="select">
        <mat-radio-button
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="selectTemplate(row)"
          [checked]="emailSelection.isSelected(row)"
        ></mat-radio-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="name">{{ 'Template Name' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="name">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mod-by">{{ 'Template type' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="mod-by">
        {{ element.email_template_type?.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="language">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="lst-mod">
        {{ 'Template language' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="lst-mod">{{ element.email_template_type?.language }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-header text-center actions">
        {{ 'Actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="actions-body text-center actions">
        <div class="row">
          <div class="col-6">
            <app-custom-icon
              id="eye"
              [icon_category]="'security'"
              [icon_name]="'eye'"
              (click)="openViewEmailDialog(element)"
            >
            </app-custom-icon>
          </div>
          <div class="col-6">
            <app-custom-icon
              id="edit-content"
              [icon_category]="'files'"
              [icon_name]="'edit-content'"
              (click)="openEditDialog(element)"
            >
            </app-custom-icon>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
