// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateFormData = (data: Record<string, any>, listWithSameKeys?: boolean): FormData => {
  const formData: FormData = new FormData();
  Object.keys(data).forEach(key => {
    // if key is languages thean we loop the list and append each language uuid separately to the formData
    if (
      key === 'languages' ||
      key === 'steps' ||
      key === 'publication_step_items' ||
      key === 'project_products' ||
      key === 'hazardous_substance' ||
      listWithSameKeys
    ) {
      data[key] &&
        data[key].forEach((res: string) => {
          formData.append(`${key}`, `${res}`);
        });
    } else {
      if (data[key] && data[key].size) {
        formData.append(`${key}`, data[key], `${data[key].name}`);
      } else if (data[key] && Array.isArray(data[key]) && data[key].length) {
        formData.append(`${key}`, JSON.stringify(data[key]).replace(/['"]+/g, '').replace(/,/g, ', '));
      } else {
        formData.append(`${key}`, `${data[key]}`);
      }
    }
  });
  return formData;
};
