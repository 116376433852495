import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ISupplierContact, ISupplierContactsResponse } from '@app/core/interface/suppliers.interface';

import { AddContactComponent } from '@app/module/supplier-management/supplier-contacts/add-contact/add-contact.component';
import { ILanguage } from '@app/core/interface/languages.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SelectionModel } from '@angular/cdk/collections';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@Component({
  selector: 'app-invite-users-list',
  templateUrl: './invite-users-list.component.html',
  styleUrls: ['./invite-users-list.component.scss'],
  providers: [SuppliersFacade],
})
export class InviteUsersListComponent implements OnChanges {
  @Input() uuid = '';
  @Input() languages: ILanguage[] = [];

  users: ISupplierContact[] = [];
  selectedUser?: ISupplierContact;
  @Output() usersSelected = new EventEmitter<ISupplierContact>();
  @Output() refreshAfterClose = new EventEmitter<boolean>();
  selection = new SelectionModel<ISupplierContact>(false, []);

  constructor(
    private _dialog: MatDialog,
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    changes['uuid'] && this.getContacts(changes['uuid'].currentValue);
  }
  toggleUserSelection(user: ISupplierContact) {
    this.selectedUser = user;
    this.usersSelected.emit(this.selectedUser);
  }
  getContacts(uuid?: string) {
    this._suppliersFacade.getSupplierContacts$(uuid ?? this.uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  addSupplierContact() {
    const dialogRef = this._dialog.open(AddContactComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { supplierUuid: this.uuid, languages: this.languages },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.refreshAfterClose.emit(true);
        this.getContacts();
      }
    });
  }

  private _success(response: ISupplierContactsResponse): void {
    this.users = response.results;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
